import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { ITPESource, ITPEVariable } from './interfaces';

const START_URL = 'PishepromTabakClass';

export const getTPESources = async (): Promise<ITPESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`);
  return data;
};

export const getTPEVariables = async (): Promise<ITPEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getTPEFullReport = async (reportData: {
  sourceId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { sourceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: sourceId,
      paramName: 'Istochnik',
    },
    ...variables,
  ]);
  return data;
};
