import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col, Select } from 'antd';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getFCIEDryerTypes,
  getFCIEDustCollectors,
  getFCIEEquipments,
  getFCIEFullReport,
  getFCIEMarks,
  getFCIEPollutants,
  getFCIEProcessTypes,
  getFCIEVariables,
} from '../../api/FoodConcentrateIndustryEmissions';

import { IFCIEFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const FoodConcentrateIndustryEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const processTypes = useQuery('FCIEProcessTypes', getFCIEProcessTypes);
  const dustCollectros = useQuery('FCIEDustCollectros', getFCIEDustCollectors);
  const dryerTypes = useQuery('FCIEDryerTypes', getFCIEDryerTypes);
  const marks = useQuery('FCIEMarks', () =>
    getFCIEMarks(
      form.getFieldValue('process_type'),
      form.getFieldValue('dryer_type') || 1,
      form.getFieldValue('equipment') || 1,
    ),
  );
  const pollutants = useQuery('FCIEPollutants', () =>
    getFCIEPollutants(
      form.getFieldValue('process_type'),
      form.getFieldValue('dryer_type') || 1,
      form.getFieldValue('equipment') || 1,
      form.getFieldValue('mark') || 1,
    ),
  );
  const equipments = useQuery('FCIEEquipments', () => getFCIEEquipments(form.getFieldValue('process_type')));
  const variables = useQuery('FCIEVariables', () => getFCIEVariables(form.getFieldValue('process_type')));

  const onFinish = (values: IFCIEFormValues) => {
    const { pollutant, process_type, dryer_type, dust_collectors, mark, equipment } = values;

    if (variables.data) {
      getFormResult(getFCIEFullReport, {
        processTypeId: process_type,
        dustCollectorId: dust_collectors || 1,
        dryerId: dryer_type || 1,
        markId: mark || 1,
        equipment: equipment || 1,
        pollutant: pollutant || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeProcessType = () => {
    marks.refetch();
    pollutants.refetch();
    equipments.refetch();
    variables.refetch();
  };

  const onChangeDryerType = () => {
    marks.refetch();
    pollutants.refetch();
  };

  const onChangeEquipment = () => {
    marks.refetch();
    pollutants.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={processTypes.data ? processTypes.data : []}
            name="process_type"
            cyPrefix="FCIE"
            label="Вид процесса"
            defaultValue={1}
            onChange={onChangeProcessType}
          />
          {form.getFieldValue('process_type') === 1 || form.getFieldValue('process_type') === 3 ? (
            <FormBaseSelect
              data={dustCollectros.data ? dustCollectros.data : []}
              name="dust_collectors"
              cyPrefix="FCIE"
              label="Наличие пылеуловителя"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') === 1 ? (
            <FormBaseSelect
              data={dryerTypes.data ? dryerTypes.data : []}
              name="dryer_type"
              cyPrefix="FCIE"
              label="Вид сушилок"
              defaultValue={1}
              onChange={onChangeDryerType}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') !== 1 ? (
            <Form.Item
              name="equipment"
              label={
                form.getFieldValue('process_type') === 2
                  ? 'Вид оборудования для термообработки'
                  : form.getFieldValue('process_type') === 4
                  ? 'Вид моечного оборудования'
                  : 'Вид технологического оборудования'
              }
              initialValue={1}
            >
              <Select data-cy="FCIE-equipment-select" onChange={onChangeEquipment}>
                {equipments.data
                  ? equipments.data.map((equipment) => (
                      <Option data-cy={`FCIE-equipment-option-${equipment.id}`} key={equipment.id} value={equipment.id}>
                        {equipment.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') !== 2 ? (
            <Form.Item name="mark" label="Марка" initialValue={1}>
              <Select data-cy="FCIE-mark-select">
                {marks.data
                  ? marks.data.map((mark) => (
                      <Option data-cy={`FCIE-mark-option-${mark.id}`} key={mark.id} value={mark.id}>
                        {mark.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') !== 4 ? (
            <Form.Item name="pollutant" label="Наименование выбрасываемого вещества" initialValue={1}>
              <Select data-cy="FCIE-pollutant-select">
                {pollutants.data
                  ? pollutants.data.map((pollutant) => (
                      <Option data-cy={`FCIE-pollutant-option-${pollutant.id}`} key={pollutant.id} value={pollutant.id}>
                        {pollutant.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="FCIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
