import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IEAREngineModel, IEAREngineType, IEARReportData, IEARRunType, IEARVariable } from './interfaces';

const START_URL = 'ATPObkatkaDvigateleyLibrary';

export const getEAREngineTypes = async (): Promise<IEAREngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDvigatelya`);
  return data;
};

export const getEARRunTypes = async (): Promise<IEARRunType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidObkatki`);
  return data;
};

export const getEAREngineModels = async (engineTypeId: string | number): Promise<IEAREngineModel[]> => {
  let response;
  switch (engineTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetVidModelForDiesel`);
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetVidModelForBenzin`);
      break;
  }
  return response?.data;
};

export const getEARVariables = async (): Promise<IEARVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getEARFullReport = async (reportData: IEARReportData): Promise<IToolsReport[]> => {
  const { engineTypeId, runTypeId, engineModelId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: engineTypeId,
      paramName: 'DvigatelId',
    },
    {
      id: runTypeId,
      paramName: 'ObkatkaId',
    },
    {
      id: engineModelId,
      paramName: 'ModelBId',
    },
    {
      id: engineModelId,
      paramName: 'ModelDId',
    },
    ...variables,
  ]);
  return data;
};
