import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IGPIEAir,
  IGPIEDryerType,
  IGPIEDust,
  IGPIEEquipmentName,
  IGPIEEquipmentType,
  IGPIEFactoryName,
  IGPIEFactoryType,
  IGPIEProcessType,
  IGPIEReportData,
} from './interfaces';

const START_URL = 'PishepromZernoClass';

export const getGPIEProcessTypes = async (): Promise<IGPIEProcessType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProcessa`);
  return data;
};

export const getGPIEDryerTypes = async (): Promise<IGPIEDryerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZernosushilki`);
  return data;
};

export const getGPIEDusts = async (): Promise<IGPIEDust[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalZamerov`);
  return data;
};

export const getGPIEAirs = async (): Promise<IGPIEAir[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalDannyh`);
  return data;
};

export const getGPIEEquipmentTypes = async (): Promise<IGPIEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOborudZernoper`);
  return data;
};

export const getGPIEEquipmentNames = async (equipmentTypeId: string | number): Promise<IGPIEEquipmentName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPodvidOborudZernoper`, {
    params: { vidOborudZernoper: equipmentTypeId },
  });
  return data;
};

export const getGPIEFactoryTypes = async (): Promise<IGPIEFactoryType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOborudKrupy`);
  return data;
};

export const getGPIEFactoryNames = async (factoryTypeId: string | number): Promise<IGPIEFactoryName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPodvidOborudKrupy`, {
    params: { vidOborudKrupy: factoryTypeId },
  });
  return data;
};

export const getGPIEVariables = async (
  processTypeId: string | number,
  airId: string | number,
  dustId: string | number,
  sourceCount: string | number,
  equipmentCount: string | number,
): Promise<IGPIEFactoryType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { VidProcessa: processTypeId, NalDannyh: airId, NalZamerov: dustId, n: sourceCount, m: equipmentCount },
  });
  return data;
};

export const getGPIEFullReport = async (reportData: IGPIEReportData): Promise<IToolsReport[]> => {
  const {
    factoryNameId,
    factoryTypeId,
    processTypeId,
    airId,
    dustId,
    sourceCount,
    equipmentCount,
    equipmentNameId,
    equipmentTypeId,
    dryerTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(
    `${START_URL}/GetResponse?vidProcessa=${processTypeId}&n=${sourceCount}&m=${equipmentCount}`,
    [
      {
        id: equipmentTypeId,
        paramName: 'VidOborudZernoper',
      },
      {
        id: equipmentNameId,
        paramName: 'PodvidOborudZernoper',
      },
      {
        id: airId,
        paramName: 'NalDannyh',
      },
      {
        id: dustId,
        paramName: 'NalZamerov',
      },
      {
        id: factoryTypeId,
        paramName: 'VidOborudKrupy',
      },
      {
        id: factoryNameId,
        paramName: 'PodvidOborudKrupy',
      },
      {
        id: dryerTypeId,
        paramName: 'VidZernosushilki',
      },
      ...variables,
    ],
  );
  return data;
};
