import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IPWCleaningDevice,
  IPWMark,
  IPWMaterial,
  IPWPaintingMethod,
  IPWPaintingType,
  IPWProcess,
  IPWVariable,
} from './interfaces';

const START_URL = 'OkraskaClass';

export const getPWProcesses = async (): Promise<IPWProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProcess`);
  return data;
};

export const getPWPaintingTypes = async (paintMethodId: string | number): Promise<IPWPaintingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOkraski`, { params: { sposobOkraski: paintMethodId } });
  return data;
};

export const getPWPaintingMethods = async (): Promise<IPWPaintingMethod[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSposobOkraski`);
  return data;
};

export const getPWVariables = async (
  cleaningDeviceId: string | number,
  processId: string | number,
): Promise<IPWVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { ochistUstr: cleaningDeviceId, process: processId },
  });
  return data;
};

export const getPWMaterials = async (): Promise<IPWMaterial[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetLakokrMaterial`);
  return data;
};

export const getPWMarks = async (materialId: string | number): Promise<IPWMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarka`, { params: { lakokrMaterial: materialId } });
  return data;
};

export const getPWCleaningDevices = async (): Promise<IPWCleaningDevice[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOchistUstr`);
  return data;
};

export const getPWFullReport = async (reportData: {
  paintMethodId: string | number;
  paintingType: string | number;
  markId: string | number;
  processId: string | number;
  cleaningDeviceId: string | number;
  materialId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { paintMethodId, processId, markId, cleaningDeviceId, paintingType, variables, materialId } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: paintingType,
      paramName: 'VidOkraski',
    },
    {
      id: cleaningDeviceId,
      paramName: 'OchistUstr',
    },
    {
      id: processId,
      paramName: 'Process',
    },
    {
      id: paintMethodId,
      paramName: 'DBSposobOkraski',
    },
    {
      id: materialId,
      paramName: 'LakokrMaterial',
    },
    {
      id: markId,
      paramName: 'Marka',
    },
    ...variables,
  ]);
  return data;
};
