import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IVEmissionSeparation,
  IVLatitude,
  IVLifetime,
  IVMark,
  IVOperatingMode,
  IVOperatingModeCount,
  IVPollutant,
  IVReportData,
  IVSectionType,
  IVSpeed,
  IVTrackMachine,
  IVVariable,
} from './interfaces';

const START_URL = 'ZhDTransportTeplovozyClass';

export const getVEmissionSeparations = async (): Promise<IVEmissionSeparation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOpredelVybrosov`);
  return data;
};

export const getVPollutants = async (): Promise<IVPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZV`);
  return data;
};

export const getVSpeeds = async (): Promise<IVSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUchastkovSkorost`);
  return data;
};

export const getVLifetimes = async (): Promise<IVLifetime[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSrokEkspluatacii`);
  return data;
};

export const getVLatitudes = async (): Promise<IVLatitude[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSeverShirota`);
  return data;
};

export const getVOperatingModes = async (): Promise<IVOperatingMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRezhimRaboty`);
  return data;
};

export const getVOperatingModeCounts = async (): Promise<IVOperatingModeCount[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetChisloRezhimRaboty`);
  return data;
};

export const getVMarks = async (emissionSeparationId: string | number): Promise<IVMark[]> => {
  let result;

  switch (emissionSeparationId) {
    case 1:
      result = await apiClient.get(`${START_URL}/GetMarkaTeplovoza`);
      break;

    case 2:
      result = await apiClient.get(`${START_URL}/GetMarkaTeplovozaManevr`);
      break;

    case 3:
      result = await apiClient.get(`${START_URL}/GetMarkaTeplovozaPromyshl`);
      break;
  }
  return result?.data;
};

export const getVSectionTypes = async (): Promise<IVSectionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipSekcii`);
  return data;
};

export const getVTrackMachines = async (): Promise<IVTrackMachine[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPutevayaTehnika`);
  return data;
};

export const getVVariables = async (emissionSeparationId: string | number): Promise<IVVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { opredelVybrosov: emissionSeparationId },
  });
  return data;
};

export const getVFullReport = async (reportData: IVReportData): Promise<IToolsReport[]> => {
  const {
    emissionSeparationId,
    markId,
    pollutant,
    speedId,
    latitudeId,
    lifetimeId,
    operatingMode1,
    operatingMode2,
    operatingMode3,
    operatingMode4,
    operatingMode5,
    operatingModeCountId,
    sectionTypeId,
    trackMachineId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: emissionSeparationId,
      paramName: 'OpredelVybrosov',
    },
    {
      id: markId,
      paramName: 'MarkaTeplovoza',
    },
    {
      id: pollutant,
      paramName: 'VidZv',
    },
    {
      id: speedId,
      paramName: 'DBUchastkovSkorost',
    },
    {
      id: lifetimeId,
      paramName: 'DBSrokEkspluatacii',
    },
    {
      id: latitudeId,
      paramName: 'DBSevernShirota',
    },
    {
      id: operatingMode1,
      paramName: 'RezhimRaboty',
    },
    {
      id: operatingMode2,
      paramName: 'RezhimRaboty',
    },
    {
      id: operatingMode3,
      paramName: 'RezhimRaboty',
    },
    {
      id: operatingMode4,
      paramName: 'RezhimRaboty',
    },
    {
      id: operatingMode5,
      paramName: 'RezhimRaboty',
    },
    {
      id: operatingModeCountId,
      paramName: 'ChisloRezhimRaboty',
    },
    {
      id: sectionTypeId,
      paramName: 'DBTipSekcii',
    },
    {
      id: trackMachineId,
      paramName: 'DBPutevayaTehnika',
    },
    ...variables,
  ]);
  return data;
};
