import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IBSAirPollution, IBSVariable } from './interfaces';

const START_URL = 'ZhDTransportAkkumulUchClass';

export const getBSAirPollutions = async (): Promise<IBSAirPollution[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetZV`);
  return data;
};

export const getBSVariables = async (
  airPollutionId: string | number,
  batteryCount: string | number,
): Promise<IBSVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { zV: airPollutionId, kol: batteryCount },
  });
  return data;
};

export const getBSFullReport = async (reportData: {
  airPollution: string | number;
  batteryCount: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { airPollution, batteryCount, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: airPollution,
      paramName: 'ZV',
    },
    {
      value: batteryCount,
      paramName: 'Kol',
    },
    ...variables,
  ]);
  return data;
};
