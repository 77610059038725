import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { ICNIEReportData, ICNIESource, ICNIEVariable } from './interfaces';

const START_URL = 'PishepromKonservyClass';

export const getCNIESources = async (): Promise<ICNIESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`);
  return data;
};

export const getCNIEVatriables = async (): Promise<ICNIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getCNIEFullReport = async (reportData: ICNIEReportData): Promise<IToolsReport[]> => {
  const { sourceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: sourceId,
      paramName: 'DBIstochnik',
    },
    ...variables,
  ]);
  return data;
};
