import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getFEAnalyzes,
  getFECombustionMixtureTypes,
  getFEDischargeTypes,
  getFEFlareInstallations,
  getFEFlareParameters,
  getFEFullReport,
  getFEMeasurementResults,
  getFEShapes,
  getFEVariables,
} from '../../api/FlareEmissions';

import { IFEFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const FlareEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const flareInstallations = useQuery('FEFlareInstallations', getFEFlareInstallations);
  const measurmentResults = useQuery('FEMeasurmentResults', getFEMeasurementResults);
  const analyzes = useQuery('FEAnalyzes', getFEAnalyzes);
  const dischargeTypes = useQuery('FEDischargeTypes', getFEDischargeTypes);
  const combustionMixtureTypes = useQuery('FECombustionMixtureTypes', getFECombustionMixtureTypes);
  const shapes = useQuery('FEShapes', getFEShapes);
  const flareParametrs = useQuery('FEFlareParametrs', getFEFlareParameters);
  const variables = useQuery('FEVariables', () =>
    getFEVariables(
      form.getFieldValue('flare_installation'),
      form.getFieldValue('measurment_result'),
      form.getFieldValue('combustion_mixture_type'),
    ),
  );

  const onFinish = (values: IFEFormValues) => {
    const {
      discharge_type,
      combustion_mixture_type,
      shape,
      flare_installation,
      flare_parametr,
      measurment_result,
      analysis,
    } = values;

    if (variables.data) {
      getFormResult(getFEFullReport, {
        flareInstallationId: flare_installation,
        flareParametrId: flare_parametr || 1,
        combustionMixtureTypeId: combustion_mixture_type,
        shapeId: shape || 1,
        dischargeTypeId: discharge_type || 1,
        measurmentResultId: measurment_result || 1,
        analysisId: analysis || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeFlareInstallation = () => {
    variables.refetch();
  };

  const onChangeMeasurmentResult = () => {
    variables.refetch();
  };

  const onChangeCombustionMixtureType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={flareInstallations.data ? flareInstallations.data : []}
            name="flare_installation"
            cyPrefix="FE"
            label="Вид факельной установки"
            defaultValue={1}
            onChange={onChangeFlareInstallation}
          />
          {form.getFieldValue('flare_installation') === 1 ? (
            <FormBaseSelect
              data={flareParametrs.data ? flareParametrs.data : []}
              name="flare_parametr"
              cyPrefix="FE"
              label="Параметры факельной установки"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('flare_installation') === 2 ? (
            <FormBaseSelect
              data={dischargeTypes.data ? dischargeTypes.data : []}
              name="discharge_type"
              cyPrefix="FE"
              label="Вид сбросов"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('flare_installation') === 3 ? (
            <FormBaseSelect
              data={shapes.data ? shapes.data : []}
              name="shape"
              cyPrefix="FE"
              label="Геометрическая форма ямы-амбара"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={combustionMixtureTypes.data ? combustionMixtureTypes.data : []}
            name="combustion_mixture_type"
            cyPrefix="FE"
            label="Вид сжигаемой смеси"
            defaultValue={1}
            onChange={onChangeCombustionMixtureType}
          />
          {form.getFieldValue('combustion_mixture_type') === 3 ? (
            <FormBaseSelect
              data={analyzes.data ? analyzes.data : []}
              name="analysis"
              cyPrefix="FE"
              label="Результаты лабораторного анализа углевод. конденсата"
              defaultValue={1}
            />
          ) : (
            <FormBaseSelect
              data={measurmentResults.data ? measurmentResults.data : []}
              name="measurment_result"
              cyPrefix="FE"
              label="Результаты измерения объемного расхода газовых и газоконденсатных смесей и прир. газа"
              defaultValue={1}
              onChange={onChangeMeasurmentResult}
            />
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="FE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
