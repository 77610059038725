import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IDIECalculationType, IDIEReportData, IDIEVariable } from './interfaces';

const START_URL = 'Diesel221Prikaz';

export const getDIECalculationTypes = async (): Promise<IDIECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getDIEVariables = async (calculationTypeId: string | number): Promise<IDIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { vidRaschetaId: calculationTypeId } });
  return data;
};

export const getDIEFullReport = async (reportData: IDIEReportData): Promise<IToolsReport[]> => {
  const { calculationTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    ...variables,
  ]);
  return data;
};
