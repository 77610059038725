import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IHTBoilerType,
  IHTCalorificValue,
  IHTCollectorType,
  IHTFuelType,
  IHTFurnaceType,
  IHTpresenceOxide,
  IHTVariable,
} from './interfaces';

const START_URL = 'TBOClass';

export const getHTCollectorTypes = async (): Promise<IHTCollectorType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipZoloul`);
  return data;
};

export const getHTFurnaceTypes = async (): Promise<IHTFurnaceType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipTopki`);
  return data;
};

export const getHTBoilerTypes = async (): Promise<IHTBoilerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipKotla`);
  return data;
};

export const getHTPresenceOxides = async (): Promise<IHTpresenceOxide[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalichieAnalizov`);
  return data;
};

export const getHTCalorificValues = async (): Promise<IHTCalorificValue[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetToplivoParams`);
  return data;
};

export const getHTFuelTypes = async (): Promise<IHTFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getHTVariables = async (
  collectorTypeId: string | number,
  boilerTypeId: string | number,
  presenceOxideId: string | number,
  furnaceTypeId: string | number,
  componentsCount: string | number,
): Promise<IHTVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      tipTopki: furnaceTypeId,
      tipZoloul: collectorTypeId,
      nalichieAnalizov: presenceOxideId,
      tipKotla: boilerTypeId,
      n: componentsCount,
    },
  });
  return data;
};

export const getHTFullReport = async (reportData: {
  fuelTypeId: string | number;
  collectorTypeId: string | number;
  boilerTypeId: string | number;
  presenceOxideId: string | number;
  calorificValueId: string | number;
  furnaceTypeId: string | number;
  componentsCount: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    furnaceTypeId,
    fuelTypeId,
    calorificValueId,
    collectorTypeId,
    componentsCount,
    presenceOxideId,
    boilerTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse?n=${componentsCount}`, [
    {
      id: fuelTypeId,
      paramName: 'VidTopliva',
    },
    {
      id: calorificValueId,
      paramName: 'DBToplivo',
    },
    {
      id: collectorTypeId,
      paramName: 'TipZoloul',
    },
    {
      id: presenceOxideId,
      paramName: 'NalichieAnalizov',
    },
    {
      id: boilerTypeId,
      paramName: 'TipKotla',
    },
    {
      id: furnaceTypeId,
      paramName: 'TipTopki',
    },
    ...variables,
  ]);
  return data;
};
