import React from 'react';
import { Form, Row, Col } from 'antd';
import { IMethodologyModule, IPMEFormValues } from './interfaces';
import { useQuery } from 'react-query';
import {
  getPMEBathShelters,
  getPMECalculationTypes,
  getPMEDegreasingTypes,
  getPMEDetailCoatings,
  getPMEFullReport,
  getPMEMaterialCompositions,
  getPMEOperationTypes,
  getPMEProcessAutomations,
  getPMEProcessingTypes,
  getPMEProductionAreas,
  getPMETechnologicalOperations,
  getPMETechnologicalProcesses,
  getPMEVariables,
} from '../../api/ProductionMetalElectroplating';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const ProductionMetalElectroplating: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('PMECalculationTypes', getPMECalculationTypes);
  const processingTypes = useQuery('PMEProcessingTypes', getPMEProcessingTypes);
  const productionAreas = useQuery('PMEProductionAreas', getPMEProductionAreas);
  const bathShelters = useQuery('PMEBathShelter', getPMEBathShelters);
  const detailCoatings = useQuery('PMEDetailCoatings', getPMEDetailCoatings);
  const processAutomations = useQuery('PMEProcessAutomations', getPMEProcessAutomations);
  const technologicalProcesses = useQuery('PMETechnologicalProcesses', () =>
    getPMETechnologicalProcesses(
      form.getFieldValue('calculation_type') || 1,
      form.getFieldValue('production_area') || 1,
      form.getFieldValue('degreasing_type') || 1,
      form.getFieldValue('operation_type') || 1,
    ),
  );
  const technologicalOperations = useQuery('PMETechnologicalOperations', () =>
    getPMETechnologicalOperations(
      form.getFieldValue('calculation_type') || 1,
      form.getFieldValue('production_area') || 1,
      form.getFieldValue('degreasing_type') || 1,
      form.getFieldValue('operation_type') || 1,
      form.getFieldValue('technological_process') || 1,
    ),
  );
  const materialCompositions = useQuery('PMEMaterialCompositions', () =>
    getPMEMaterialCompositions(
      form.getFieldValue('calculation_type') || 1,
      form.getFieldValue('production_area') || 1,
      form.getFieldValue('degreasing_type') || 1,
      form.getFieldValue('operation_type') || 1,
      form.getFieldValue('technological_process') || 1,
      form.getFieldValue('technological_operation') || 1,
    ),
  );
  const degreasingTypes = useQuery('PMEDegreasingTypes', getPMEDegreasingTypes);
  const operationTypes = useQuery('PMEOperationTypes', () =>
    getPMEOperationTypes(form.getFieldValue('calculation_type') || 1, form.getFieldValue('degreasing_type') || 1),
  );
  const variables = useQuery('PMEVariables', () =>
    getPMEVariables(
      form.getFieldValue('calculation_type') || 1,
      form.getFieldValue('degreasing_type') || 1,
      form.getFieldValue('processing_type') || 1,
    ),
  );

  const onFinish = (values: IPMEFormValues) => {
    const {
      calculation_type,
      processing_type,
      degreasing_type,
      operation_type,
      technological_process,
      bath_shelter,
      detail_coatings,
      process_automation,
      technological_operation,
      material_composition,
      production_area,
    } = values;

    if (variables.data) {
      getFormResult(getPMEFullReport, {
        calculationTypeId: calculation_type,
        processingTypeId: processing_type,
        productionAreaId: production_area || 1,
        bathShelterId: bath_shelter || 1,
        detailCoatingId: detail_coatings || 1,
        processAutomationId: process_automation || 1,
        technologicalProcessId: technological_process || 1,
        technologicalOperationId: technological_operation || 1,
        materialCompositionId: material_composition || 1,
        degreasingTypeId: degreasing_type || 1,
        operationTypeId: operation_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
    operationTypes.refetch();
    materialCompositions.refetch();
    technologicalOperations.refetch();
    technologicalProcesses.refetch();
  };

  const onChangeProcessingType = () => {
    variables.refetch();
  };

  const onChangeProductionArea = () => {
    materialCompositions.refetch();
    technologicalOperations.refetch();
    technologicalProcesses.refetch();
  };

  const onChangeDegreasingType = () => {
    variables.refetch();
    operationTypes.refetch();
    materialCompositions.refetch();
    technologicalOperations.refetch();
    technologicalProcesses.refetch();
  };

  const onChangeOperationType = () => {
    materialCompositions.refetch();
    technologicalOperations.refetch();
    technologicalProcesses.refetch();
  };

  const onChangeTechnologicalProcess = () => {
    materialCompositions.refetch();
    technologicalOperations.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            label="Вид расчета"
            cyPrefix="PME"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={processingTypes.data ? processingTypes.data : []}
            name="processing_type"
            label="Провести расчет с учетом газоотчистки и гравитационного оседания аэрозоля в воздухопроводе"
            cyPrefix="PME"
            onChange={onChangeProcessingType}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') == 2 ? (
            <FormBaseSelect
              data={degreasingTypes.data ? degreasingTypes.data : []}
              name="degreasing_type"
              label="Вид обезжиривания"
              cyPrefix="PME"
              onChange={onChangeDegreasingType}
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('degreasing_type') == 1 || form.getFieldValue('degreasing_type') == 3 ? (
            <FormBaseSelect
              data={operationTypes.data ? operationTypes.data : []}
              name="operation_type"
              label="Вид операции"
              cyPrefix="PME"
              onChange={onChangeOperationType}
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {(form.getFieldValue('calculation_type') == 1 && form.getFieldValue('production_area') > 1) ||
          (form.getFieldValue('calculation_type') == 2 &&
            form.getFieldValue('degreasing_type') == 3 &&
            form.getFieldValue('operation_type') == 2) ? (
            <FormBaseSelect
              data={technologicalProcesses.data ? technologicalProcesses.data : []}
              name="technological_process"
              label="Вид технологического процесса"
              cyPrefix="PME"
              onChange={onChangeTechnologicalProcess}
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 1 ? (
            <FormBaseSelect
              data={productionAreas.data ? productionAreas.data : []}
              name="production_area"
              label="Участок производства"
              cyPrefix="PME"
              onChange={onChangeProductionArea}
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 1 ||
          (form.getFieldValue('calculation_type') == 2 && form.getFieldValue('degreasing_type') >= 2) ? (
            <>
              <FormBaseSelect
                data={bathShelters.data ? bathShelters.data : []}
                name="bath_shelter"
                label="Коэффициент укрытия ванны, К1"
                cyPrefix="PME"
                defaultValue={1}
              />
              <FormBaseSelect
                data={detailCoatings.data ? detailCoatings.data : []}
                name="detail_coatings"
                label="Коэффициент учитывающий способ покрытия мелких деталей, К4"
                cyPrefix="PME"
                defaultValue={1}
              />
              <FormBaseSelect
                data={processAutomations.data ? processAutomations.data : []}
                name="process_automation"
                label="Коэффициент учитывающий автоматизацию процесса, К5"
                cyPrefix="PME"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {(form.getFieldValue('calculation_type') == 1 && form.getFieldValue('production_area') != 2) ||
          (form.getFieldValue('calculation_type') == 2 && form.getFieldValue('degreasing_type') == 1) ? (
            <FormBaseSelect
              data={technologicalOperations.data ? technologicalOperations.data : []}
              name="technological_operation"
              label="Вид технологической операции"
              cyPrefix="PME"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('production_area') <= 2 ||
          (form.getFieldValue('calculation_type') == 2 &&
            form.getFieldValue('degreasing_type') == 3 &&
            form.getFieldValue('operation_type') == 2) ? (
            <FormBaseSelect
              data={materialCompositions.data ? materialCompositions.data : []}
              name="material_composition"
              label="Состав материалов"
              cyPrefix="PME"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="PME"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
