import React from 'react';
import { Form, Row, Col } from 'antd';
import { IAEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getAEAreaTypes,
  getAEBusTypes,
  getAEEngineTypes,
  getAEEngineValues,
  getAEFuelTypes,
  getAEFullReport,
  getAEInjectionOrCarburetor,
  getAELiftingCompacities,
  getAENeutralizerTypes,
  getAESources,
  getAEVariables,
} from '../../../../api/AreasEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const AreasEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const areaTypes = useQuery('AEAreaTypes', getAEAreaTypes);
  const sources = useQuery('AESources', getAESources);
  const engineTypes = useQuery('AEEngineTypes', () => getAEEngineTypes(form.getFieldValue('source')));
  const fuelTypes = useQuery('AEFuelTypes', () => getAEFuelTypes(form.getFieldValue('engine_type')));
  const engineValues = useQuery('AEEngineValues', getAEEngineValues);
  const injectionOrCarburetor = useQuery('AEInjectionOrCarburetor', getAEInjectionOrCarburetor);
  const neutralizerTypes = useQuery('AENeutralizerTypes', () => getAENeutralizerTypes(form.getFieldValue('source')));
  const liftingCompacities = useQuery('AELiftingCompacities', () =>
    getAELiftingCompacities(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const busTypes = useQuery('AEBusTypes', () =>
    getAEBusTypes(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const variables = useQuery('AEVariables', () => getAEVariables(form.getFieldValue('area_type')));

  const onFinish = (values: IAEFormValues) => {
    const {
      area_type,
      source,
      engine_type,
      engine_value,
      fuel_type,
      injection_or_carburetor,
      neutralizer_type,
      lifting_compacity,
      bus_type,
    } = values;

    if (variables.data) {
      getFormResult(getAEFullReport, {
        areaTypeId: area_type,
        sourceId: source,
        EngineTypeId: engine_type,
        FuelTypeId: fuel_type,
        EngineValueId: engine_value || 1,
        injectionOrCarburetorId: injection_or_carburetor || 1,
        neutralizerTypeId: neutralizer_type || 1,
        liftingCompacityId: lifting_compacity || 1,
        busTypeId: bus_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEngineType = () => {
    fuelTypes.refetch();
    liftingCompacities.refetch();
  };

  const onChangeSource = () => {
    engineTypes.refetch();
    neutralizerTypes.refetch();
  };

  const onChangeAreaType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={areaTypes.data ? areaTypes.data : []}
            name="area_type"
            cyPrefix="AE"
            label="Вид помещения зоны ТО и ТР"
            defaultValue={1}
            onChange={onChangeAreaType}
          />
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="AE"
            label="Передвежные источники"
            defaultValue={1}
            onChange={onChangeSource}
          />
          {form.getFieldValue('source') == 5 || form.getFieldValue('source') == 6 ? (
            <FormBaseSelect
              data={busTypes.data ? busTypes.data : []}
              name="bus_type"
              cyPrefix="AE"
              label="Класс автобуса"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('source') == 3 || form.getFieldValue('source') == 4 ? (
            <FormBaseSelect
              data={liftingCompacities.data ? liftingCompacities.data : []}
              name="lifting_compacity"
              cyPrefix="AE"
              label="Грузоподъемность"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          <FormBaseSelect
            data={engineTypes.data ? engineTypes.data : []}
            name="engine_type"
            cyPrefix="AE"
            label="Тип двигателя"
            defaultValue={1}
            onChange={onChangeEngineType}
          />
          {form.getFieldValue('engine_type') == 2 ? (
            <FormBaseSelect
              data={injectionOrCarburetor.data ? injectionOrCarburetor.data : []}
              name="injection_or_carburetor"
              cyPrefix="AE"
              label="Впрыск или карбюратор"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('engine_type') == 4 ? (
            <FormBaseSelect
              data={neutralizerTypes.data ? neutralizerTypes.data : []}
              name="neutralizer_type"
              cyPrefix="AE"
              label="Вид сертифицированного каталитического нейтролизатора"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          <FormBaseSelect
            data={engineValues.data ? engineValues.data : []}
            name="engine_value"
            cyPrefix="AE"
            label="Рабочий обьем двигателя"
            defaultValue={1}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="AE"
            label="Вид топлива"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="AE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
