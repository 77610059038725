import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IFIEBranchType, IFIEBurnedWood, IFIEEquipmentType, IFIEProcessType, IFIESource } from './interfaces';

const START_URL = 'PishepromRybaClass';

export const getFIEProcessTypes = async (): Promise<IFIEProcessType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProcessa`);
  return data;
};

export const getFIEEquipmentTypes = async (processTypeId: string | number): Promise<IFIEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOborud`, { params: { vidProcessa: processTypeId } });
  return data;
};

export const getFIESources = async (
  processTypeId: string | number,
  equipmentTypeId: string | number,
): Promise<IFIESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`, {
    params: { vidProcessa: processTypeId, vidOborud: equipmentTypeId },
  });
  return data;
};

export const getFIEBurnedWoods = async (): Promise<IFIEBurnedWood[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDrevesiny`);
  return data;
};

export const getFIEBranchTypes = async (): Promise<IFIEBranchType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDrevesiny`);
  return data;
};

export const getFIEVariables = async (
  processTypeId: string | number,
  equipmentTypeId: string | number,
): Promise<IFIESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidProcessa: processTypeId, vidOborud: equipmentTypeId },
  });
  return data;
};

export const getFIEFullReport = async (reportData: {
  processTypeId: string | number;
  equipmentTypeId: string | number;
  sourceId: string | number;
  burnedWoodId: string | number;
  branchTypeId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { processTypeId, equipmentTypeId, sourceId, burnedWoodId, branchTypeId, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: equipmentTypeId,
      paramName: 'VidOborud',
    },
    {
      id: sourceId,
      paramName: 'Istochnik',
    },
    {
      id: processTypeId,
      paramName: 'VidProcessa',
    },
    {
      id: burnedWoodId,
      paramName: 'VidDrevesiny',
    },
    {
      id: branchTypeId,
      paramName: 'VidOtdeleniya',
    },
    ...variables,
  ]);
  return data;
};
