import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ITPPAditionalAirFraction,
  ITPPAshCollectorType,
  ITPPBoilerType,
  ITPPBurnerDesign,
  ITPPCalculationType,
  ITPPFlueGasRecirculationMethod,
  ITPPFuelType,
  ITPPGasTemperature,
  ITPPGetFullReportResponse,
  ITPPInstallationType,
  ITPPNitricOxidePresence,
  ITPPOperationMode,
  ITPPSlagRemovalType,
  ITPPSulfurPresence,
  ITPPSuperheaterPresenceOption,
  ITPPVariable,
} from './interfaces';

const START_URL = 'PowerStationBoilerHouseClass';

export const getTPPCalculationTypes = async (): Promise<ITPPCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getTPPInstallationTypes = async (): Promise<ITPPInstallationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidUstanovki`);
  return data;
};

export const getTPPBoilerTypes = async (): Promise<ITPPBoilerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKotla`);
  return data;
};

export const getTPPFuelTypes = async (
  installationTypeId: string | number,
  cellType: string | number,
): Promise<ITPPFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, {
    params: { vidUstanovki: installationTypeId, tipkameri: cellType },
  });
  return data;
};

export const getTPPSulfurPresenceOptions = async (): Promise<ITPPSulfurPresence[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSera`);
  return data;
};

export const getTPPNitricOxidePresenceOptions = async (): Promise<ITPPNitricOxidePresence[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNO`);
  return data;
};

export const getTPPOperationModes = async (): Promise<ITPPOperationMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRejimRaboty`);
  return data;
};

export const getTPPAshCollectorTypes = async (): Promise<ITPPAshCollectorType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZoloUlov`);
  return data;
};

export const getTPPFlueGasRecirculationMethods = async (): Promise<ITPPFlueGasRecirculationMethod[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetE1`);
  return data;
};

export const getTPPBurnerDesigns = async (): Promise<ITPPBurnerDesign[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetB2`);
  return data;
};

export const getTPPSlagRemovalTypes = async (): Promise<ITPPSlagRemovalType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetB3`);
  return data;
};

export const getTPPAditionalAirFractions = async (fuelTypeId: string | number): Promise<ITPPAditionalAirFraction[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetE2`, { params: { vidToplivaId: fuelTypeId } });
  return data;
};

export const getTPPGasTemperatures = async (cellType: string | number): Promise<ITPPGasTemperature[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKr`, { params: { tipkameri: cellType } });
  return data;
};

export const getTPPSuperheaterPresenceOptions = async (): Promise<ITPPSuperheaterPresenceOption[]> => {
  const { data } = await apiClient.get(`${START_URL}/Getnoc`);
  return data;
};

export const getTPPVariables = async (
  calculationTypeId: string | number,
  installationTypeId: string | number,
  fuelTypeId: string | number,
  sulfurPresenceOptionId: string | number,
): Promise<ITPPVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidraschetaId: calculationTypeId,
      vidUstanovki: installationTypeId,
      vidTopliva: fuelTypeId,
      sera: sulfurPresenceOptionId,
    },
  });
  return data;
};

export const getTPPFullReport = async (reportData: ITPPGetFullReportResponse): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    installationTypeId,
    fuelTypeId,
    sulfurPresenceOption,
    boilerTypeId,
    nitricOxidePresenceOptionId,
    superheaterPresenceOptionId,
    slagRemovalTypeId,
    operationModeId,
    ashCollectorTypeId,
    flueGasRecirculationMethodId,
    burnerDesignId,
    aditionalAirFractionId,
    gasTemperatureId,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: installationTypeId,
      paramName: 'VidUstanovki',
    },
    {
      id: fuelTypeId,
      paramName: 'VidToplivaId',
    },
    {
      id: sulfurPresenceOption,
      paramName: 'Sera',
    },
    {
      id: 1,
      paramName: 'Tipkameri',
    },
    {
      id: boilerTypeId,
      paramName: 'VidKotla',
    },
    {
      id: nitricOxidePresenceOptionId,
      paramName: 'NO',
    },
    {
      id: superheaterPresenceOptionId,
      paramName: 'noc',
    },
    {
      id: operationModeId,
      paramName: 'RejimRaboty',
    },
    {
      id: ashCollectorTypeId,
      paramName: 'VidZoloUlov',
    },
    {
      id: flueGasRecirculationMethodId,
      paramName: 'E1',
    },
    {
      id: burnerDesignId,
      paramName: 'B2',
    },
    {
      id: slagRemovalTypeId,
      paramName: 'B3',
    },
    {
      id: aditionalAirFractionId,
      paramName: 'E2',
    },
    {
      id: gasTemperatureId,
      paramName: 'Kr',
    },
    {
      id: 1,
      paramName: 'TipGTUId',
    },
    ...variables.filter((elem) => elem.paramName !== 'TipGTUId'),
  ]);
  return data;
};
