import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IHEEEquipmentType, IHEEOil, IHEEReportData, IHEEVariable } from './interfaces';

const START_URL = 'SredstvaPerekachkiLibrary';

export const getHEEOils = async (): Promise<IHEEOil[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNefteprodukt`);
  return data;
};

export const getHEEEquipmentTypes = async (): Promise<IHEEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOborudovaniya`);
  return data;
};

export const getHEEVariables = async (): Promise<IHEEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getHEEFullReport = async (reportData: IHEEReportData): Promise<IToolsReport[]> => {
  const { oilId, equipmentTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: oilId,
      paramName: 'NefteproduktId',
    },
    {
      id: equipmentTypeId,
      paramName: 'OborudovanieId',
    },
    ...variables,
  ]);
  return data;
};
