import { apiClient } from '../Client';
import { IAGFSVariable } from './interfaces';
import { IToolsReport } from '../Tools/interface';
import { IReportVariableObject } from '../Interfaces';

const START_URL = 'AutoStation';

export const getAGFSVariables = async (): Promise<IAGFSVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getAGFSFullReport = async (reportData: {
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [...variables]);
  return data;
};
