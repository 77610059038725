import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IEEPMProductionType, IEEPMSource, IEEPMVariable } from './interfaces';

const START_URL = 'PishepromMelassaClass';

export const getEEPMProductionTypes = async (): Promise<IEEPMProductionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProizvodstva`);
  return data;
};

export const getEEPMSources = async (productionTypeId: string | number): Promise<IEEPMSource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`, {
    params: { vidProizvodstva: productionTypeId },
  });
  return data;
};

export const getEEMPVariables = async (): Promise<IEEPMVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getEEPMFullReport = async (reportData: {
  productionTypeId: string | number;
  sourceId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { productionTypeId, sourceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: productionTypeId,
      paramName: 'VidProizvodstva',
    },
    {
      id: sourceId,
      paramName: 'Istochnik',
    },
    ...variables,
  ]);
  return data;
};
