import { FormInstance } from 'rc-field-form';
import { IReportVariableObject, IStandartData } from '../api/Interfaces';

export function generateReportingVariablesArray(
  variables: IStandartData[],
  form: FormInstance,
): IReportVariableObject[] {
  const reportVariablesArray: IReportVariableObject[] = [];

  for (let i = 0; i <= variables.length - 1; i++) {
    reportVariablesArray[i] = {
      value: form.getFieldValue(variables[i].name) || 0,
      paramName: variables[i].paramName,
    };
  }

  return reportVariablesArray;
}
