import React from 'react';
import { Form, Row, Col } from 'antd';
import { ILREFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getLREClimaticZones,
  getLREFullReport,
  getLREOilGroups,
  getLREOils,
  getLREOperationModes,
  getLRETankDesigns,
  getLREVariables,
} from '../../../../api/LoadingRacksEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const LoadingRacksEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const climaticZones = useQuery('LREClimaticZones', getLREClimaticZones);
  const oils = useQuery('LREOils', getLREOils);
  const oilGroups = useQuery('LREOilGroups', getLREOilGroups);
  const tankDesigns = useQuery('LRETankDesigns', getLRETankDesigns);
  const operationModes = useQuery('LREOperationModes', () => getLREOperationModes(form.getFieldValue('tank_design')));
  const variables = useQuery('LREVariables', getLREVariables);

  const onFinish = (values: ILREFormValues) => {
    const { climatic_zone, oil, oil_group, tank_design, operation_mode } = values;

    if (variables.data) {
      getFormResult(getLREFullReport, {
        climaticZoneId: climatic_zone,
        oilId: oil,
        oilGroupId: oil_group,
        tankDesignId: tank_design,
        operationModeId: operation_mode,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeTankDesign = () => {
    operationModes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={climaticZones.data ? climaticZones.data : []}
            name="climatic_zone"
            cyPrefix="LRE"
            label="Климатическая зона"
            defaultValue={1}
          />
          <FormBaseSelect
            data={oils.data ? oils.data : []}
            name="oil"
            cyPrefix="LRE"
            label="Нефтепродукт"
            defaultValue={1}
          />
          <FormBaseSelect
            data={oilGroups.data ? oilGroups.data : []}
            name="oil_group"
            cyPrefix="LRE"
            label="Группа нефтепродукта"
            defaultValue={1}
          />
          <FormBaseSelect
            data={tankDesigns.data ? tankDesigns.data : []}
            name="tank_design"
            cyPrefix="LRE"
            label="Конструкция резервуара"
            onChange={onChangeTankDesign}
            defaultValue={1}
          />
          <FormBaseSelect
            data={operationModes.data ? operationModes.data : []}
            name="operation_mode"
            cyPrefix="LRE"
            label="Режим эксплуатации"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="LRE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
