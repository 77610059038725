export const downloadFileFromResponse = (data: File, fileName: string, fileExtension: string): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = downloadUrl;
  link.setAttribute('download', `${fileName}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
