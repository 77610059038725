import { apiClient } from '../Client';
import {
  IBHSCFuelType,
  IBHSCCalculationType,
  IBHSCSolidField,
  IBHSCGasField,
  IBHSCLiquidField,
  IBHSCSulfurOxide,
  IBHSCHeatLoss,
  IBHSCFurnaceType,
  IBHSCVanadiumProportion,
  IBHSCVariable,
  IHBSCFuelAggregationState,
} from './interfaces';
import { IToolsReport } from '../Tools/interface';
import { IReportVariableObject } from '../Interfaces';

const START_URL = 'BoilerHouseSmallClass';

export const getBHSCFuelAggregationState = async (fuelTypeId: string | number): Promise<IHBSCFuelAggregationState> => {
  const { data } = await apiClient.put(`/${START_URL}/SGetVidTopliva?_sGetVidTopliva=${fuelTypeId}`);
  return data;
};

export const getBHSCFuelTypes = async (): Promise<IBHSCFuelType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidTopliva`);
  return data;
};

export const getBHSCCalculationTypes = async (): Promise<IBHSCCalculationType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidRascheta`);
  return data;
};

export const getBHSCFields = async (): Promise<{
  gas: IBHSCGasField[];
  liquid: IBHSCLiquidField[];
  solid: IBHSCSolidField[];
}> => {
  const { data } = await apiClient.get(`/${START_URL}/GetMestorojConstALL`);
  return data;
};

export const getBHSCSulfurOxides = async (): Promise<IBHSCSulfurOxide[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetNso2`);
  return data;
};

export const getBHSCFurnaceTypes = async (): Promise<IBHSCFurnaceType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetTipTopki2`);
  return data;
};

export const getBHSCHeatLosses = async (): Promise<IBHSCHeatLoss[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetTipTopki`);
  return data;
};

export const getBHSCVanadiumProportions = async (): Promise<IBHSCVanadiumProportion[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetNv`);
  return data;
};

export const getBHSCVariables = async (
  calculationTypeId: string | number,
  fuelTypeId: string | number,
): Promise<IBHSCVariable[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetInputData`, {
    params: { vidraschetaId: calculationTypeId, vidToplivaId: fuelTypeId },
  });
  return data;
};

export const getBHSCFullReport = async (reportData: {
  calculationTypeId: string | number;
  fuelTypeId: string | number;
  fieldId: string | number;
  heatLossId: string | number;
  furnaceTypeId: string | number;
  sulfurOxideId: string | number;
  vanadiumProportion: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    fuelTypeId,
    fieldId,
    furnaceTypeId,
    sulfurOxideId,
    vanadiumProportion,
    heatLossId,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`/${START_URL}/getResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: fuelTypeId,
      paramName: 'VidToplivaId',
    },
    {
      id: fieldId,
      paramName: 'DataBaseMestorojId',
    },
    {
      id: furnaceTypeId,
      paramName: 'DataBaseTipTopki2Id',
    },
    {
      id: sulfurOxideId,
      paramName: 'Nso2DataBase',
    },
    {
      id: vanadiumProportion,
      paramName: 'NvDataBase',
    },
    {
      id: heatLossId,
      paramName: 'DataBaseTipTopkiId',
    },
    ...variables,
  ]);
  return data;
};

export const getFileReport = async (): Promise<IBHSCVanadiumProportion[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetNv`);
  return data;
};
