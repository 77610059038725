import React from 'react';
import { Form, Row, Col } from 'antd';
import { IMEFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import {
  getMECalcualtionTypes,
  getMECastingWeights,
  getMECleaningTypes,
  getMEEquipmentTypes,
  getMEFuelStoveTypes,
  getMEFullReport,
  getMEMeltingConditions,
  getMEMixtureSubtypes,
  getMEMixtureTypes,
  getMEMoldEquipments,
  getMEOilMarks,
  getMEOilProcesses,
  getMEOvenTypes,
  getMEProcessTypes,
  getMEProductionTechnologies,
  getMESmeltingTypes,
  getMESteelTypes,
  getMEVariables,
  getMEWorkTypes,
} from '../../api/MechanicalEngineering';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const MechanicalEngineering: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('MECalculationTypes', getMECalcualtionTypes);
  const processTypes = useQuery('MEProcessTypes', getMEProcessTypes);
  const steelTypes = useQuery('MESteelTypes', getMESteelTypes);
  const productionTechnologies = useQuery('MEProductionTechnologies', getMEProductionTechnologies);
  const ovenTypes = useQuery('MEOvenTypes', getMEOvenTypes);
  const meltingConditions = useQuery('MEMeltingConditions', getMEMeltingConditions);
  const fuelStoveTypes = useQuery('MEFuelStoveTypes', getMEFuelStoveTypes);
  const smeltingTypes = useQuery('MESmeltingTypes', getMESmeltingTypes);
  const castingWeights = useQuery('MECastingWeight', getMECastingWeights);
  const mixtureTypes = useQuery('MEMixtureType', getMEMixtureTypes);
  const mixtureSubtypes = useQuery('MEMixtureSubtypes', () => getMEMixtureSubtypes(form.getFieldValue('mixture_type')));
  const workTypes = useQuery('MEWorkTypes', getMEWorkTypes);
  const equipmentTypes = useQuery('MEEquipmentTypes', getMEEquipmentTypes);
  const oilMarks = useQuery('MEOilMarks', getMEOilMarks);
  const oilProcesses = useQuery('MEOilProcesses', getMEOilProcesses);
  const moldEquipments = useQuery('MEMoldEquipments', getMEMoldEquipments);
  const cleaningTypes = useQuery('MECleaningTypes', getMECleaningTypes);
  const variables = useQuery('MEVariables', getMEVariables);

  const onFinish = (values: IMEFormValues) => {
    const {
      calculation_type,
      process_type,
      steel_type,
      production_technology,
      fuel_stove_type,
      melting_condition,
      casting_weight,
      smelting_type,
      mixture_type,
      mixture_subtype,
      oven_type,
      work_type,
      equipment_type,
      oil_mark,
      oil_process,
      mold_equipment,
      cleaning_type,
    } = values;

    if (variables.data) {
      getFormResult(getMEFullReport, {
        calculatorTypeId: calculation_type || 1,
        processTypeId: process_type || 1,
        steelTypeId: steel_type || 1,
        productionTechnologyId: production_technology || 1,
        ovenTypeId: oven_type || 1,
        meltingConditionId: melting_condition || 1,
        stoveTypeId: fuel_stove_type || 1,
        smeltingTypeId: smelting_type || 1,
        castingWeightId: casting_weight || 1,
        mixtureTypeId: mixture_type || 1,
        mixtureSubtypeId: mixture_subtype || 1,
        workTypeId: work_type || 1,
        equipmentId: equipment_type || 1,
        oilMarkId: oil_mark || 1,
        oilProcessId: oil_process || 1,
        moldEquipmentId: mold_equipment || 1,
        cleaningTypeId: cleaning_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeMixtureType = () => {
    mixtureSubtypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="ME"
            label="Вид расчета"
            defaultValue={1}
          />
          <FormBaseSelect
            data={processTypes.data ? processTypes.data : []}
            name="process_type"
            cyPrefix="ME"
            label="Вид процесса"
            onChange={() => variables.refetch()}
            defaultValue={1}
          />
          <FormBaseSelect
            data={steelTypes.data ? steelTypes.data : []}
            name="steel_type"
            cyPrefix="ME"
            label="Тип стали"
            defaultValue={1}
          />
          <FormBaseSelect
            data={productionTechnologies.data ? productionTechnologies.data : []}
            name="production_technology"
            cyPrefix="ME"
            label="Технология производства"
            defaultValue={1}
          />
          {form.getFieldValue('process_type') == 1 ? (
            <FormBaseSelect
              data={fuelStoveTypes.data ? fuelStoveTypes.data : []}
              name="fuel_stove_type"
              cyPrefix="ME"
              label="Тип варганок"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={meltingConditions.data ? meltingConditions.data : []}
            name="melting_condition"
            cyPrefix="ME"
            label="Условия плавки"
            defaultValue={1}
          />

          {form.getFieldValue('process_type') == 2 ? (
            <FormBaseSelect
              data={castingWeights.data ? castingWeights.data : []}
              name="casting_weight"
              cyPrefix="ME"
              label="Масса отливок, m, кг"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={smeltingTypes.data ? smeltingTypes.data : []}
            name="smelting_type"
            cyPrefix="ME"
            label="Тип выплавки"
            defaultValue={1}
          />
          {form.getFieldValue('process_type') == 3 ? (
            <>
              <FormBaseSelect
                data={mixtureTypes.data ? mixtureTypes.data : []}
                name="mixture_type"
                cyPrefix="ME"
                label="Вид процесса проготовления формовочных смессей"
                onChange={onChangeMixtureType}
                defaultValue={1}
              />
              <FormBaseSelect
                data={mixtureSubtypes.data ? mixtureSubtypes.data : []}
                name="mixture_subtype"
                cyPrefix="ME"
                label="Подвид процесса проготовления формовочных смессей"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 5 ? (
            <FormBaseSelect
              data={ovenTypes.data ? ovenTypes.data : []}
              name="oven_type"
              cyPrefix="ME"
              label="Тип печи"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 7 ? (
            <FormBaseSelect
              data={workTypes.data ? workTypes.data : []}
              name="work_type"
              cyPrefix="ME"
              label="Вид работ"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 9 ? (
            <FormBaseSelect
              data={equipmentTypes.data ? equipmentTypes.data : []}
              name="equipment_type"
              cyPrefix="ME"
              label="Тип оборудования"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 10 ? (
            <>
              <FormBaseSelect
                data={oilProcesses.data ? oilProcesses.data : []}
                name="oil_process"
                cyPrefix="ME"
                label="Процесс"
                defaultValue={1}
              />
              <FormBaseSelect
                data={oilMarks.data ? oilMarks.data : []}
                name="oil_mark"
                cyPrefix="ME"
                label="Марка смолы"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 11 ? (
            <FormBaseSelect
              data={moldEquipments.data ? moldEquipments.data : []}
              name="mold_equipment"
              cyPrefix="ME"
              label="Оборудование выбивки форм и стержней"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('process_type') == 12 ? (
            <FormBaseSelect
              data={cleaningTypes.data ? cleaningTypes.data : []}
              name="cleaning_type"
              cyPrefix="ME"
              label="Способ очистки литья черных металлов, оборудование"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="ME"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
