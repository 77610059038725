import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col, Select } from 'antd';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getTEEECalculationTypes,
  getTEEECleaningTypes,
  getTEEEEquipmentTypes,
  getTEEEFullReport,
  getTEEEInstallations,
  getTEEEMeltingTypes,
  getTEEEMetalTypes,
  getTEEEProductionTypes,
  getTEEETechnicalProcesses,
  getTEEETreatmentPlants,
  getTEEEVariables,
} from '../../../../api/TechnologicalEquipmentEngineeringEnterprises';

import { IMethodologyModule, ITEEEFormValues } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const TechnologicalEquipmentEngineeringEnterprises: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('TEEECalculationTypes', getTEEECalculationTypes);
  const meltingTypes = useQuery('TEEEMeltingTypes', getTEEEMeltingTypes);
  const metalTypes = useQuery('TEEEMetalTypes', getTEEEMetalTypes);
  const equipmentTypes = useQuery('TEEEEquipmentTypes', getTEEEEquipmentTypes);
  const productionTypes = useQuery('TEEEProductionTypes', getTEEEProductionTypes);
  const cleaningTypes = useQuery('TEEECleaningTypes', getTEEECleaningTypes);
  const technicalProcesses = useQuery('TEEETechnicalProcesses', getTEEETechnicalProcesses);
  const installations = useQuery('TEEEInstallations', () =>
    getTEEEInstallations(form.getFieldValue('production_type')),
  );
  const treatmentPlants = useQuery('TEEETreatmentPlants', getTEEETreatmentPlants);
  const variables = useQuery('TEEEVariables', () => getTEEEVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: ITEEEFormValues) => {
    const {
      calculation_type,
      cleaning_type,
      melting_type,
      metal_type,
      equipment_type,
      production_type,
      technical_proccess,
      installation,
    } = values;

    if (variables.data) {
      getFormResult(getTEEEFullReport, {
        calculationTypeId: calculation_type,
        equipmentTypeId: equipment_type || 1,
        meltingTypeId: melting_type || 1,
        metalTypeId: metal_type || 1,
        productionTypeId: production_type || 1,
        cleaningTypeId: cleaning_type || 1,
        technicalProcessId: technical_proccess || 1,
        installationId: installation || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  const onChangeProductionType = () => {
    installations.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="TEEE"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalculationType}
          />
          {form.getFieldValue('calculation_type') === 1 ? (
            <>
              <FormBaseSelect
                data={meltingTypes.data ? meltingTypes.data : []}
                name="melting_type"
                cyPrefix="TEEE"
                label="Вид плавки"
                defaultValue={1}
              />
              <FormBaseSelect
                data={metalTypes.data ? metalTypes.data : []}
                name="metal_type"
                cyPrefix="TEEE"
                label="Вид метала"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 1 ||
          form.getFieldValue('calculation_type') === 3 ||
          form.getFieldValue('calculation_type') === 4 ||
          form.getFieldValue('calculation_type') === 5 ? (
            <FormBaseSelect
              data={treatmentPlants.data ? treatmentPlants.data : []}
              name="treatment_plant"
              cyPrefix="TEEE"
              label="Вид очистного сооружения"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 3 ? (
            <>
              <FormBaseSelect
                data={productionTypes.data ? productionTypes.data : []}
                name="production_type"
                cyPrefix="TEEE"
                label="Вид производства"
                defaultValue={1}
                onChange={onChangeProductionType}
              />
              <Form.Item name="installation" label="Установки" initialValue={1}>
                <Select data-cy="TEEE-installation-select">
                  {installations.data
                    ? installations.data.map((field) => (
                        <Option data-cy={`TEEE-installation-option-${field.id}`} key={field.id} value={field.id}>
                          {field.type}
                        </Option>
                      ))
                    : ''}
                </Select>
              </Form.Item>
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 4 ? (
            <FormBaseSelect
              data={equipmentTypes.data ? equipmentTypes.data : []}
              name="equipment_type"
              cyPrefix="TEEE"
              label="Вид оборудования"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') === 5 || form.getFieldValue('calculation_type') === 6 ? (
            <FormBaseSelect
              data={cleaningTypes.data ? cleaningTypes.data : []}
              name="cleaning_type"
              cyPrefix="TEEE"
              label="Вид очистки"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') === 7 ? (
            <FormBaseSelect
              data={technicalProcesses.data ? technicalProcesses.data : []}
              name="technical_proccess"
              cyPrefix="TEEE"
              label="Вид тех процесса"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TEEE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
