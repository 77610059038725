import React from 'react';
import { useQuery } from 'react-query';
import { Form, InputNumber, Row, Col } from 'antd';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import { getBSAirPollutions, getBSFullReport, getBSVariables } from '../../../../api/BatterySection';

import { IBSFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const BatterySection: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const airPollutions = useQuery('BSAirPolutions', getBSAirPollutions);
  const variables = useQuery('BSVariables', () =>
    getBSVariables(form.getFieldValue('air_pollution'), form.getFieldValue('battery_count') || 1),
  );

  const onFinish = (value: IBSFormValues) => {
    const { air_pollution, battery_count } = value;

    if (variables.data) {
      getFormResult(getBSFullReport, {
        airPollution: air_pollution,
        batteryCount: battery_count,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={airPollutions.data ? airPollutions.data : []}
            name="air_pollution"
            cyPrefix="BS"
            label="ЗВ при работе с аккумуляторыми батареями"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          {form.getFieldValue('air_pollution') === 1 ? (
            <Form.Item name="battery_count" label="Количество видов батарей">
              <InputNumber
                data-cy="BS-battery_count-input"
                min={1}
                placeholder={'1'}
                defaultValue={1}
                onChange={handleChangeVariables}
              />
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BS"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
