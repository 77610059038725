import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IMECalculationType,
  IMECastingWeight,
  IMECleaningType,
  IMEEquipmentType,
  IMEFuelStoveType,
  IMEMeltingCondition,
  IMEMixtureSubtype,
  IMEMixtureType,
  IMEMoldEquipment,
  IMEOilMark,
  IMEOliProcess,
  IMEOvenType,
  IMEProcessType,
  IMEProductionTechnology,
  IMEReportData,
  IMESmeltingType,
  IMESteelType,
  IMEVariables,
  IMEWorkType,
} from './interfaces';

const START_URL = 'MashinostroenieClass';

export const getMECalcualtionTypes = async (): Promise<IMECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getMEProcessTypes = async (): Promise<IMEProcessType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProcessa`);
  return data;
};

export const getMESteelTypes = async (): Promise<IMESteelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipStali`);
  return data;
};

export const getMEProductionTechnologies = async (): Promise<IMEProductionTechnology[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipPechi`);
  return data;
};

export const getMEOvenTypes = async (): Promise<IMEOvenType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipPechi26`);
  return data;
};

export const getMEMeltingConditions = async (): Promise<IMEMeltingCondition[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUslovPlavki`);
  return data;
};

export const getMEFuelStoveTypes = async (): Promise<IMEFuelStoveType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipVagranok`);
  return data;
};

export const getMESmeltingTypes = async (): Promise<IMESmeltingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVyplavka`);
  return data;
};

export const getMECastingWeights = async (): Promise<IMECastingWeight[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMassaOtlivok`);
  return data;
};

export const getMEMixtureTypes = async (): Promise<IMEMixtureType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProcPrigotovleniya`);
  return data;
};

export const getMEMixtureSubtypes = async (mixtureTypeId: string | number): Promise<IMEMixtureSubtype[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPodvidProcPrigotovleniya`, {
    params: { vidProcPrigotovleniya: mixtureTypeId },
  });
  return data;
};

export const getMEWorkTypes = async (): Promise<IMEWorkType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRabot`);
  return data;
};

export const getMEEquipmentTypes = async (): Promise<IMEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipOborud`);
  return data;
};

export const getMEOilMarks = async (): Promise<IMEOilMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarkaSmoly`);
  return data;
};

export const getMEOilProcesses = async (): Promise<IMEOliProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProcessSmoly`);
  return data;
};

export const getMEMoldEquipments = async (): Promise<IMEMoldEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOborudVybivki`);
  return data;
};

export const getMECleaningTypes = async (): Promise<IMECleaningType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSposobOchistki`);
  return data;
};

export const getMEVariables = async (): Promise<IMEVariables[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getMEFullReport = async (reportData: IMEReportData): Promise<IToolsReport[]> => {
  const {
    calculatorTypeId,
    processTypeId,
    steelTypeId,
    productionTechnologyId,
    ovenTypeId,
    meltingConditionId,
    stoveTypeId,
    smeltingTypeId,
    castingWeightId,
    mixtureTypeId,
    mixtureSubtypeId,
    workTypeId,
    equipmentId,
    oilMarkId,
    oilProcessId,
    moldEquipmentId,
    cleaningTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: stoveTypeId,
      paramName: 'TipVagranok',
    },
    {
      id: productionTechnologyId,
      paramName: 'TipPechi',
    },
    {
      id: steelTypeId,
      paramName: 'TipStali',
    },
    {
      id: processTypeId,
      paramName: 'VidProcessa',
    },
    {
      id: calculatorTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: meltingConditionId,
      paramName: 'UslovPlavki',
    },
    {
      id: castingWeightId,
      paramName: 'MassaOtlivok',
    },
    {
      id: mixtureTypeId,
      paramName: 'VidProcPrigotovleniya',
    },
    {
      id: mixtureSubtypeId,
      paramName: 'PodvidProcPrigotovleniya',
    },
    {
      id: smeltingTypeId,
      paramName: 'Vyplavka',
    },
    {
      id: ovenTypeId,
      paramName: 'TipPechi26',
    },
    {
      id: workTypeId,
      paramName: 'VidRabot',
    },
    {
      id: equipmentId,
      paramName: 'TipOborud',
    },
    {
      id: oilMarkId,
      paramName: 'MarkaSmoly',
    },
    {
      id: oilProcessId,
      paramName: 'ProcessSmoly',
    },
    {
      id: moldEquipmentId,
      paramName: 'OborudVybivki',
    },
    {
      id: cleaningTypeId,
      paramName: 'SposobOchistki',
    },
    {
      id: 1,
      paramName: 'TipOborud13',
    },
    ...variables,
  ]);
  return data;
};
