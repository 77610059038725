import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IFEAnalysis,
  IFECombustionMixtureType,
  IFEDischargeType,
  IFEFlareInstallation,
  IFEFlareParametr,
  IFEGetFullReport,
  IFEMeasurementResult,
  IFEShape,
} from './interfaces';

const START_URL = 'FakelnyeUstanovkiLibrary';

export const getFEFlareInstallations = async (): Promise<IFEFlareInstallation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidFakelnayaUstanovka`);
  return data;
};

export const getFEMeasurementResults = async (): Promise<IFEMeasurementResult[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidResultatyIzmereniy`);
  return data;
};

export const getFEAnalyzes = async (): Promise<IFEAnalysis[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidResultatyAnalizaKondensata`);
  return data;
};

export const getFEDischargeTypes = async (): Promise<IFEDischargeType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSbrosov`);
  return data;
};

export const getFECombustionMixtureTypes = async (): Promise<IFECombustionMixtureType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSmesi`);
  return data;
};

export const getFEShapes = async (): Promise<IFEShape[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidFormaYamy`);
  return data;
};

export const getFEFlareParameters = async (): Promise<IFEFlareParametr[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidParametryFakelnoyUstanovki`);
  return data;
};

export const getFEVariables = async (
  flareInstallationId: string | number,
  measurmentResultId: string | number,
  combustionMixtureTypeId: string | number,
): Promise<IFEFlareParametr[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      fakelnayaUstanovkaId: flareInstallationId,
      resultatyIzmereniyId: measurmentResultId,
      smesId: combustionMixtureTypeId,
    },
  });
  return data;
};

export const getFEFullReport = async (reportData: IFEGetFullReport): Promise<IToolsReport[]> => {
  const {
    flareInstallationId,
    flareParametrId,
    measurmentResultId,
    shapeId,
    analysisId,
    dischargeTypeId,
    combustionMixtureTypeId,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: flareInstallationId,
      paramName: 'FakelnayaUstanovkaId',
    },
    {
      id: flareParametrId,
      paramName: 'ParametryFakelnoyUstanovkiId',
    },
    {
      id: measurmentResultId,
      paramName: 'ResultatyIzmereniyId',
    },
    {
      id: shapeId,
      paramName: 'FormaYamyId',
    },
    {
      id: analysisId,
      paramName: 'ResultatyAnalizaKondensataId',
    },
    {
      id: dischargeTypeId,
      paramName: 'SbrosyId',
    },
    {
      id: combustionMixtureTypeId,
      paramName: 'SmesId',
    },
    ...variables,
  ]);
  return data;
};
