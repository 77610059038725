import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import { LoadingUnloadingOperationEmissions, FugitiveSourceEmissions, WasteDumbsEmissions } from './Submethodologies';

const { Option } = Select;

export const Order8221: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selecTEdSubmethodology, setSelecTEdSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`O8221-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelecTEdSubmethodology(value)}
              >
                <Option data-cy={`O8221-submethodology-option-0`} value={0}>
                  Выбросы от неорганизованных источников (прил. 8 приказ №221-Ө)
                </Option>
                <Option data-cy={`O8221-submethodology-option-1`} value={1}>
                  Расчет выбросов при погрузочно-разгрузочных работах
                </Option>
                <Option data-cy={`O8221-submethodology-option-2`} value={2}>
                  Расчет выбросов от породных отвалов (п. 7 прил. 8 приказ №221-Ө)
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selecTEdSubmethodology == 0 ? (
        <FugitiveSourceEmissions marginBetween={marginBetween}>{children}</FugitiveSourceEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 1 ? (
        <LoadingUnloadingOperationEmissions marginBetween={marginBetween}>
          {children}
        </LoadingUnloadingOperationEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 2 ? (
        <WasteDumbsEmissions marginBetween={marginBetween}>{children}</WasteDumbsEmissions>
      ) : (
        ''
      )}
    </>
  );
};
