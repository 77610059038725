import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IERSCalculationType, IERSEngine, IERSVariable } from './interfaces';

const START_URL = 'RailwaysObkatkaLibrary';

export const getERSCalculationTypes = async (): Promise<IERSCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getERSEngines = async (): Promise<IERSEngine[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTeplovaoz`);
  return data;
};

export const getERSVariables = async (calculationTypeId: string | number): Promise<IERSVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { vidRaschetaId: calculationTypeId } });
  return data;
};

export const getERSFullReport = async (reportData: {
  calculationTypeId: string | number;
  engineId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { calculationTypeId, engineId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: engineId,
      paramName: 'TeplovaozId',
    },
    ...variables,
  ]);
  return data;
};
