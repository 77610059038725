import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICAWDAnnualOutput,
  ICAWDAshCarryover,
  ICAWDAshCharacteristic,
  ICAWDCalculationType,
  ICAWDCollectorPresence,
  ICAWDField,
  ICAWDFuelMark,
  ICAWDFuelType,
  ICAWDFuranceBoilerType,
  ICAWDReportData,
} from './interfaces';

const START_URL = 'ZoloshlakiClass';

export const getCAWDCalculationTypes = async (): Promise<ICAWDCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getCAWDFields = async (): Promise<ICAWDField[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMestoroj`);
  return data;
};

export const getCAWDFuelMarks = async (fieldId: string | number): Promise<ICAWDFuelMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarka`, { params: { mestoroj: fieldId } });
  return data;
};

export const getCAWDAnnualOutputs = async (): Promise<ICAWDAnnualOutput[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPloshad`);
  return data;
};

export const getCAWDFuelTypes = async (furanceBoilerTypeId: string | number): Promise<ICAWDFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetToplivo`, { params: { vidTopok: furanceBoilerTypeId } });
  return data;
};

export const getCAWDCollectorPresences = async (): Promise<ICAWDCollectorPresence[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalichieZoloul`);
  return data;
};

export const getCAWDAshCharacteristics = async (): Promise<ICAWDAshCharacteristic[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalichieZoly`);
  return data;
};

export const getCAWDFuranceBoilerTypes = async (): Promise<ICAWDFuranceBoilerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopok`);
  return data;
};

export const getCAWDAshCarryovers = async (): Promise<ICAWDAshCarryover[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDolyaUnosa`);
  return data;
};

export const getCAWDVariables = async (
  calculationTypeId: string | number,
  ashPresenceId: string | number,
  ashCarryover: string | number,
): Promise<ICAWDAshCarryover[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRascheta: calculationTypeId, nalichieZoly: ashPresenceId, dolyaUnosa: ashCarryover },
  });
  return data;
};

export const getCAWDFullReport = async (reportData: ICAWDReportData): Promise<IToolsReport[]> => {
  const {
    ashCharacteristicId,
    calculationTypeId,
    fieldsId,
    fuelMarkId,
    fuelTypeId,
    annualOutputId,
    collectorPresenceId,
    ashCarryoverId,
    furanceBoilerTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: fieldsId,
      paramName: 'Mestoroj',
    },
    {
      id: ashCharacteristicId,
      paramName: 'NalichieZoly',
    },
    {
      id: fuelMarkId,
      paramName: 'DBMarka',
    },
    {
      id: ashCarryoverId,
      paramName: 'DolyaUnosa',
    },
    {
      id: collectorPresenceId,
      paramName: 'NalichieZoloul',
    },
    {
      id: furanceBoilerTypeId,
      paramName: 'VidTopok',
    },
    {
      id: annualOutputId,
      paramName: 'Ploshad',
    },
    {
      id: fuelTypeId,
      paramName: 'Toplivo',
    },
    ...variables,
  ]);
  return data;
};
