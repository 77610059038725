import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  ICTCountOperationMode,
  ICTFilterType,
  ICTLifetime,
  ICTMark,
  ICTOperationMode,
  ICTPollutant,
  ICTVariable,
} from './interfaces';

const START_URL = 'AutotransportKarierClass';

export const getCTOperationModes = async (): Promise<ICTOperationMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRezhimRaboty`);
  return data;
};

export const getCTMarks = async (): Promise<ICTMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarkaAvto`);
  return data;
};

export const getCTCountOperationModes = async (): Promise<ICTCountOperationMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetChisloRezhimRaboty`);
  return data;
};

export const getCTLifetimes = async (): Promise<ICTLifetime[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSrokEkspluatacii`);
  return data;
};

export const getCTPollutants = async (): Promise<ICTPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZv`);
  return data;
};

export const getCTFilterTypes = async (): Promise<ICTFilterType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidFiltra`);
  return data;
};

export const getCTVariables = async (): Promise<ICTVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getCTFullReport = async (reportData: {
  operationMode1: string | number;
  operationMode2: string | number;
  operationMode3: string | number;
  countOperationMode: string | number;
  markId: string | number;
  lifetimeId: string | number;
  pollutantId: string | number;
  filterTypeId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    countOperationMode,
    operationMode1,
    operationMode2,
    operationMode3,
    markId,
    lifetimeId,
    filterTypeId,
    pollutantId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: countOperationMode,
      paramName: 'ChisloRezhimRaboty',
    },
    {
      id: pollutantId,
      paramName: 'VidZv',
    },
    {
      id: lifetimeId,
      paramName: 'DBSrokEkspluatacii',
    },
    {
      id: operationMode1,
      paramName: 'RezhimRaboty',
    },
    {
      id: operationMode2,
      paramName: 'RezhimRaboty',
    },
    {
      id: operationMode3,
      paramName: 'RezhimRaboty',
    },
    {
      id: filterTypeId,
      paramName: 'DBVidFiltra',
    },
    {
      id: markId,
      paramName: 'MarkaAvto',
    },
    ...variables,
  ]);
  return data;
};
