import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import { BoilerHouseSmallClass, TubeFurnacesEmissions } from './Submethodologies';

const { Option } = Select;

export const KazahstanEcoExport: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selectedSubmethodology, setSelectedSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`KEE-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelectedSubmethodology(value)}
              >
                <Option data-cy={`KEE-submethodology-option-0`} value={0}>
                  Выбросы от котлов пп до 30 т/час
                </Option>
                <Option data-cy={`KEE-submethodology-option-1`} value={1}>
                  Расчет выбросов от трубчатых печей
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedSubmethodology == 0 ? (
        <BoilerHouseSmallClass marginBetween={marginBetween}>{children}</BoilerHouseSmallClass>
      ) : (
        <TubeFurnacesEmissions marginBetween={marginBetween}>{children}</TubeFurnacesEmissions>
      )}
    </>
  );
};
