import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import {
  CHPEmissions,
  HeatExchangersEmissions,
  LoadingRacksEmissions,
  RefineryTanksEmissions,
  GasStationTanksEmissions,
} from './Submethodologies';

const { Option } = Select;

export const TankEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selectedSubmethodology, setSelectedSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`TE-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelectedSubmethodology(value)}
              >
                <Option data-cy={`TE-submethodology-option-0`} value={0}>
                  Выбросы от резервуаров ТЭЦ
                </Option>
                <Option data-cy={`TE-submethodology-option-1`} value={1}>
                  Выбросы от резервуаров НПЗ
                </Option>
                <Option data-cy={`TE-submethodology-option-2`} value={2}>
                  Выбросы от наливных эстакад
                </Option>
                <Option data-cy={`TE-submethodology-option-3`} value={3}>
                  Выбросы от теплообменных аппаратов и средств перекачки
                </Option>
                <Option data-cy={`TE-submethodology-option-4`} value={4}>
                  Выбросы из резервуаров АЗС
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedSubmethodology == 0 ? <CHPEmissions marginBetween={marginBetween}>{children}</CHPEmissions> : ''}
      {selectedSubmethodology == 1 ? (
        <RefineryTanksEmissions marginBetween={marginBetween}>{children}</RefineryTanksEmissions>
      ) : (
        ''
      )}
      {selectedSubmethodology == 2 ? (
        <LoadingRacksEmissions marginBetween={marginBetween}>{children}</LoadingRacksEmissions>
      ) : (
        ''
      )}
      {selectedSubmethodology == 3 ? (
        <HeatExchangersEmissions marginBetween={marginBetween}>{children}</HeatExchangersEmissions>
      ) : (
        ''
      )}
      {selectedSubmethodology == 4 ? (
        <GasStationTanksEmissions marginBetween={marginBetween}>{children}</GasStationTanksEmissions>
      ) : (
        ''
      )}
    </>
  );
};
