import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IGSTECalculationType,
  IGSTEClimaticZone,
  IGSTEFuelType,
  IGSTEReportData,
  IGSTETankDesign,
  IGSTEVariable,
} from './interfaces';

const START_URL = 'ServerResponse';

export const getGSTECalculationTypes = async (): Promise<IGSTECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRacheta`);
  return data;
};

export const getGSTEFuelTypes = async (): Promise<IGSTEFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getGSTEClimaticZones = async (): Promise<IGSTEClimaticZone[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlimaticheskoyZony`);
  return data;
};

export const getGSTETankDesigns = async (calculationTypeId: string | number): Promise<IGSTETankDesign[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKonstrukciiReservuara`, {
    params: { vidRascheta: calculationTypeId },
  });
  return data;
};

export const getGSTEVariables = async (calculationTypeId: string | number): Promise<IGSTEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRascheta: calculationTypeId },
  });
  return data;
};

export const getGSTEFullReport = async (reportData: IGSTEReportData): Promise<IToolsReport[]> => {
  const { calculatonTypeId, fuelTypeId, climaticZoneId, tankDesingId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculatonTypeId,
      paramName: 'Tiprascheta',
    },
    {
      id: fuelTypeId,
      paramName: 'Tiptopliva',
    },
    {
      id: climaticZoneId,
      paramName: 'TipKlimaticheskoyZony',
    },
    {
      id: tankDesingId,
      paramName: 'TipKonstrukciiReservuara',
    },
    ...variables,
  ]);
  return data;
};
