import React from 'react';
import { Form, Row, Col } from 'antd';
import { IMethodologyModule, IWIFormValues } from './interfaces';
import { useQuery } from 'react-query';
import {
  getWIAmmoniaContents,
  getWICalculationTypes,
  getWICountries,
  getWIDustCollectionEquipments,
  getWIFullReport,
  getWILatexPollutantTypes,
  getWILatexTypes,
  getWIMachineMarks,
  getWIMachineSubTypes,
  getWIMachineTypes,
  getWIOnlyResinTypes,
  getWIPassportData,
  getWIPlaywoodProductionAreas,
  getWIResinMarks,
  getWIResinTypes,
  getWISuctionSystems,
  getWITechnologicalProcesses,
  getWIVariables,
  getWIWoodMaterials,
} from '../../api/WoodworkingIndustry';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const WoodworkingIndustry: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('WICalculationTypes', getWICalculationTypes);
  const passportData = useQuery('WIPassportData', getWIPassportData);
  const suctionSystems = useQuery('WISuctionSystems', getWISuctionSystems);
  const machineTypes = useQuery('WIMachineTypes', getWIMachineTypes);
  const machineSubTypes = useQuery('WIMachineSubTypes', () =>
    getWIMachineSubTypes(form.getFieldValue('machine_type') || 1),
  );
  const machineMarks = useQuery('WIMachineMarks', () =>
    getWIMachineMarks(form.getFieldValue('machine_type') || 1, form.getFieldValue('machine_sub_type') || 1),
  );
  const technologicalProcesses = useQuery('WITechnologicalProcesses', getWITechnologicalProcesses);
  const woodMaterials = useQuery('WIWoodMaterials', getWIWoodMaterials);
  const resinTypes = useQuery('WIResinTypes', getWIResinTypes);
  const onlyResinTypes = useQuery('WIOnlyResinTypes', () => getWIOnlyResinTypes(form.getFieldValue('only_resin_type')));
  const resinMarks = useQuery('WIResinMarks', () =>
    getWIResinMarks(form.getFieldValue('only_resin_type') || 1, form.getFieldValue('country') || 0),
  );
  const ammoniaContents = useQuery('WIAmmoniaContents', getWIAmmoniaContents);
  const playwoodProductionAreas = useQuery('WIPlaywoodProductionAreas', getWIPlaywoodProductionAreas);
  const dustCollectionEquipments = useQuery('WIDustCollectionEquipments', getWIDustCollectionEquipments);
  const countries = useQuery('WICoutries', getWICountries);
  const latexTypes = useQuery('WILatexTypes', getWILatexTypes);
  const latexPollutantTypes = useQuery('WIPollutantTypes', getWILatexPollutantTypes);
  const variables = useQuery('WIVariables', () =>
    getWIVariables(
      form.getFieldValue('calculation_type') || 1,
      form.getFieldValue('suction_system') || 1,
      form.getFieldValue('passport_data') || 1,
      form.getFieldValue('resin_type') || 1,
      form.getFieldValue('only_resin_type') || 1,
      form.getFieldValue('country') || 1,
      form.getFieldValue('resin_mark') || 1,
      form.getFieldValue('playwood_production_area') || 1,
    ),
  );

  const onFinish = (values: IWIFormValues) => {
    const {
      calculation_type,
      suction_system,
      machine_type,
      machine_sub_type,
      machine_mark,
      passport_data,
      dust_collection_equipment,
      wood_material,
      only_resin_type,
      country,
      resin_type,
      latex_type,
      latex_pollutant_type,
      resin_mark,
      ammonia_content,
      technological_process,
      playwood_production_area,
    } = values;

    if (variables.data) {
      getFormResult(getWIFullReport, {
        latexTypeId: latex_type || 1,
        latexPollutantTypeId: latex_pollutant_type || 1,
        calculationTypeId: calculation_type || 1,
        suctionSystemId: suction_system || 1,
        passportDataId: passport_data || 1,
        resinTypeId: resin_type || 1,
        onlyResinTypeId: only_resin_type || 1,
        countryId: country || 1,
        resinMark: resin_mark || 1,
        playwoodProductionAreaId: playwood_production_area || 1,
        machineTypeId: machine_type || 1,
        machineSubTypeId: machine_sub_type || 1,
        machineMarkId: machine_mark || 1,
        technologicalProcessId: technological_process || 1,
        woodMaterialId: wood_material || 1,
        ammoniaContentId: ammonia_content || 1,
        dustCollectionEquipmentId: dust_collection_equipment || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeResinType = () => {
    resinMarks.refetch();
    onlyResinTypes.refetch();
  };

  const onChangeMachineType = () => {
    machineSubTypes.refetch();
    machineMarks.refetch();
  };

  const onChangeMachineSubType = () => {
    machineMarks.refetch();
  };

  const handleChangeVariable = () => {
    variables.refetch();
  };

  const onChangeCountry = () => {
    resinMarks.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="WI"
            label="Вид расчета"
            onChange={handleChangeVariable}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') <= 2 || form.getFieldValue('calculation_type') == 4 ? (
            <FormBaseSelect
              data={suctionSystems.data ? suctionSystems.data : []}
              name="suction_system"
              cyPrefix="WI"
              label="Наличие системы местных отходов"
              defaultValue={1}
              onChange={handleChangeVariable}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 || form.getFieldValue('calculation_type') == 4 ? (
            <>
              <FormBaseSelect
                data={machineTypes.data ? machineTypes.data : []}
                name="machine_type"
                cyPrefix="WI"
                label="Вид станка"
                onChange={onChangeMachineType}
                defaultValue={1}
              />
              <FormBaseSelect
                data={machineSubTypes.data ? machineSubTypes.data : []}
                name="machine_sub_type"
                cyPrefix="WI"
                label="Подвид станка"
                onChange={onChangeMachineSubType}
                defaultValue={1}
              />
              <FormBaseSelect
                data={machineMarks.data ? machineMarks.data : []}
                name="machine_mark"
                cyPrefix="WI"
                label="Марка станка"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('suction_system') == 2 &&
          (form.getFieldValue('calculation_type') <= 2 || form.getFieldValue('calculation_type') == 4) ? (
            <FormBaseSelect
              data={passportData.data ? passportData.data : []}
              name="passport_data"
              cyPrefix="WI"
              label="Наличие паспортных данных"
              defaultValue={1}
              onChange={handleChangeVariable}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('passport_data') == 2 &&
          (form.getFieldValue('calculation_type') <= 2 || form.getFieldValue('calculation_type') == 4) ? (
            <FormBaseSelect
              data={dustCollectionEquipments.data ? dustCollectionEquipments.data : []}
              name="dust_collection_equipment"
              cyPrefix="WI"
              label="Тип пылеулавливающего оборудование"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 2 ? (
            <FormBaseSelect
              data={woodMaterials.data ? woodMaterials.data : []}
              name="wood_material"
              cyPrefix="WI"
              label="Вид древесных материалов и влажность"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {(form.getFieldValue('calculation_type') == 3 ||
            form.getFieldValue('calculation_type') == 5 ||
            form.getFieldValue('calculation_type') == 6) &&
          form.getFieldValue('resin_type') == 1 ? (
            <FormBaseSelect
              data={onlyResinTypes.data ? onlyResinTypes.data : []}
              name="only_resin_type"
              cyPrefix="WI"
              label="Вид смолы"
              defaultValue={1}
              onChange={onChangeResinType}
            />
          ) : (
            ''
          )}
          {(form.getFieldValue('calculation_type') == 3 ||
            form.getFieldValue('calculation_type') == 5 ||
            form.getFieldValue('calculation_type') == 6) &&
          form.getFieldValue('resin_type') == 2 ? (
            <FormBaseSelect
              data={countries.data ? countries.data : []}
              name="country"
              cyPrefix="WI"
              label="Страна"
              onChange={onChangeCountry}
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 3 ||
          form.getFieldValue('calculation_type') == 5 ||
          form.getFieldValue('calculation_type') == 6 ? (
            <FormBaseSelect
              data={resinTypes.data ? resinTypes.data : []}
              name="resin_type"
              cyPrefix="WI"
              label="Тип смолы или латекса"
              defaultValue={1}
              onChange={handleChangeVariable}
            />
          ) : (
            ''
          )}
          {(form.getFieldValue('calculation_type') == 3 ||
            form.getFieldValue('calculation_type') == 5 ||
            form.getFieldValue('calculation_type') == 6) &&
          form.getFieldValue('resin_type') == 3 ? (
            <>
              <FormBaseSelect
                data={latexTypes.data ? latexTypes.data : []}
                name="latex_type"
                cyPrefix="WI"
                label="Виды дисперсии (латексов)"
                defaultValue={1}
              />
              <FormBaseSelect
                data={latexPollutantTypes.data ? latexPollutantTypes.data : []}
                name="latex_pollutant_type"
                cyPrefix="WI"
                label="Мономеры и вредные выделения"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 3 ||
          form.getFieldValue('calculation_type') == 5 ||
          form.getFieldValue('calculation_type') == 6 ? (
            <FormBaseSelect
              data={resinMarks.data ? resinMarks.data : []}
              name="resin_mark"
              cyPrefix="WI"
              label="Марка смолы"
              onChange={handleChangeVariable}
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 3 ? (
            <FormBaseSelect
              data={ammoniaContents.data ? ammoniaContents.data : []}
              name="ammonia_content"
              cyPrefix="WI"
              label="Содержание аммиака в смоле, %"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 2 ? (
            <FormBaseSelect
              data={technologicalProcesses.data ? technologicalProcesses.data : []}
              name="technological_process"
              cyPrefix="WI"
              label="Вид технологического процесса"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 4 ? (
            <FormBaseSelect
              data={playwoodProductionAreas.data ? playwoodProductionAreas.data : []}
              name="playwood_production_area"
              cyPrefix="WI"
              label="Участок производства фанеры"
              onChange={handleChangeVariable}
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="WI"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
