import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { IBPEFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import {
  getBPEAirSupplies,
  getBPEAshCatcherTypes,
  getBPEBoilerTypes,
  getBPECalculationTypes,
  getBPECleaningSurfaces,
  getBPEFuelTypes,
  getBPEFullReport,
  getBPEHeatLosses,
  getBPENetherHeats,
  getBPESprayingOils,
  getBPEVariables,
} from '../../api/BenzopyreneEmissions';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const BenzopyreneEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('BPECalculationTypes', getBPECalculationTypes);
  const fuelTypes = useQuery('BPEFuelTypes', getBPEFuelTypes);
  const ashCatcherTypes = useQuery('BPEAshCatcherTypes', getBPEAshCatcherTypes);
  const boilerTypes = useQuery('BPEBoilerTypes', getBPEBoilerTypes);
  const airSupplies = useQuery('BPEAirSupllies', getBPEAirSupplies);
  const heatLosses = useQuery('BPEHeatLosses', getBPEHeatLosses);
  const sprayingOils = useQuery('BPESprayingOils', getBPESprayingOils);
  const cleaningSurfaces = useQuery('BPECleaningSurfaces', getBPECleaningSurfaces);
  const netherHeats = useQuery('BPENetherHeats', () => getBPENetherHeats(form.getFieldValue('fuel_type')));
  const variables = useQuery('BPEVariables', () =>
    getBPEVariables(form.getFieldValue('calculation_type'), form.getFieldValue('fuel_type')),
  );

  const onFinish = (values: IBPEFormValues) => {
    const {
      calculation_type,
      fuel_type,
      ash_catcher_type,
      boiler_type,
      air_supply,
      heat_loss,
      nether_heat,
      cleaning_surface,
      spraying_oil,
    } = values;

    if (variables.data) {
      getFormResult(getBPEFullReport, {
        calculationTypeId: calculation_type,
        fuelTypeId: fuel_type,
        ashCatcherId: ash_catcher_type || 1,
        boilerTypeId: boiler_type || 1,
        airSupplyId: air_supply || 1,
        heatLossId: heat_loss,
        sprayingOil: spraying_oil || 1,
        cleaningSurfaceId: cleaning_surface || 1,
        netherHeatId: nether_heat,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeFuelType = () => {
    netherHeats.refetch();
    variables.refetch();
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="BPE"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalculationType}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="BPE"
            label="Вид топлива"
            defaultValue={1}
            onChange={onChangeFuelType}
          />
          <Form.Item
            name="heat_loss"
            label="Потеря тепла от механической неполноты сгорания топлива, %, q4"
            initialValue={1}
          >
            <Select data-cy="BPE-heat_loss-select">
              {heatLosses.data
                ? heatLosses.data.map((heatLoss) => (
                    <Option data-cy={`BPE-heat_loss-option-${heatLoss.id}`} key={heatLoss.id} value={heatLoss.id}>
                      {`Тип топки: ${heatLoss.tip}, Топливо: ${heatLoss.toplivo} a=${heatLoss.a}, q3=${heatLoss.q3}, q4=${heatLoss.q4}, q4un=${heatLoss.q4un}`}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <Form.Item
            name="nether_heat"
            label="Низшая теплота сгорания рабочего топлива Qr, калл/кг (калл/нм3)"
            initialValue={1}
          >
            <Select data-cy="BPE-nether_heat-select">
              {netherHeats.data
                ? netherHeats.data.map((netherHeat) => (
                    <Option
                      data-cy={`BPE-nether_heat-option-${netherHeat.id}`}
                      key={netherHeat.id}
                      value={netherHeat.id}
                    >
                      {netherHeat.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          {form.getFieldValue('calculation_type') == 2 ? (
            <>
              <FormBaseSelect
                data={boilerTypes.data ? boilerTypes.data : []}
                name="boiler_type"
                cyPrefix="BPE"
                label="Вид котлоагрегата"
                defaultValue={1}
              />
              {form.getFieldValue('fuel_type') == 1 || form.getFieldValue('fuel_type') == 4 ? (
                <FormBaseSelect
                  data={airSupplies.data ? airSupplies.data : []}
                  name="air_supply"
                  cyPrefix="BPE"
                  label="Место подачи рециркулируемого воздуха"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
              {form.getFieldValue('fuel_type') != 1 && form.getFieldValue('fuel_type') != 4 ? (
                <Form.Item name="ash_catcher_type" label="Тип и марка золоуловителя" initialValue={1}>
                  <Select data-cy="BPE-ash_catcher_type-select">
                    {ashCatcherTypes.data
                      ? ashCatcherTypes.data.map((ash_catcher_type) => (
                          <Option
                            data-cy={`BPE-ash_catcher_type-option-${ash_catcher_type.id}`}
                            key={ash_catcher_type.id}
                            value={ash_catcher_type.id}
                          >
                            {ash_catcher_type.vid}
                          </Option>
                        ))
                      : ''}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              {form.getFieldValue('fuel_type') == 4 ? (
                <>
                  <FormBaseSelect
                    data={sprayingOils.data ? sprayingOils.data : []}
                    name="spraying_oil"
                    cyPrefix="BPE"
                    label="Коэффициент, учитывающий способ распылевания мазута R"
                    defaultValue={1}
                  />
                  <FormBaseSelect
                    data={cleaningSurfaces.data ? cleaningSurfaces.data : []}
                    name="cleaning_surface"
                    cyPrefix="BPE"
                    label="Коэффициент, учитывающий дробевую очистку конвективных поверхностей K0"
                    defaultValue={1}
                  />
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BPE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
