import React from 'react';
import { Form } from 'antd';

import { IMethodologyModule } from './interfaces';

import { getAGFSFullReport, getAGFSVariables } from '../../api/AutoGasFillingStation';

import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const AutoGasFillingStation: React.FC<IMethodologyModule> = ({ children }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const variables = useQuery('AGFSVariables', getAGFSVariables);

  const onFinish = () => {
    if (variables.data) {
      getFormResult(getAGFSFullReport, {
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <FormVariableInputList
        data={variables.data ? variables.data : []}
        cyPrefix="AGFS"
        placeholder="0"
        minValue={0}
        defaultValue={0}
      />
      {children}
    </Form>
  );
};
