import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IEEFOPArea, IEEFOPClimaticZoneType, IEEFOPVariable } from './interfaces';

const START_URL = 'IspareniyaMazuta';

export const getEEFOPClimaticZoneTypes = async (): Promise<IEEFOPClimaticZoneType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlimaticheskoyZony`);
  return data;
};

export const getEEFOPAreas = async (climaticZoneTypeId: string | number): Promise<IEEFOPArea[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOblast`, { params: { vidKlimatichZony: climaticZoneTypeId } });
  return data;
};

export const getEEFOPVariables = async (): Promise<IEEFOPVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getEEFOPFullReport = async (reportData: {
  climaticZoneTypeId: string | number;
  area: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { climaticZoneTypeId, area, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: climaticZoneTypeId,
      paramName: 'VidKlimatichZony',
    },
    {
      id: area,
      paramName: 'Oblast',
    },
    ...variables,
  ]);
  return data;
};
