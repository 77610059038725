import React from 'react';
import { Form, InputNumber } from 'antd';
import { IFormVariableInputList } from './interfaces';

export const FormVariableInputList: React.FC<IFormVariableInputList> = ({
  data,
  cyPrefix,
  minValue,
  placeholder,
  defaultValue,
}: IFormVariableInputList) => {
  return (
    <>
      {data.map((variable) => (
        <Form.Item key={variable.id} label={variable.name} name={variable.name}>
          <InputNumber
            data-cy={`${cyPrefix}-input-${variable.id}`}
            min={minValue}
            placeholder={placeholder ? placeholder : '0'}
            defaultValue={defaultValue}
          />
        </Form.Item>
      ))}
    </>
  );
};
