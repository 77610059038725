import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IBSWAshCatcherType,
  IBSWBrownCoalType,
  IBSWCoalType,
  IBSWEquipmentType,
  IBSWFuelType,
  IBSWOilType,
  IBSWReportData,
  IBSWTechnologicalOperation,
  IBSWVariable,
} from './interfaces';

const START_URL = 'KuznechnyeRabotyLibrary';

export const getBSWEquipmentTypes = async (): Promise<IBSWEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechOborudovanie`);
  return data;
};

export const getBSWFuelTypes = async (): Promise<IBSWFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getBSWBrownCoalTypes = async (): Promise<IBSWBrownCoalType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidMestorozhdenieBurogoUglya`);
  return data;
};

export const getBSWCoalTypes = async (): Promise<IBSWCoalType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidMestorozhdenieKamennogoUglya`);
  return data;
};

export const getBSWAshCatcherTypes = async (): Promise<IBSWAshCatcherType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZoloulovitelya`);
  return data;
};

export const getBSWTechnologicalOperations = async (): Promise<IBSWTechnologicalOperation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZoloulovitelya`);
  return data;
};

export const getBSWOilTypes = async (): Promise<IBSWOilType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidMazuta`);
  return data;
};

export const getBSWVariables = async (
  equipmentTypeId: string | number,
  fuelTypeId: string | number,
): Promise<IBSWVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { techOborudovanieId: equipmentTypeId, toplivoId: fuelTypeId },
  });
  return data;
};

export const getBSWFullReport = async (reportData: IBSWReportData): Promise<IToolsReport[]> => {
  const {
    equipmentTypeId,
    fuelTypeId,
    coalTypeId,
    brownCoalTypeId,
    oilTypeId,
    technicalOperationId,
    ashCatcherTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: equipmentTypeId,
      paramName: 'TechOborudovanieId',
    },
    {
      id: fuelTypeId,
      paramName: 'ToplivoId',
    },
    {
      id: coalTypeId,
      paramName: 'MestorozhdenieKId',
    },
    {
      id: brownCoalTypeId,
      paramName: 'MestorozhdenieBId',
    },
    {
      id: oilTypeId,
      paramName: 'MazutId',
    },
    {
      id: ashCatcherTypeId,
      paramName: 'ZoloulovitelId',
    },
    {
      id: technicalOperationId,
      paramName: 'TechOperaciyaId',
    },
    ...variables,
  ]);
  return data;
};
