import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { ICLEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getCLECalculationTypes,
  getCLEEquipmentTypes,
  getCLEFullReport,
  getCLELabNames,
  getCLEOutliarRemovals,
  getCLESubstances,
  getCLEVariables,
} from '../../../../api/ChemicalLaboratoriesEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const ChemicalLaboratoriesEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('CLECalculationTypes', getCLECalculationTypes);
  const equipmentTypes = useQuery('CLEEquipmentTypes', () => getCLEEquipmentTypes(form.getFieldValue('lab_name') || 1));
  const labNames = useQuery('CLELabNames', () => getCLELabNames(form.getFieldValue('calculation_type')));
  const outliarRemovals = useQuery('CLEOutliarRemovals', getCLEOutliarRemovals);
  const substances = useQuery('CLESubstances', () =>
    getCLESubstances(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('lab_name') || 1,
      form.getFieldValue('equipment_type'),
    ),
  );
  const variables = useQuery('CLEVariables', () => getCLEVariables(form.getFieldValue('outliar_removal')));

  const onFinish = (values: ICLEFormValues) => {
    const { calculation_type, lab_name, equipment_type, outliar_removal, substance } = values;

    if (variables.data) {
      getFormResult(getCLEFullReport, {
        calculationTypeId: calculation_type,
        equipmentTypeId: equipment_type,
        labNameId: lab_name || 1,
        outlairRemovalId: outliar_removal,
        substances: substance || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    labNames.refetch();
    substances.refetch();
  };

  const onChangeLabName = () => {
    equipmentTypes.refetch();
    substances.refetch();
  };

  const onChangeEquipmentType = () => {
    substances.refetch();
  };

  const onChangeOutliarRemoval = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            onChange={onChangeCalculationType}
            cyPrefix="CLE"
            label="Вид расчета для химической лаборатории"
            defaultValue={1}
          />
          {(form.getFieldValue('calculation_type') == 2 || form.getFieldValue('calculation_type') == 3) &&
          labNames.data ? (
            <FormBaseSelect
              data={labNames.data ? labNames.data : []}
              name="lab_name"
              cyPrefix="CLE"
              label="Наименование лаборатории"
              onChange={onChangeLabName}
              defaultValue={labNames.data[0].id}
            />
          ) : (
            ''
          )}
          {equipmentTypes.data ? (
            <FormBaseSelect
              data={equipmentTypes.data ? equipmentTypes.data : []}
              name="equipment_type"
              cyPrefix="CLE"
              label="Тип, модель оборудования"
              onChange={onChangeEquipmentType}
              defaultValue={equipmentTypes.data[0].id}
            />
          ) : (
            ''
          )}

          <Form.Item name="substance" label="Используемые вещества">
            <Select data-cy="CLE-substance-select" mode="multiple">
              {substances.data
                ? substances.data.map((substance, index) => (
                    <Option data-cy={`CLE-substance-option-${index}`} key={index} value={substance.names}>
                      {substance.names}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <FormBaseSelect
            data={outliarRemovals.data ? outliarRemovals.data : []}
            name="outliar_removal"
            cyPrefix="CLE"
            label="Как удаляются выбросы в атмосферу"
            onChange={onChangeOutliarRemoval}
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CLE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
