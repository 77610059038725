import { createApi, createStore } from 'effector/compat';

import { IToolsReport } from '../../../api/Tools/interface';

interface IPollutantsTableStore {
  tableData: IToolsReport[];
}

export const $pollutantsTableStore = createStore<IPollutantsTableStore>({ tableData: [] });

export const { setPollutantsTableData } = createApi($pollutantsTableStore, {
  setPollutantsTableData: (state, newData: IToolsReport[]) => ({ ...state, tableData: newData }),
});
