import axios from 'axios';
import { QueryClient } from 'react-query';

const BASE_URL = 'https://method.koktem2.kz';

export const apiClient = axios.create({
  baseURL: BASE_URL,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
