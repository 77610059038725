import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { ITRFEOperationType, ITRFESubstance, ITRFEVariables } from './interfaces';

const START_URL = 'RemontToplivnoyApparaturyLibrary';

export const getTRFEOperationTypes = async (): Promise<ITRFEOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOperacii`);
  return data;
};

export const getTRFESubstances = async (): Promise<ITRFESubstance[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSostav`);
  return data;
};

export const getTRFEVariables = async (
  operationTypeId: string | number,
  substanceId: string | number,
): Promise<ITRFEVariables[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { operaciyaId: operationTypeId, sostavId: substanceId },
  });
  return data;
};

export const getTRFEFullReport = async (reportData: {
  operationTypeId: string | number;
  substanceId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { operationTypeId, substanceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: operationTypeId,
      paramName: 'OperaciyaId',
    },
    {
      id: substanceId,
      paramName: 'SostavId',
    },
    ...variables,
  ]);
  return data;
};
