import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { ICMEFormValues, IMethodologyModule } from './interfaces';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { useQuery } from 'react-query';
import {
  getCMEAmountExplosives,
  getCMECalculationTypes,
  getCMEDrillingTypes,
  getCMEDustCollectorTypes,
  getCMEEquipmentNames,
  getCMEExplosiveConsumptions,
  getCMEExplosiveTypes,
  getCMEFullReport,
  getCMEHumidities,
  getCMELifetimes,
  getCMELocalConditions,
  getCMEPollutants,
  getCMEVariables,
  getCMEWindSpeeds,
} from '../../api/CoalMIningEnterprises/Index';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const CoalMiningEnterprises: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('ICMECalculationTypes', getCMECalculationTypes);
  const dustCollectorTypes = useQuery('ICMEDustCollectorTypes', getCMEDustCollectorTypes);
  const humidities = useQuery('ICMEHumidities', getCMEHumidities);
  const windSpeeds = useQuery('ICMEWindSpeeds', getCMEWindSpeeds);
  const equipmentNames = useQuery('ICMEEquipmentNames', getCMEEquipmentNames);
  const lifetimes = useQuery('ICMELifetimes', getCMELifetimes);
  const localConditions = useQuery('ICMELocalConditions', getCMELocalConditions);
  const amountExplosives = useQuery('ICMEAmountExplosives', getCMEAmountExplosives);
  const pollutants = useQuery('ICMEPollutants', getCMEPollutants);
  const explosiveTypes = useQuery('ICMEExplosiveTypes', () => getCMEExplosiveTypes(form.getFieldValue('pollutant')));
  const explosiveConsumptions = useQuery('ICMEExplosiveConsumptions', getCMEExplosiveConsumptions);
  const drillingTypes = useQuery('ICMEDrillingTypes', getCMEDrillingTypes);
  const variables = useQuery('ICMEVariables', () =>
    getCMEVariables(form.getFieldValue('calculation_type'), form.getFieldValue('dust_collector_type')),
  );

  const onFinish = (values: ICMEFormValues) => {
    const {
      calculation_type,
      dust_collection_type,
      equipment_name,
      wind_speed,
      humidity,
      lifetime,
      ammount_explosive,
      local_condition,
      pollutant,
      explosive_type,
      explosive_сonsumption,
      drilling_type,
    } = values;

    if (variables.data) {
      getFormResult(getCMEFullReport, {
        calculationTypeId: calculation_type,
        dustCollectorTypeId: dust_collection_type || 1,
        sourceId: 1,
        humidityId: humidity || 1,
        windSpeedId: wind_speed || 1,
        equipmentNameId: equipment_name || 1,
        lifetimeId: lifetime || 1,
        localConditionId: local_condition || 1,
        amountExplosiveId: ammount_explosive || 1,
        pollutantId: pollutant || 1,
        explosiveTypeId: explosive_type || 1,
        explosiveConsumptionId: explosive_сonsumption || 1,
        drillingTypeId: drilling_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangePollutant = () => {
    explosiveTypes.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="CME"
            label="Вид расчета"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') == 1 ? (
            <FormBaseSelect
              data={dustCollectorTypes.data ? dustCollectorTypes.data : []}
              name="dust_collection_type"
              cyPrefix="CME"
              label="Вид пылеуловителя"
              onChange={handleChangeVariables}
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') > 2 && form.getFieldValue('calculation_type') < 7 ? (
            <>
              <FormBaseSelect
                data={equipmentNames.data ? equipmentNames.data : []}
                name="equipment_name"
                cyPrefix="CME"
                label="Наименование оборудования"
                defaultValue={1}
              />
              <FormBaseSelect
                data={windSpeeds.data ? windSpeeds.data : []}
                name="wind_speed"
                cyPrefix="CME"
                label="Скорость ветра"
                defaultValue={1}
              />
              <FormBaseSelect
                data={humidities.data ? humidities.data : []}
                name="humidity"
                cyPrefix="CME"
                label="Влажноть материала"
                defaultValue={1}
              />
              <FormBaseSelect
                data={lifetimes.data ? lifetimes.data : []}
                name="lifetime"
                cyPrefix="CME"
                label="Срок эксплуатации"
                defaultValue={1}
              />
              <FormBaseSelect
                data={localConditions.data ? localConditions.data : []}
                name="local_condition"
                cyPrefix="CME"
                label="Местные условия"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') > 6 ? (
            <>
              <FormBaseSelect
                data={amountExplosives.data ? amountExplosives.data : []}
                name="ammount_explosive"
                cyPrefix="CME"
                label="Кол-во взрывчатых веществ при производстве 1 взрыва"
                defaultValue={1}
              />
              <FormBaseSelect
                data={pollutants.data ? pollutants.data : []}
                name="pollutant"
                cyPrefix="CME"
                label="Вид ЗВ"
                onChange={onChangePollutant}
                defaultValue={1}
              />
              <FormBaseSelect
                data={explosiveTypes.data ? explosiveTypes.data : []}
                name="explosive_type"
                cyPrefix="CME"
                label="Вид взрывчатого вещества"
                defaultValue={1}
              />
              <Form.Item
                name="explosive_сonsumption"
                label="Удельный расход взрывчатого вещества, кг/м3"
                initialValue={1}
              >
                <Select data-cy="CME-explosive_сonsumption-select">
                  {explosiveConsumptions.data
                    ? explosiveConsumptions.data.map((consumption) => (
                        <Option
                          data-cy={`CME-explosive_сonsumption-option-${consumption.id}`}
                          key={consumption.id}
                          value={consumption.id}
                        >
                          {consumption.value}
                        </Option>
                      ))
                    : ''}
                </Select>
              </Form.Item>
              <FormBaseSelect
                data={drillingTypes.data ? drillingTypes.data : []}
                name="drilling_type"
                cyPrefix="CME"
                label="Вид буровых работ"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CME"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
