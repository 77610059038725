import React from 'react';
import { Form, Col, Row } from 'antd';
import { IMethodologyModule, IPEFormValues } from './interfaces';
import { useQuery } from 'react-query';
import {
  getPEBoilerHeatingTypes,
  getPECalculationTypes,
  getPECatalystTypes,
  getPECleaningSystems,
  getPEClimaticZones,
  getPEEmissions,
  getPEFuels,
  getPEFuelTypes,
  getPEFullReport,
  getPEInstallationNames,
  getPEMechanicalCleaningObjects,
  getPEObjectShelterDegrees,
  getPEObjectShelterPercentages,
  getPEOilNames,
  getPEOperatingModes,
  getPEOvenTypes,
  getPETankDesigns,
  getPETechnologicalInstallations,
  getPETimeIntervals,
  getPEVacuumSystems,
  getPEVariables,
  getPEVehicleGroups,
  getPEVehicleYears,
  getPEVentilationSystemTypes,
  getPEWaterSupplySystems,
} from '../../api/PetrochemicalEnterprises';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const PetrochemicalEnterprises: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('PECalculationTypes', getPECalculationTypes);
  const emissions = useQuery('PEEmissions', () => getPEEmissions(form.getFieldValue('calculation_type')));
  const oilNames = useQuery('PEOilNames', () =>
    getPEOilNames(form.getFieldValue('calculation_type'), form.getFieldValue('emission')),
  );
  const operatingModes = useQuery('PEOperatingModes', getPEOperatingModes);
  const tankDesings = useQuery('PETankDesings', () => getPETankDesigns(form.getFieldValue('operating_mode')));
  const climaticZones = useQuery('PEClimaticZones', getPEClimaticZones);
  const cleaningSystems = useQuery('PECleaningSystems', () => getPECleaningSystems(form.getFieldValue('emission')));
  const objectShelterDegerees = useQuery('PEObjectShelterDegrees', getPEObjectShelterDegrees);
  const objectShelterPercentages = useQuery('PEObjectShelterPercentages', getPEObjectShelterPercentages);
  const mechanicalCleaningObjects = useQuery('PEMechanicalCleaningObjects', () =>
    getPEMechanicalCleaningObjects(form.getFieldValue('cleaning_system')),
  );
  const waterSupplySystems = useQuery('PEWaterSupplySystems', getPEWaterSupplySystems);
  const fuelTypes = useQuery('PEFuelTypes', getPEFuelTypes);
  const fuels = useQuery('PEFuels', getPEFuels);
  const installationNames = useQuery('PEInstallationNames', getPEInstallationNames);
  const boilerHeatingTypes = useQuery('PEBoilerHeatingTypes', getPEBoilerHeatingTypes);
  const vacuumSystems = useQuery('PEVacuumSystems', getPEVacuumSystems);
  const catalystTypes = useQuery('PECatalystTypes', getPECatalystTypes);
  const technologicalInstallations = useQuery('PETechnologicalInstallations', () =>
    getPETechnologicalInstallations(form.getFieldValue('calculation_type')),
  );
  const ventilationSystemTypes = useQuery('PEVentilationSystemTypes', getPEVentilationSystemTypes);
  const ovenTypes = useQuery('PEOvenType', getPEOvenTypes);
  const vehicleGroups = useQuery('PEVehicleGroups', getPEVehicleGroups);
  const vehicleYears = useQuery('PEVehicleYears', getPEVehicleYears);
  const timeIntervals = useQuery('PETimeIntervals', getPETimeIntervals);
  const variables = useQuery('PEVariables', () =>
    getPEVariables(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('emission'),
      form.getFieldValue('fuel_type'),
      form.getFieldValue('fuel'),
      form.getFieldValue('time_interval'),
    ),
  );

  const onFinish = (values: IPEFormValues) => {
    const {
      calculation_type,
      time_interval,
      emission,
      oil_name,
      operating_mode,
      tank_desing,
      climatic_zone,
      cleaning_system,
      object_shelter_percentage,
      object_shelter_degree,
      mechanical_clean_object,
      fuel_type,
      water_supply_system,
      fuel,
      installation_name,
      vacuum_system,
      catalyst_type,
      technological_installation,
      ventilation_system_type,
      oven_type,
      vehicle_year,
      vehicle_group,
      boiler_heating_type,
    } = values;

    if (variables.data) {
      getFormResult(getPEFullReport, {
        calculationTypeId: calculation_type,
        emissionsId: emission,
        oilNameId: oil_name || 1,
        tankDesignId: tank_desing || 1,
        climaticZoneId: climatic_zone || 1,
        operatingModes: operating_mode || 1,
        cleaningSystemId: cleaning_system || 1,
        objectShelterDegreeId: object_shelter_degree || 1,
        objectShelterPercentagesId: object_shelter_percentage || 1,
        mechanicalCleaningObjectId: mechanical_clean_object || 1,
        waterSupplySystemId: water_supply_system || 1,
        fuelTypeId: fuel_type || 1,
        fuelId: fuel || 1,
        installationNameId: installation_name || 1,
        boilerHeatingTypeId: boiler_heating_type || 1,
        vacuumSystemId: vacuum_system || 1,
        catalystTypeId: catalyst_type || 1,
        technologicalInstallationId: technological_installation || 1,
        ventilationSystemTypeId: ventilation_system_type || 1,
        ovenTypeId: oven_type || 1,
        vehicleGroupId: vehicle_group || 1,
        vehicleYearId: vehicle_year || 1,
        timeIntervals: time_interval || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    emissions.refetch();
    oilNames.refetch();
    technologicalInstallations.refetch();
    variables.refetch();
    oilNames.refetch();
    cleaningSystems.refetch();
  };

  const onChangeEmissions = () => {
    oilNames.refetch();
    cleaningSystems.refetch();
  };

  const onChangeCleaningSystem = () => {
    mechanicalCleaningObjects.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="PE"
            label="Вид расчетов"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={timeIntervals.data ? timeIntervals.data : []}
            name="time_interval"
            cyPrefix="PE"
            label="Период"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          <FormBaseSelect
            data={emissions.data ? emissions.data : []}
            name="emission"
            cyPrefix="PE"
            label="Вид выброса"
            onChange={onChangeEmissions}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') == 1 ? (
            <>
              <FormBaseSelect
                data={oilNames.data ? oilNames.data : []}
                name="oil_name"
                cyPrefix="PE"
                label="Наименование нефтепродукта"
                defaultValue={1}
              />
              <FormBaseSelect
                data={operatingModes.data ? operatingModes.data : []}
                name="operating_mode"
                cyPrefix="PE"
                label="Режим эксплуатации"
                defaultValue={1}
              />
              <FormBaseSelect
                data={tankDesings.data ? tankDesings.data : []}
                name="tank_desing"
                cyPrefix="PE"
                label="Конструкция резервуара"
                defaultValue={1}
              />
              <FormBaseSelect
                data={climaticZones.data ? climaticZones.data : []}
                name="climatic_zone"
                cyPrefix="PE"
                label="Климатическая зона"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 3 ? (
            <>
              <FormBaseSelect
                data={cleaningSystems.data ? cleaningSystems.data : []}
                name="cleaning_system"
                cyPrefix="PE"
                label="Система очистного сооружения"
                onChange={onChangeCleaningSystem}
                defaultValue={1}
              />
              <FormBaseSelect
                data={objectShelterPercentages.data ? objectShelterPercentages.data : []}
                name="object_shelter_percentage"
                cyPrefix="PE"
                label="Процент укрытия обьекта"
                defaultValue={1}
              />
              {form.getFieldValue('emission') == 5 ? (
                <FormBaseSelect
                  data={objectShelterDegerees.data ? objectShelterDegerees.data : []}
                  name="object_shelter_degree"
                  cyPrefix="PE"
                  label="Степень укрытия обьекта"
                  defaultValue={1}
                />
              ) : (
                <FormBaseSelect
                  data={mechanicalCleaningObjects.data ? mechanicalCleaningObjects.data : []}
                  name="mechanical_clean_object"
                  cyPrefix="PE"
                  label="Обьекты механической очистки"
                  defaultValue={1}
                />
              )}
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 4 ? (
            <>
              <FormBaseSelect
                data={cleaningSystems.data ? cleaningSystems.data : []}
                name="cleaning_system"
                cyPrefix="PE"
                label="Система очистного сооружения"
                onChange={onChangeCleaningSystem}
                defaultValue={1}
              />
              {form.getFieldValue('emission') == 8 ? (
                <>
                  <FormBaseSelect
                    data={objectShelterPercentages.data ? objectShelterPercentages.data : []}
                    name="object_shelter_percentage"
                    cyPrefix="PE"
                    label="Процент укрытия обьекта"
                    defaultValue={1}
                  />
                  <FormBaseSelect
                    data={objectShelterDegerees.data ? objectShelterDegerees.data : []}
                    name="object_shelter_degree"
                    cyPrefix="PE"
                    label="Степень укрытия обьекта"
                    defaultValue={1}
                  />
                </>
              ) : (
                <FormBaseSelect
                  data={waterSupplySystems.data ? waterSupplySystems.data : []}
                  name="water_supply_system"
                  cyPrefix="PE"
                  label="Система оборотного водоснабжения"
                  defaultValue={1}
                />
              )}
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 5 ? (
            <>
              <FormBaseSelect
                data={fuelTypes.data ? fuelTypes.data : []}
                name="fuel_type"
                cyPrefix="PE"
                label="Вид топлива"
                onChange={handleChangeVariables}
                defaultValue={1}
              />
              <FormBaseSelect
                data={installationNames.data ? installationNames.data : []}
                name="installation_name"
                cyPrefix="PE"
                label="Наименование установки"
                defaultValue={1}
              />
              {form.getFieldValue('fuel_type') == 2 ? (
                <>
                  <FormBaseSelect
                    data={fuels.data ? fuels.data : []}
                    name="fuel"
                    cyPrefix="PE"
                    label="Топливо"
                    onChange={handleChangeVariables}
                    defaultValue={1}
                  />
                  <FormBaseSelect
                    data={boilerHeatingTypes.data ? boilerHeatingTypes.data : []}
                    name="boiler_heating_type"
                    cyPrefix="PE"
                    label="Тип нагрева котлов"
                    defaultValue={1}
                  />
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 6 ? (
            <FormBaseSelect
              data={vacuumSystems.data ? vacuumSystems.data : []}
              name="vacuum_system"
              cyPrefix="PE"
              label="Вакуум создающие системы"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 9 ? (
            <>
              {form.getFieldValue('emission') == 13 ? (
                <FormBaseSelect
                  data={catalystTypes.data ? catalystTypes.data : []}
                  name="catalyst_type"
                  cyPrefix="PE"
                  label="Вид катализатора"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
              <FormBaseSelect
                data={technologicalInstallations.data ? technologicalInstallations.data : []}
                name="technological_installation"
                cyPrefix="PE"
                label="Технологическая установка"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 11 ? (
            <FormBaseSelect
              data={ventilationSystemTypes.data ? ventilationSystemTypes.data : []}
              name="ventilation_system_type"
              cyPrefix="PE"
              label="Тип системы вентиляции"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 12 ? (
            <FormBaseSelect
              data={ovenTypes.data ? ovenTypes.data : []}
              name="oven_type"
              cyPrefix="PE"
              label="Тип печи"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 12 ? (
            <FormBaseSelect
              data={technologicalInstallations.data ? technologicalInstallations.data : []}
              name="technological_installation"
              cyPrefix="PE"
              label="Технологическая установка"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 13 ? (
            <>
              <FormBaseSelect
                data={vehicleGroups.data ? vehicleGroups.data : []}
                name="vehicle_group"
                cyPrefix="PE"
                label="Группа автомобиля"
                defaultValue={1}
              />
              <FormBaseSelect
                data={vehicleYears.data ? vehicleYears.data : []}
                name="vehicle_year"
                cyPrefix="PE"
                label="Год автомобиля"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="PE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
