import React from 'react';
import { Form, Row, Col } from 'antd';
import { IEARFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getEAREngineModels,
  getEAREngineTypes,
  getEARRunTypes,
  getEARVariables,
  getEARFullReport,
} from '../../../../api/EngineAfterRepair';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const EngineAfterRepair: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const engineTypes = useQuery('EAREngineTypes', getEAREngineTypes);
  const engineModels = useQuery('EAREngineModels', () => getEAREngineModels(form.getFieldValue('engine_type')));
  const runTypes = useQuery('EARRunTypes', getEARRunTypes);
  const variables = useQuery('EARVariables', getEARVariables);

  const onFinish = (values: IEARFormValues) => {
    const { engine_model, engine_type, run_type } = values;

    if (variables.data) {
      getFormResult(getEARFullReport, {
        engineModelId: engine_model,
        engineTypeId: engine_type,
        runTypeId: run_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEngineType = () => {
    engineModels.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={engineTypes.data ? engineTypes.data : []}
            name="engine_type"
            cyPrefix="EAR"
            label="Тип двигателя"
            onChange={onChangeEngineType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={runTypes.data ? runTypes.data : []}
            name="run_type"
            cyPrefix="EAR"
            label="Вид опкатки"
            defaultValue={1}
          />
          <FormBaseSelect
            data={engineModels.data ? engineModels.data : []}
            name="engine_model"
            cyPrefix="EAR"
            label="Модель двигателя"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="EAR"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
