import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ITGCBusType,
  ITGCEngineDisplacement,
  ITGCEngineType,
  ITGCFuelType,
  ITGCHeatingType,
  ITGCInjectionCarburetor,
  ITGCLiftingCapacity,
  ITGCNeutralizerType,
  ITGCReportData,
  ITGCSource,
  ITGCVariable,
} from './interfaces';

const START_URL = 'AvtotransportLibrary';

export const getTGCSources = async (): Promise<ITGCSource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPeredvizhnyeIstochniki`);
  return data;
};

export const getTGCEngineTypes = async (sourceId: string | number): Promise<ITGCEngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDvigatelya`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getTGCFuelTypes = async (engineTypeId: string | number): Promise<ITGCFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, { params: { vidDvigatelyaId: engineTypeId } });
  return data;
};

export const getTGCEngineDisplacements = async (): Promise<ITGCEngineDisplacement[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVDvigatelya`);
  return data;
};

export const getTGCInjectionCarburetor = async (): Promise<ITGCInjectionCarburetor[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVpryskKarbyurator`);
  return data;
};

export const getTGCNeutralizerTypes = async (sourceId: string | number): Promise<ITGCNeutralizerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNeitralizatora`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getTGCHeatingTypes = async (): Promise<ITGCHeatingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNalichiePodogreva`);
  return data;
};

export const getTGCLiftingCapacities = async (
  sourceId: string | number,
  engineTypeId: string | number,
): Promise<ITGCLiftingCapacity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidGruzopodemnost`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineTypeId },
  });
  return data;
};

export const getTGCBusTypes = async (
  sourceId: string | number,
  engineTypeId: string | number,
): Promise<ITGCBusType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlassAvtobusa`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineTypeId },
  });
  return data;
};

export const getTGCVariables = async (): Promise<ITGCVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getTGCFullReport = async (reportData: ITGCReportData): Promise<IToolsReport[]> => {
  const {
    sourceId,
    engineTypeId,
    engineDisplacementId,
    injectionCarburetorId,
    fuelTypeId,
    neutralizerTypeId,
    liftingCapacityId,
    busTypeId,
    heatingId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: sourceId,
      paramName: 'PeredvizhnyeIstochnikiId',
    },
    {
      id: engineTypeId,
      paramName: 'DvigatelId',
    },
    {
      id: engineDisplacementId,
      paramName: 'VDvigatelyaId',
    },
    {
      id: injectionCarburetorId,
      paramName: 'VpryskKarbyuratorId',
    },
    {
      id: fuelTypeId,
      paramName: 'ToplivoId',
    },
    {
      id: neutralizerTypeId,
      paramName: 'NeitralizatorId',
    },
    {
      id: liftingCapacityId,
      paramName: 'GruzopodemnostId',
    },
    {
      id: busTypeId,
      paramName: 'KlassAvtobusaId',
    },
    {
      id: heatingId,
      paramName: 'NalichiePodogrevaId',
    },
    ...variables,
  ]);
  return data;
};
