import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import {
  getEEMPVariables,
  getEEPMFullReport,
  getEEPMProductionTypes,
  getEEPMSources,
} from '../../api/EmissionEnterpriseProcessingMolasses';

import { IEEPMFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const EmissionEnterpriseProcessingMolasses: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const productionTypes = useQuery('EEPMProductionTypes', getEEPMProductionTypes);
  const sources = useQuery('EEPMSources', () => getEEPMSources(form.getFieldValue('production_type')));
  const variables = useQuery('EEPMVariables', getEEMPVariables);

  const onFinish = (values: IEEPMFormValues) => {
    const { production_type, source } = values;

    if (variables.data) {
      getFormResult(getEEPMFullReport, {
        productionTypeId: production_type,
        sourceId: source,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeProductionType = () => {
    sources.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={productionTypes.data ? productionTypes.data : []}
            name="production_type"
            cyPrefix="EEPM"
            label="Вид производства по переработке мелассы"
            defaultValue={1}
            onChange={onChangeProductionType}
          />
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="EEPM"
            label="Наименование источника выделения"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="EEPM"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
