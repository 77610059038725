import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { useQuery } from 'react-query';
import {
  getECPCalculationTypes,
  getECPDusts,
  getECPFuelTypes,
  getECPFullReport,
  getECPNitrogens,
  getECPVariables,
} from '../../api/EnterprisesCementProduction';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { IECPFormValues, IMethodologyModule } from './interfaces';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';
const Option = Select;

export const EnterprisesCementProduction: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('ECPCalculationTypes', getECPCalculationTypes);
  const fuelTypes = useQuery('ECPFuelTypes', getECPFuelTypes);
  const nitrogens = useQuery('ECPNitrogens', getECPNitrogens);
  const dusts = useQuery('ECPDusts', getECPDusts);
  const variables = useQuery('ECPVariables', () => getECPVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IECPFormValues) => {
    const { calculation_type, fuel_type, nitrogen, dust } = values;

    if (variables.data) {
      getFormResult(getECPFullReport, {
        calculationTypeId: calculation_type,
        fuelTypeId: fuel_type,
        nitrogenId: nitrogen,
        dustId: dust,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="ECP"
            label="Вид расчетов"
            onChange={onChangeCalculationType}
            defaultValue={2}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="ECP"
            label="Вид топлива"
            defaultValue={1}
          />
          <Form.Item name="nitrogen" label="Азот NOx" initialValue={1}>
            <Select data-cy="ECP-nitrogen-select">
              {nitrogens.data
                ? nitrogens.data.map((nitrogen) => (
                    <Option data-cy={`ECP-nitrogen-option-${nitrogen.id}`} key={nitrogen.id} value={nitrogen.id}>
                      {nitrogen.aggrType}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <Form.Item name="dust" label="Пыль" initialValue={1}>
            <Select data-cy="ECP-dust-select">
              {dusts.data
                ? dusts.data.map((dust) => (
                    <Option data-cy={`ECP-dust-option-${dust.id}`} key={dust.id} value={dust.id}>
                      {`${dust.uchastok} - ${dust.istochnikVibrosa} - ${dust.istochnikPili}; обьем воздуха - ${dust.obiem}, T - ${dust.temperature}, концентрация пыли - ${dust.concentratPili}`}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="ECP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
