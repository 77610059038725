import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import { getDIECalculationTypes, getDIEFullReport, getDIEVariables } from '../../api/DieselInstallationsEmissions';

import { IDIEFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const DieselInstallationsEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('DIECalculationTypes', getDIECalculationTypes);
  const variables = useQuery('DIEVariables', () => getDIEVariables(form.getFieldValue('calculation_type')));

  const onFinsih = (values: IDIEFormValues) => {
    const { calculation_type } = values;

    if (variables.data) {
      getFormResult(getDIEFullReport, {
        calculationTypeId: calculation_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinsih}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            onChange={onChangeCalculationType}
            cyPrefix="DIE"
            label="Вид расчета"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="DIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
