import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICRWBusType,
  ICRWEngineDisplacement,
  ICRWFuelType,
  ICRWInjectionCarburetor,
  ICRWLEngineType,
  ICRWLiftingCapacity,
  ICRWNeutralizerType,
  ICRWReportData,
  ICRWRoomType,
  ICRWSource,
} from './interfaces';

const START_URL = 'ATPMoykaAvtoLibrary';

export const getCRWRoomTypes = async (): Promise<ICRWRoomType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPomesheniya`);
  return data;
};

export const getCRWSources = async (): Promise<ICRWSource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPeredvizhnyeIstochniki`);
  return data;
};

export const getCRWEngineTypes = async (): Promise<ICRWLEngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDvigatelya`);
  return data;
};

export const getCRWFuelTypes = async (engineTypeId: string | number): Promise<ICRWFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, { params: { vidDvigatelyaId: engineTypeId } });
  return data;
};

export const getCRWEngineDisplacements = async (): Promise<ICRWEngineDisplacement[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVDvigatelya`);
  return data;
};

export const getCRWInjectionCarburetors = async (): Promise<ICRWInjectionCarburetor[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVpryskKarbyurator`);
  return data;
};

export const getCRWNeutralizerTypes = async (sourceId: string | number): Promise<ICRWNeutralizerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNeitralizatora`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getCRWLiftingCapacities = async (
  sourceId: string | number,
  engineId: string | number,
): Promise<ICRWLiftingCapacity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidGruzopodemnost`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineId },
  });
  return data;
};

export const getCRWBusTypes = async (sourceId: string | number, engineId: string | number): Promise<ICRWBusType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlassAvtobusa`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineId },
  });
  return data;
};

export const getCRWVariables = async (roomTypeId: string | number): Promise<ICRWRoomType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { pomeshenieId: roomTypeId } });
  return data;
};

export const getCRWFullReport = async (reportData: ICRWReportData): Promise<IToolsReport[]> => {
  const {
    roomTypeId,
    sourceId,
    liftingCapacityId,
    engineTypeId,
    fuelTypeId,
    engineDisplacementId,
    injectionCarburetorId,
    neutralizerTypeId,
    busTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: roomTypeId,
      paramName: 'PomeshenieId',
    },
    {
      id: sourceId,
      paramName: 'PeredvizhnyeIstochnikiId',
    },
    {
      id: engineTypeId,
      paramName: 'DvigatelId',
    },
    {
      id: fuelTypeId,
      paramName: 'ToplivoId',
    },
    {
      id: engineDisplacementId,
      paramName: 'VDvigatelyaId',
    },
    {
      id: injectionCarburetorId,
      paramName: 'VpryskKarbyuratorId',
    },
    {
      id: neutralizerTypeId,
      paramName: 'NeitralizatorId',
    },
    {
      id: liftingCapacityId,
      paramName: 'GruzopodemnostId',
    },
    {
      id: busTypeId,
      paramName: 'KlassAvtobusaId',
    },
    ...variables,
  ]);
  return data;
};
