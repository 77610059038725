import React from 'react';
import { useStore } from 'effector-react';
import { PollutantsTableTemplate } from '../../ui/templates/PollutantsTableTemplate';

import { $pollutantsTableStore } from './models/pollutantsTableState';

export const PollutantsTable: React.FC = () => {
  const { tableData } = useStore($pollutantsTableStore);

  return <PollutantsTableTemplate data={tableData} />;
};
