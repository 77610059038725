import React from 'react';
import { Form, Row, Col } from 'antd';
import { IBSWFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getBSWAshCatcherTypes,
  getBSWBrownCoalTypes,
  getBSWCoalTypes,
  getBSWEquipmentTypes,
  getBSWFuelTypes,
  getBSWFullReport,
  getBSWOilTypes,
  getBSWTechnologicalOperations,
  getBSWVariables,
} from '../../../../api/BlacksmithWork';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const BlacksmithWork: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const equipmentTypes = useQuery('BSWEquipmentTypes', getBSWEquipmentTypes);
  const fuelTypes = useQuery('BSWFuelTypes', getBSWFuelTypes);
  const brownCoalTypes = useQuery('BSWBrownCoalTypes', getBSWBrownCoalTypes);
  const coalTypes = useQuery('BSWCoalTypes', getBSWCoalTypes);
  const ashCatcherTypes = useQuery('BSWAshCatcherTypes', getBSWAshCatcherTypes);
  const technologicalOperations = useQuery('BSWTechnologicalOperations', getBSWTechnologicalOperations);
  const oilTypes = useQuery('BSWOilTypes', getBSWOilTypes);
  const variables = useQuery('BSWVariables', () =>
    getBSWVariables(form.getFieldValue('euqipment_type'), form.getFieldValue('fuel_type')),
  );

  const onFinish = (values: IBSWFormValues) => {
    const {
      equipment_type,
      fuel_type,
      brown_coal_type,
      coal_type,
      ash_catcher_type,
      technological_operation,
      oil_type,
    } = values;

    if (variables.data) {
      getFormResult(getBSWFullReport, {
        equipmentTypeId: equipment_type,
        fuelTypeId: fuel_type || 1,
        brownCoalTypeId: brown_coal_type || 1,
        coalTypeId: coal_type || 1,
        oilTypeId: oil_type || 1,
        ashCatcherTypeId: ash_catcher_type || 1,
        technicalOperationId: technological_operation || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEquipmentType = () => {
    variables.refetch();
  };

  const onChangeFuelType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={equipmentTypes.data ? equipmentTypes.data : []}
            name="equipment_type"
            cyPrefix="BSW"
            label="Основное технологическое оборудование кузнечных участков"
            defaultValue={1}
            onChange={onChangeEquipmentType}
          />

          {form.getFieldValue('equipment_type') == 2 ? (
            <FormBaseSelect
              data={technologicalOperations.data ? technologicalOperations.data : []}
              name="technological_operation"
              cyPrefix="BSW"
              label="Вид технологической операции"
              defaultValue={1}
            />
          ) : (
            <FormBaseSelect
              data={fuelTypes.data ? fuelTypes.data : []}
              name="fuel_type"
              cyPrefix="BSW"
              label="Вид топлива"
              defaultValue={1}
              onChange={onChangeFuelType}
            />
          )}
          {form.getFieldValue('fuel_type') == 1 && form.getFieldValue('equipment_type') == 1 ? (
            <FormBaseSelect
              data={brownCoalTypes.data ? brownCoalTypes.data : []}
              name="brown_coal_type"
              cyPrefix="BSW"
              label="Месторождение бурого угля"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('fuel_type') !== 4 && form.getFieldValue('equipment_type') == 1 ? (
            <FormBaseSelect
              data={ashCatcherTypes.data ? ashCatcherTypes.data : []}
              name="ash_catcher_type"
              cyPrefix="BSW"
              label="Вид золоуловителя"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('fuel_type') == 2 && form.getFieldValue('equipment_type') == 1 ? (
            <FormBaseSelect
              data={coalTypes.data ? coalTypes.data : []}
              name="coal_type"
              cyPrefix="BSW"
              label="Месторождение каменного угля"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('fuel_type') == 3 && form.getFieldValue('equipment_type') == 1 ? (
            <FormBaseSelect
              data={oilTypes.data ? oilTypes.data : []}
              name="oil_type"
              cyPrefix="BSW"
              label="Вид мазута"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BSW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
