import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import { GasWelding, TechnologicalEquipmentEngineeringEnterprises } from './Submethodologies';

const { Option } = Select;

export const Order4221: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selectedSubmethodology, setSelectedSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`O4221-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelectedSubmethodology(value)}
              >
                <Option data-cy={`O4221-submethodology-option-0`} value={0}>
                  Газосварочные работы
                </Option>
                <Option data-cy={`O4221-submethodology-option-1`} value={1}>
                  Технологическое оборудование предприятий машиностроения
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedSubmethodology == 0 ? (
        <GasWelding marginBetween={marginBetween}>{children}</GasWelding>
      ) : (
        <TechnologicalEquipmentEngineeringEnterprises marginBetween={marginBetween}>
          {children}
        </TechnologicalEquipmentEngineeringEnterprises>
      )}
    </>
  );
};
