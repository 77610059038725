import React from 'react';
import { Form, Row, Col } from 'antd';
import { ILUOEFormValues, IMethodologyModule } from '../.././interfaces';
import { useQuery } from 'react-query';
import {
  getLUOEBreeds,
  getLUOEBulldozerMarks,
  getLUOECalculationTypes,
  getLUOEEquipmentMarks,
  getLUOEEquipmentNames,
  getLUOEExcavationDifficulties,
  getLUOEFullReport,
  getLUOEHumidities,
  getLUOEOperatingModes,
  getLUOEPollutants,
  getLUOERockFortresses,
  getLUOESpecialEquipments,
  getLUOEVariables,
  getLUOEWindSpeeds,
  getLUOEWorkingEquipments,
} from '../../../../api/LoadingUnloadingOperationEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const LoadingUnloadingOperationEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const specialEquipments = useQuery('LUOESpecialEquipments', getLUOESpecialEquipments);
  const equipmentNames = useQuery('LUOEEquipmentNames', getLUOEEquipmentNames);
  const calculationTypes = useQuery('LUOECalculationTypes', getLUOECalculationTypes);
  const equipmentMarks = useQuery('LUOEEquipmentMarks', () =>
    getLUOEEquipmentMarks(form.getFieldValue('equipment_name') || 1),
  );
  const breeds = useQuery('LUOEBreeds', () =>
    getLUOEBreeds(form.getFieldValue('special_equipment'), form.getFieldValue('equipment_name') || 1),
  );
  const rockFortresses = useQuery('LUOERockFortresses', () =>
    getLUOERockFortresses(
      form.getFieldValue('special_equipment'),
      form.getFieldValue('breed'),
      form.getFieldValue('equipment_name') || 1,
    ),
  );
  const workingEquipments = useQuery('LUOEWorkingEquipments', getLUOEWorkingEquipments);
  const excavationDifficulties = useQuery('LUOEExcavationDifficulties', () =>
    getLUOEExcavationDifficulties(form.getFieldValue('working_equipment')),
  );
  const windSpeeds = useQuery('LUOEWindSpeeds', getLUOEWindSpeeds);
  const humidities = useQuery('LUOEHumidities', getLUOEHumidities);
  const bulldozerMarks = useQuery('LUOEBulldozerMarks', getLUOEBulldozerMarks);
  const pollutants = useQuery('LUOEPollutants', getLUOEPollutants);
  const operatingModes = useQuery('LUOEOperatingModes', getLUOEOperatingModes);
  const variables = useQuery('LUOEVariables', () =>
    getLUOEVariables(
      form.getFieldValue('special_equipment'),
      form.getFieldValue('equipment_name'),
      form.getFieldValue('calculation_type'),
      form.getFieldValue('equipment_mark'),
    ),
  );

  const onFinish = (values: ILUOEFormValues) => {
    const {
      special_equipment,
      calculation_type,
      bulldozer_mark,
      equipment_name,
      equipment_mark,
      breed,
      rock_fortress,
      working_equipment,
      excavation_difficulty,
      wind_speed,
      humidity,
      operating_mode,
      pollutant,
    } = values;

    if (variables.data) {
      getFormResult(getLUOEFullReport, {
        specialEquipmentId: special_equipment,
        equipmentNameId: equipment_name || 1,
        calculationTypeId: calculation_type || 1,
        equipmentMarkId: equipment_mark || 1,
        breedId: breed || 1,
        rockFortressId: rock_fortress || 1,
        workingEquipmentId: working_equipment || 1,
        excavationDifficultyId: excavation_difficulty || 1,
        windSpeed: wind_speed || 1,
        humidityId: humidity || 1,
        bulldozerMarkId: bulldozer_mark || 1,
        pollutantId: pollutant || 1,
        operatingMode: operating_mode || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEquipmentName = () => {
    breeds.refetch();
    rockFortresses.refetch();
    variables.refetch();
    equipmentMarks.refetch();
  };

  const onChangeSpecialEquipment = () => {
    breeds.refetch();
    rockFortresses.refetch();
    variables.refetch();
  };

  const onChangeBreed = () => {
    rockFortresses.refetch();
  };

  const onChangeWorkingEquipment = () => {
    excavationDifficulties.refetch();
  };

  const onChangeCalculatoinType = () => {
    variables.refetch();
  };

  const onChangeEquipmentMark = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={specialEquipments.data ? specialEquipments.data : []}
            name="special_equipment"
            cyPrefix="LUOE"
            label="Вид спецтехники"
            onChange={onChangeSpecialEquipment}
            defaultValue={1}
          />
          {form.getFieldValue('special_equipment') == 2 ? (
            <>
              <FormBaseSelect
                data={calculationTypes.data ? calculationTypes.data : []}
                name="calculation_type"
                cyPrefix="LUOE"
                label="Вид расчета"
                onChange={onChangeCalculatoinType}
                defaultValue={1}
              />
              <FormBaseSelect
                data={bulldozerMarks.data ? bulldozerMarks.data : []}
                name="bulldozer_mark"
                cyPrefix="LUOE"
                label="Марка бульдозера"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('special_equipment') == 1 ? (
            <>
              <FormBaseSelect
                data={equipmentNames.data ? equipmentNames.data : []}
                name="equipment_name"
                cyPrefix="LUOE"
                label="Наименование оборудования"
                onChange={onChangeEquipmentName}
                defaultValue={1}
              />
              <FormBaseSelect
                data={equipmentMarks.data ? equipmentMarks.data : []}
                name="equipment_mark"
                cyPrefix="LUOE"
                label="Марка оборудования"
                onChange={onChangeEquipmentMark}
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') != 2 ? (
            <>
              <FormBaseSelect
                data={breeds.data ? breeds.data : []}
                name="breed"
                cyPrefix="LUOE"
                label="Породы"
                onChange={onChangeBreed}
                defaultValue={1}
              />
              <FormBaseSelect
                data={rockFortresses.data ? rockFortresses.data : []}
                name="rock_fortress"
                cyPrefix="LUOE"
                label="Крепость пород"
                defaultValue={1}
              />
              <FormBaseSelect
                data={workingEquipments.data ? workingEquipments.data : []}
                name="working_equipment"
                cyPrefix="LUOE"
                label="Вид рабочего оборудования"
                onChange={onChangeWorkingEquipment}
                defaultValue={1}
              />
              <FormBaseSelect
                data={excavationDifficulties.data ? excavationDifficulties.data : []}
                name="excavation_difficulty"
                cyPrefix="LUOE"
                label="Категория пород по трудности экскавации"
                defaultValue={1}
              />
              <FormBaseSelect
                data={windSpeeds.data ? windSpeeds.data : []}
                name="wind_speed"
                cyPrefix="LUOE"
                label="Скорость ветра g, м/с"
                defaultValue={1}
              />
              <FormBaseSelect
                data={humidities.data ? humidities.data : []}
                name="humidity"
                cyPrefix="LUOE"
                label="Влажность материала, %"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          {form.getFieldValue('special_equipment') == 2 && form.getFieldValue('calculation_type') == 2 ? (
            <>
              <FormBaseSelect
                data={operatingModes.data ? operatingModes.data : []}
                name="operating_mode"
                cyPrefix="LUOE"
                label="Режим работы"
                defaultValue={1}
              />
              <FormBaseSelect
                data={pollutants.data ? pollutants.data : []}
                name="pollutant"
                cyPrefix="LUOE"
                label="Вид ЗВ"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="LUOE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
