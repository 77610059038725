import React from 'react';
import { Form, Row, Col, InputNumber } from 'antd';
import { IFSEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getFSEBlowingSpeeds,
  getFSECalculationTypes,
  getFSEDropHeights,
  getFSEFullReport,
  getFSEHumidities,
  getFSELiftCapacities,
  getFSELocalConditions,
  getFSEMaterials,
  getFSEPieceSizes,
  getFSERoadTypes,
  getFSEStockMaterials,
  getFSETransportSpeeds,
  getFSEVariables,
  getFSEWindSpeeds,
} from '../../../../api/FugitiveSourceEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const FugitiveSourceEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('FSECalculationTypes', getFSECalculationTypes);
  const materials = useQuery('FSEMaterials', getFSEMaterials);
  const windSpeeds = useQuery('FSEWindSpeeds', getFSEWindSpeeds);
  const localConditions = useQuery('FSELocalConditions', getFSELocalConditions);
  const humidities = useQuery('FSEHumidities', getFSEHumidities);
  const pieceSizes = useQuery('FSEPieceSizes', getFSEPieceSizes);
  const stockMaterials = useQuery('FSEStockMaterials', getFSEStockMaterials);
  const dropHeights = useQuery('FSEDropHeights', getFSEDropHeights);
  const liftCapacities = useQuery('FSELiftCapacities', getFSELiftCapacities);
  const roadTypes = useQuery('FSERoadTypes', getFSERoadTypes);
  const transportSpeeds = useQuery('FSETransportSpeeds', getFSETransportSpeeds);
  const blowingSpeeds = useQuery('FSEBlowingSpeeds', getFSEBlowingSpeeds);
  const variables = useQuery('FSEVariables', () =>
    getFSEVariables(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('coal_type') || 0,
      form.getFieldValue('drilling_rig') || 0,
    ),
  );

  const onFinish = (values: IFSEFormValues) => {
    const {
      calculation_type,
      material,
      wind_speed,
      drop_height,
      local_condition,
      humidity,
      piece_size,
      stock_material,
      lift_capacity,
      road_type,
      transport_speed,
      blowing_speed,
    } = values;

    if (variables.data) {
      getFormResult(getFSEFullReport, {
        calculationTypeId: calculation_type,
        materialId: material || 1,
        windSpeedId: wind_speed || 1,
        localCondition: local_condition || 1,
        humidityId: humidity || 1,
        pieceSizeId: piece_size || 1,
        stockMaterialId: stock_material || 1,
        dropHeightId: drop_height || 1,
        liftCapacityId: lift_capacity || 1,
        roadTypeId: road_type || 1,
        transportSpeedId: transport_speed || 1,
        blowingSpeedId: blowing_speed || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="FSE"
            label="Вид расчетов"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') == 1 ||
          form.getFieldValue('calculation_type') == 2 ||
          form.getFieldValue('calculation_type') == 5 ? (
            <>
              <FormBaseSelect
                data={materials.data ? materials.data : []}
                name="material"
                cyPrefix="FSE"
                label="Материал"
                defaultValue={1}
              />
              <FormBaseSelect
                data={windSpeeds.data ? windSpeeds.data : []}
                name="wind_speed"
                cyPrefix="FSE"
                label="Скорость ветра"
                defaultValue={1}
              />
              <FormBaseSelect
                data={dropHeights.data ? dropHeights.data : []}
                name="drop_height"
                cyPrefix="FSE"
                label="Высота падения материала"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 ||
          form.getFieldValue('calculation_type') == 2 ||
          form.getFieldValue('calculation_type') == 5 ||
          form.getFieldValue('calculation_type') == 7 ? (
            <FormBaseSelect
              data={localConditions.data ? localConditions.data : []}
              name="local_condition"
              cyPrefix="FSE"
              label="Местные условия"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') != 3 && form.getFieldValue('calculation_type') != 6 ? (
            <FormBaseSelect
              data={humidities.data ? humidities.data : []}
              name="humidity"
              cyPrefix="FSE"
              label="Влажность материала"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') <= 2 ? (
            <FormBaseSelect
              data={pieceSizes.data ? pieceSizes.data : []}
              name="piece_size"
              cyPrefix="FSE"
              label="Размер куска"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 ? (
            <FormBaseSelect
              data={stockMaterials.data ? stockMaterials.data : []}
              name="stock_material"
              cyPrefix="FSE"
              label="Складируемй материал"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 4 ? (
            <>
              <FormBaseSelect
                data={liftCapacities.data ? liftCapacities.data : []}
                name="lift_capacity"
                cyPrefix="FSE"
                label="Средняя грузоподъемность"
                defaultValue={1}
              />
              <FormBaseSelect
                data={roadTypes.data ? roadTypes.data : []}
                name="road_type"
                cyPrefix="FSE"
                label="Вид дороги"
                defaultValue={1}
              />
              <FormBaseSelect
                data={transportSpeeds.data ? transportSpeeds.data : []}
                name="transport_speed"
                cyPrefix="FSE"
                label="Средняя скорость транспортирования"
                defaultValue={1}
              />
              <FormBaseSelect
                data={blowingSpeeds.data ? blowingSpeeds.data : []}
                name="blowing_speed"
                cyPrefix="FSE"
                label="Скорость обдува"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 3 ? (
            <Form.Item name="coal_type" label="Количество разновидностей угля" initialValue={1}>
              <InputNumber
                data-cy="FSE-coal_type-input"
                min={1}
                placeholder={'1'}
                defaultValue={1}
                onChange={handleChangeVariables}
              />
            </Form.Item>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 6 ? (
            <Form.Item
              name="drilling_rig"
              label="Количество единовременно работающих буровых различных станков"
              initialValue={1}
            >
              <InputNumber
                data-cy="FSE-drilling_rig-input"
                min={1}
                placeholder={'1'}
                defaultValue={1}
                onChange={handleChangeVariables}
              />
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="FSE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
