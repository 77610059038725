import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getFIEBranchTypes,
  getFIEBurnedWoods,
  getFIEEquipmentTypes,
  getFIEFullReport,
  getFIEProcessTypes,
  getFIESources,
  getFIEVariables,
} from '../../api/FishIndustryEmissions';

import { IFIEFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const FishIndustryEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const processTypes = useQuery('FIEProcessTypes', getFIEProcessTypes);
  const equipmentTypes = useQuery('FIEEquipmentTypes', () => getFIEEquipmentTypes(form.getFieldValue('process_type')));
  const sources = useQuery('FIESources', () =>
    getFIESources(form.getFieldValue('process_type'), form.getFieldValue('equipment_type')),
  );
  const burnedWoods = useQuery('FIEBurnedWoods', getFIEBurnedWoods);
  const branchTypes = useQuery('FIEBranchTypes', getFIEBranchTypes);
  const variables = useQuery('FIEVariables', () =>
    getFIEVariables(form.getFieldValue('process_type'), form.getFieldValue('equipment_type')),
  );

  const onFinish = (values: IFIEFormValues) => {
    const { process_type, equipment_type, source, branch_type, burned_wood } = values;

    if (variables.data) {
      getFormResult(getFIEFullReport, {
        processTypeId: process_type,
        equipmentTypeId: equipment_type || 1,
        sourceId: source,
        branchTypeId: branch_type || 1,
        burnedWoodId: burned_wood || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeProcessType = () => {
    equipmentTypes.refetch();
    sources.refetch();
    variables.refetch();
  };

  const onChangeEquipmentType = () => {
    sources.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={processTypes.data ? processTypes.data : []}
            name="process_type"
            cyPrefix="FIE"
            label="Вид процесса"
            defaultValue={1}
            onChange={onChangeProcessType}
          />
          {form.getFieldValue('process_type') % 2 !== 0 ? (
            <FormBaseSelect
              data={equipmentTypes.data ? equipmentTypes.data : []}
              name="equipment_type"
              cyPrefix="FIE"
              label="Вид оборудования"
              defaultValue={1}
              onChange={onChangeEquipmentType}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') <= 2 ? (
            <FormBaseSelect
              data={sources.data ? sources.data : []}
              name="source"
              cyPrefix="FIE"
              label="Источники выделения"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') === 2 ? (
            <FormBaseSelect
              data={burnedWoods.data ? burnedWoods.data : []}
              name="burned_wood"
              cyPrefix="FIE"
              label="Вид сжигаемой древесины"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') === 3 ? (
            <FormBaseSelect
              data={branchTypes.data ? branchTypes.data : []}
              name="branch_type"
              cyPrefix="FIE"
              label="Вид отделения"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="FIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
