import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IPCOperationType, IPCReportData, IPCSubstance, IPCTechnologicalProcess, IPCVariable } from './interfaces';

const START_URL = 'ATPMoykaDetaleyLibrary';

export const getPCOperationTypes = async (): Promise<IPCOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOperacii`);
  return data;
};

export const getPCTechnologicalProcesses = async (
  operationTypeId: string | number,
): Promise<IPCTechnologicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechProcess`, { params: { operaciyaId: operationTypeId } });
  return data;
};

export const getPCSubstances = async (
  operationTypeId: string | number,
  technologicalProcessId: string | number,
): Promise<IPCSubstance[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSostav`, {
    params: { operaciyaId: operationTypeId, techProcessId: technologicalProcessId },
  });
  return data;
};

export const getPCVariables = async (operationTypeId: string | number): Promise<IPCVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { operaciyaId: operationTypeId } });
  return data;
};

export const getPCFullReport = async (reportData: IPCReportData): Promise<IToolsReport[]> => {
  const { operationTypeId, technologicalProcessId, substanceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: operationTypeId,
      paramName: 'OperaciyaId',
    },
    {
      id: technologicalProcessId,
      paramName: 'TechProcessId',
    },
    {
      id: substanceId,
      paramName: 'SostavId',
    },
    ...variables,
  ]);
  return data;
};
