import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import {
  getTRFEFullReport,
  getTRFEOperationTypes,
  getTRFESubstances,
  getTRFEVariables,
} from '../../../../api/TestingRepairFuelEquipment';

import { IMethodologyModule, ITRFEFormValues } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const TestingRepairFuelEquipment: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const operationTypes = useQuery('TRFEOperationTypes', getTRFEOperationTypes);
  const substances = useQuery('TRFESubstances', getTRFESubstances);
  const variables = useQuery('TRFEVariables', () =>
    getTRFEVariables(form.getFieldValue('operation_type'), form.getFieldValue('substance')),
  );

  const onFinish = (values: ITRFEFormValues) => {
    const { operation_type, substance } = values;

    if (variables.data) {
      getFormResult(getTRFEFullReport, {
        operationTypeId: operation_type,
        substanceId: substance,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={operationTypes.data ? operationTypes.data : []}
            name="operation_type"
            cyPrefix="TRFE"
            label="Вид операции"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={substances.data ? substances.data : []}
            name="substance"
            cyPrefix="TRFE"
            label="Преминяемые вещества и материалы"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TRFE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
