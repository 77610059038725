import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { getBEFullReport, getBEPollutants, getBEProductionStages, getBEVariables } from '../../api/BakeriesEmissions';

import { IBEFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const BakeriesEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const productionStages = useQuery('BEProductionStages', getBEProductionStages);
  const pollutants = useQuery('BEPollutants', () => getBEPollutants(form.getFieldValue('production_stage')));
  const variables = useQuery('BEVariables', getBEVariables);

  const onFinish = (values: IBEFormValues) => {
    const { pollutant, production_stage } = values;

    if (variables.data) {
      getFormResult(getBEFullReport, {
        productionStageId: production_stage,
        pollutantId: pollutant,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeProductionStage = () => {
    pollutants.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={productionStages.data ? productionStages.data : []}
            name="production_stage"
            cyPrefix={'BE'}
            label="Этапы производства хлебобулочных изделий"
            defaultValue={1}
            onChange={onChangeProductionStage}
          />
          <FormBaseSelect
            data={pollutants.data ? pollutants.data : []}
            name="pollutant"
            cyPrefix={'BE'}
            label="Наименование загрязняющего вещества"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
