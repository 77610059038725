import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import {
  AreasEmissions,
  CarParking,
  CareerTransport,
  EngineAfterRepair,
  BatterWork,
  BlacksmithWork,
  PartsCleaning,
  RepairRubberProducts,
  TestingRepairFuelEquipment,
  CopperWork,
  CarWash,
  ToxicityGasControl,
} from './Submethodologies';

const { Option } = Select;

export const TruckingCompany: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selectedSubmethodology, setSelectedSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`TC-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelectedSubmethodology(value)}
              >
                <Option data-cy={`TC-submethodology-option-0`} value={0}>
                  Стоянка автомобилей
                </Option>
                <Option data-cy={`TC-submethodology-option-1`} value={1}>
                  Аккумуляторные работы
                </Option>
                <Option data-cy={`TC-submethodology-option-2`} value={2}>
                  Кузнечные работы
                </Option>
                <Option data-cy={`TC-submethodology-option-3`} value={3}>
                  Выбросы от участков ТО и ТР
                </Option>
                <Option data-cy={`TC-submethodology-option-4`} value={4}>
                  Карьерный транспор
                </Option>
                <Option data-cy={`TC-submethodology-option-5`} value={5}>
                  Испытание и ремонт топливной аппаратуры
                </Option>
                <Option data-cy={`TC-submethodology-option-6`} value={6}>
                  Мойка и очистка деталей, узлов и агрегатов
                </Option>
                <Option data-cy={`TC-submethodology-option-7`} value={7}>
                  Обкатка двигателя после ремонта
                </Option>
                <Option data-cy={`TC-submethodology-option-8`} value={8}>
                  Ремонт резинотехнических изделий
                </Option>
                <Option data-cy={`TC-submethodology-option-9`} value={9}>
                  Медницкие работы
                </Option>
                <Option data-cy={`TC-submethodology-option-10`} value={10}>
                  Мойка автомобилей
                </Option>
                <Option data-cy={`TC-submethodology-option-11`} value={11}>
                  Контроль токсичности отработавших газов
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedSubmethodology == 0 ? <CarParking marginBetween={marginBetween}>{children}</CarParking> : ''}
      {selectedSubmethodology == 1 ? <BatterWork marginBetween={marginBetween}>{children}</BatterWork> : ''}
      {selectedSubmethodology == 2 ? <BlacksmithWork marginBetween={marginBetween}>{children}</BlacksmithWork> : ''}
      {selectedSubmethodology == 3 ? <AreasEmissions marginBetween={marginBetween}>{children}</AreasEmissions> : ''}
      {selectedSubmethodology == 4 ? <CareerTransport marginBetween={marginBetween}>{children}</CareerTransport> : ''}
      {selectedSubmethodology == 5 ? (
        <TestingRepairFuelEquipment marginBetween={marginBetween}>{children}</TestingRepairFuelEquipment>
      ) : (
        ''
      )}
      {selectedSubmethodology == 6 ? <PartsCleaning marginBetween={marginBetween}>{children}</PartsCleaning> : ''}
      {selectedSubmethodology == 7 ? (
        <EngineAfterRepair marginBetween={marginBetween}>{children}</EngineAfterRepair>
      ) : (
        ''
      )}
      {selectedSubmethodology == 8 ? (
        <RepairRubberProducts marginBetween={marginBetween}>{children}</RepairRubberProducts>
      ) : (
        ''
      )}
      {selectedSubmethodology == 9 ? <CopperWork marginBetween={marginBetween}>{children}</CopperWork> : ''}
      {selectedSubmethodology == 10 ? <CarWash marginBetween={marginBetween}>{children}</CarWash> : ''}
      {selectedSubmethodology == 11 ? (
        <ToxicityGasControl marginBetween={marginBetween}>{children}</ToxicityGasControl>
      ) : (
        ''
      )}
    </>
  );
};
