import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col, InputNumber } from 'antd';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getWPMCalculationTypes,
  getWPMEquipmentTypes,
  getWPMFullReport,
  getWPMMaterialTypes,
  getWPMMechanicalRestorationTypes,
  getWPMOperationTypes,
  getWPMPrepressingModes,
  getWPMProductMassTypes,
  getWPMVariables,
} from '../../api/WorkingPlasticMaterials';

import { IMethodologyModule, IWPMFormValues } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const WorkingPlasticMaterials: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('WPMCalculationTypes', getWPMCalculationTypes);
  const equipmentTypes = useQuery('WPMEquipmentTypes', () =>
    getWPMEquipmentTypes(form.getFieldValue('calculation_type')),
  );
  const operationTypes = useQuery('WPMOperationTypes', () =>
    getWPMOperationTypes(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('calculation_type') != 1 && form.getFieldValue('calculation_type') != 10
        ? form.getFieldValue('equipment_type') || 1
        : 0,
    ),
  );
  const mechanicalRestorationTypes = useQuery('WPMMechanicalRestorationTypes', () =>
    getWPMMechanicalRestorationTypes(form.getFieldValue('calculation_type')),
  );
  const productMassTypes = useQuery('WPMProductMassTypes', () =>
    getWPMProductMassTypes(form.getFieldValue('calculation_type')),
  );
  const materialTypes = useQuery('WPMMaterialTypes', () =>
    getWPMMaterialTypes(form.getFieldValue('calculation_type'), form.getFieldValue('operation_type') || 1),
  );
  const prepressingModes = useQuery('WPMPrepressingModes', getWPMPrepressingModes);
  const variables = useQuery('WPMVariables', () => getWPMVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IWPMFormValues) => {
    const {
      calculation_type,
      operation_type,
      material_type,
      equipment_type,
      mechanical_restoration_type,
      product_mass_types,
      prepressing_mode,
    } = values;

    if (variables.data) {
      getFormResult(getWPMFullReport, {
        calculationTypeId: calculation_type,
        equipmentId: equipment_type || 1,
        operationId: operation_type || 1,
        mechanicalRestorationTypeId: mechanical_restoration_type || 1,
        productMassTypeId: product_mass_types || 1,
        materialTypeId: material_type || 1,
        prepressingMode: prepressing_mode || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    equipmentTypes.refetch();
    operationTypes.refetch();
    mechanicalRestorationTypes.refetch();
    productMassTypes.refetch();
    materialTypes.refetch();
    variables.refetch();
  };

  const onChangeOperationType = () => {
    materialTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="WPM"
            label="Вид расчета"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') == 1 ||
          form.getFieldValue('calculation_type') == 8 ||
          form.getFieldValue('calculation_type') == 10 ? (
            <FormBaseSelect
              data={operationTypes.data ? operationTypes.data : []}
              name="operation_type"
              cyPrefix="WPM"
              label="Вид операции"
              onChange={onChangeOperationType}
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 ? (
            <FormBaseSelect
              data={materialTypes.data ? materialTypes.data : []}
              name="material_type"
              cyPrefix="WPM"
              label="Вид материала"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {equipmentTypes.data ? (
            equipmentTypes.data.length ? (
              <FormBaseSelect
                data={equipmentTypes.data ? equipmentTypes.data : []}
                name="equipment_type"
                cyPrefix="WPM"
                label="Вид оборудования"
                defaultValue={1}
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {mechanicalRestorationTypes.data ? (
            mechanicalRestorationTypes.data.length ? (
              <FormBaseSelect
                data={mechanicalRestorationTypes.data ? mechanicalRestorationTypes.data : []}
                name="mechanical_restoration_type"
                cyPrefix="WPM"
                label="Вид механической обработки"
                defaultValue={1}
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {productMassTypes.data ? (
            productMassTypes.data.length ? (
              <FormBaseSelect
                data={productMassTypes.data ? productMassTypes.data : []}
                name="product_mass_types"
                cyPrefix="WPM"
                label="Вид массы обрабатываемого изделия"
                defaultValue={1}
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 ? (
            <FormBaseSelect
              data={prepressingModes.data ? prepressingModes.data : []}
              name="prepressing_mode"
              cyPrefix="WPM"
              label="Вид массы обрабатываемого изделия"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          {variables.data
            ? variables.data.map((variable, index) => (
                <Form.Item key={variable.id + variable.name} label={variable.name} name={variable.name}>
                  <InputNumber data-cy={`WPM-input-${index}`} min={0} placeholder={'0'} />
                </Form.Item>
              ))
            : []}
        </Col>
      </Row>
      {children}
    </Form>
  );
};
