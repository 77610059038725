import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import { getDSOFullReport, getDSOOperationTypes, getDSOVariables } from '../../../../api/DrySandOven';

import { IDSOFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const DrySandOven: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const operationTypes = useQuery('DSOOperationTypes', getDSOOperationTypes);
  const variables = useQuery('DSOVariables', getDSOVariables);

  const onFinish = (value: IDSOFormValues) => {
    const { operation_type } = value;

    if (variables.data) {
      getFormResult(getDSOFullReport, {
        operationType: operation_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={operationTypes.data ? operationTypes.data : []}
            name="operation_type"
            cyPrefix="DSO"
            label="Вид операции"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="DSO"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
