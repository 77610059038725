import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ILREClimaticZone,
  ILREOil,
  ILREOilGroup,
  ILREOperationMode,
  ILRETankDesign,
  IRLEReportData,
  IRLEVariable,
} from './interfaces';

const START_URL = 'NalivnyeEstakadyLibrary';

export const getLREClimaticZones = async (): Promise<ILREClimaticZone[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlimaticheskoyZony`);
  return data;
};

export const getLREOils = async (): Promise<ILREOil[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNefteprodukt`);
  return data;
};

export const getLREOilGroups = async (): Promise<ILREOilGroup[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidGruppaNefteprodukta`);
  return data;
};

export const getLRETankDesigns = async (): Promise<ILRETankDesign[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKonstrukciyaReservuara`);
  return data;
};

export const getLREOperationModes = async (tankDesignId: string | number): Promise<ILREOperationMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRezhimEkspluatacii`, {
    params: { konstrukciyaReservuaraId: tankDesignId },
  });
  return data;
};

export const getLREVariables = async (): Promise<IRLEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getLREFullReport = async (reportData: IRLEReportData): Promise<IToolsReport[]> => {
  const { climaticZoneId, oilId, oilGroupId, tankDesignId, operationModeId, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: climaticZoneId,
      paramName: 'KlimaticheskayaZonaId',
    },
    {
      id: oilId,
      paramName: 'NefteproduktId',
    },
    {
      id: oilGroupId,
      paramName: 'GruppaNefteproduktaId',
    },
    {
      id: tankDesignId,
      paramName: 'KonstrukciyaReservuaraId',
    },
    {
      id: operationModeId,
      paramName: 'RezhimEkspluataciiId',
    },
    ...variables,
  ]);
  return data;
};
