import React from 'react';
import { Form, Row, Col } from 'antd';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import {
  getMMPAvailabilityDustCollectionEquipments,
  getMMPFullReport,
  getMMPGravitySubsidenceCoefficients,
  getMMPImpuritySuctionRatios,
  getMMPProcessingTypes,
  getMMPSpecificEmissionEquipments,
  getMMPSpecificOilEmissionEquipments,
  getMMPTechnologicalOperationTypes,
  getMMPTechnologicalProcessTypes,
  getMMPVariables,
} from '../../api/MetalMechanicalProcessing';

import { IMethodologyModule, IMMPFormValues } from './interfaces';

import { useQuery } from 'react-query';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const MetalMechanicalProcessing: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const processingTypes = useQuery('MMPProcessingTypes', getMMPProcessingTypes);
  const availabilityDustCollectionEquipments = useQuery(
    'MMPAvailabilityDustCollectionEquipments',
    getMMPAvailabilityDustCollectionEquipments,
  );
  const gravitySubsidenceCoefficients = useQuery(
    'MMPGravitySubsidenceCoefficients',
    getMMPGravitySubsidenceCoefficients,
  );
  const technologicalProcessTypes = useQuery('MMPTechnologicalProcessTypes', getMMPTechnologicalProcessTypes);
  const specificEmissionEquipments = useQuery('MMPSpecificEmissionEquipments', () =>
    getMMPSpecificEmissionEquipments(
      form.getFieldValue('technological_process_type'),
      form.getFieldValue('technological_operation_type') || 1,
    ),
  );
  const specificOilEmissionEquipments = useQuery(
    'MMPSpecificOilEmissionEquipments',
    getMMPSpecificOilEmissionEquipments,
  );
  const impuritySuctionRatios = useQuery('MMPImpuritySuctionRatios', getMMPImpuritySuctionRatios);
  const technologicalOperationTypes = useQuery('MMPTechnologicalOperationTypes', () =>
    getMMPTechnologicalOperationTypes(form.getFieldValue('technological_process_type')),
  );
  const variables = useQuery('MMPVariables', () =>
    getMMPVariables(
      form.getFieldValue('availability_dust_collection_equipment'),
      form.getFieldValue('impurity_suction_ratio'),
      form.getFieldValue('processing_type'),
    ),
  );

  const onFinish = (values: IMMPFormValues) => {
    const {
      processing_type,
      availability_dust_collection_equipment,
      gravity_subsidence_coefficient,
      technological_operation_type,
      technological_process_type,
      impurity_suction_ratio,
      specific_emission_equipment,
      specific_oil_emissione_equipment,
    } = values;

    if (variables.data) {
      getFormResult(getMMPFullReport, {
        processingTypeId: processing_type,
        availabilityDustCollectionEquipmentId: availability_dust_collection_equipment,
        gravitySubsidenceCoefficientId: gravity_subsidence_coefficient,
        technologicalProcessTypeId: technological_process_type,
        technologicalOperationTypeId: technological_operation_type || 1,
        impuritySuctionRatioId: impurity_suction_ratio || 1,
        specificEmissionEquipmentId: specific_emission_equipment,
        specificOilEmissionEquipmentId: specific_oil_emissione_equipment || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  const handleChangeSpecificEmissionEquipments = () => {
    specificEmissionEquipments.refetch();
  };

  const onChangeTechnologicalProcessType = () => {
    handleChangeSpecificEmissionEquipments();
    technologicalOperationTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={processingTypes.data ? processingTypes.data : []}
            name="processing_type"
            cyPrefix="MMP"
            onChange={handleChangeVariables}
            defaultValue={1}
            label="Вид механической обработки"
          />
          {form.getFieldValue('processing_type') === 2 ? (
            <FormBaseSelect
              data={specificOilEmissionEquipments.data ? specificOilEmissionEquipments.data : []}
              name="specific_oil_emissione_equipment"
              cyPrefix="MMP"
              defaultValue={1}
              label="Вид оборудования, удельный выброс масла или эмульсола на 1 кВт мощности станка"
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={availabilityDustCollectionEquipments.data ? availabilityDustCollectionEquipments.data : []}
            name="availability_dust_collection_equipment"
            cyPrefix="MMP"
            onChange={handleChangeVariables}
            defaultValue={1}
            label="Наличие пылеулавливающего оборудования"
          />
          {form.getFieldValue('availability_dust_collection_equipment') === 2 ? (
            <FormBaseSelect
              data={impuritySuctionRatios.data ? impuritySuctionRatios.data : []}
              name="impurity_suction_ratio"
              cyPrefix="MMP"
              onChange={handleChangeVariables}
              defaultValue={1}
              label="Коэффициент эффективности местных отсосов"
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={gravitySubsidenceCoefficients.data ? gravitySubsidenceCoefficients.data : []}
            name="gravity_subsidence_coefficient"
            cyPrefix="MMP"
            defaultValue={1}
            label="Коэффициент гравитационного оседания, К"
          />
          <FormBaseSelect
            data={technologicalProcessTypes.data ? technologicalProcessTypes.data : []}
            name="technological_process_type"
            cyPrefix="MMP"
            onChange={onChangeTechnologicalProcessType}
            defaultValue={1}
            label="Вид технологического процесса"
          />
          {form.getFieldValue('technological_process_type') === 2 ||
          form.getFieldValue('technological_process_type') === 4 ||
          form.getFieldValue('technological_process_type') === 5 ? (
            <FormBaseSelect
              data={technologicalOperationTypes.data ? technologicalOperationTypes.data : []}
              name="technological_operation_type"
              cyPrefix="MMP"
              onChange={handleChangeSpecificEmissionEquipments}
              defaultValue={1}
              label="Вид технологической операции"
            />
          ) : (
            ''
          )}
          <FormBaseSelect
            data={specificEmissionEquipments.data ? specificEmissionEquipments.data : []}
            name="specific_emission_equipment"
            cyPrefix="MMP"
            defaultValue={1}
            label="Вид оборудования, удельный выброс Q, г/с"
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="MMP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
