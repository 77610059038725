import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import { IGWFromValus, IMethodologyModule } from '../../interfaces';

import {
  getGWCalculationTypes,
  getGWFullReport,
  getGWVariables,
  getGWWeldingOrCuttingTypes,
} from '../../../../api/GasWelding';

import { useQuery } from 'react-query';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const GasWelding: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('GWCalculationTypes', getGWCalculationTypes);
  const weldingOrCuttingTypes = useQuery('GWWeldingOrCuttingTypes', () =>
    getGWWeldingOrCuttingTypes(form.getFieldValue('calculation_type')),
  );
  const variables = useQuery('GWVariables', () => getGWVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IGWFromValus) => {
    const { calculation_type, welding_or_cutting_type } = values;

    if (variables.data) {
      getFormResult(getGWFullReport, {
        calculationType: calculation_type,
        WeldingTypeOrCuttingType: welding_or_cutting_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    weldingOrCuttingTypes.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="GW"
            label="Вид расчетов"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          <Form.Item
            name="welding_or_cutting_type"
            label={form.getFieldValue('calculation_type') > 3 ? 'Вид резки' : 'Вид сварки'}
            initialValue={1}
          >
            <Select data-cy="GW-welding_or_cutting_type-select">
              {weldingOrCuttingTypes.data
                ? weldingOrCuttingTypes.data.map((type) => (
                    <Option data-cy={`GW-welding_or_cutting_type-option-${type.id}`} key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="GW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
