import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getERSCalculationTypes,
  getERSEngines,
  getERSFullReport,
  getERSVariables,
} from '../../../../api/EngineRunningSection';

import { IERSFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const EngineRunningSection: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('ERSCalculationTypes', getERSCalculationTypes);
  const engines = useQuery('ERSEngines', getERSEngines);
  const variables = useQuery('ERSVariables', () => getERSVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IERSFormValues) => {
    const { calculation_type, engine } = values;

    if (variables.data) {
      getFormResult(getERSFullReport, {
        calculationTypeId: calculation_type,
        engineId: engine,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="ERS"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalculationType}
          />
          <FormBaseSelect
            data={engines.data ? engines.data : []}
            name="engine"
            cyPrefix="ERS"
            label="Наименование дизельного двигателя"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="ERS"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
