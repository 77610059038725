import React from 'react';
import { Form, Col, Row } from 'antd';
import { ICWFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getCWAppliedMaterials,
  getCWCalculationTypes,
  getCWFullReport,
  getCWVariables,
} from '../../../../api/CopperWork';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const CopperWork: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('CWCalculationTypes', getCWCalculationTypes);
  const appliedMaterials = useQuery('CWAppliedMaterials', () =>
    getCWAppliedMaterials(form.getFieldValue('calculation_type')),
  );
  const variables = useQuery('CWVariables', () => getCWVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: ICWFormValues) => {
    const { calculation_type, applied_material } = values;
    if (variables.data) {
      getFormResult(getCWFullReport, {
        calculatoinTypeId: calculation_type,
        appliedMaterialId: applied_material,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    appliedMaterials.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="CW"
            label="Вид расчета"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={appliedMaterials.data ? appliedMaterials.data : []}
            name="applied_material"
            cyPrefix="CW"
            label="Вид применяемых материалов"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
