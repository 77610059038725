import React from 'react';
import { useQuery } from 'react-query';
import { Form, InputNumber, Row, Col } from 'antd';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getTPPAditionalAirFractions,
  getTPPAshCollectorTypes,
  getTPPBoilerTypes,
  getTPPBurnerDesigns,
  getTPPCalculationTypes,
  getTPPFlueGasRecirculationMethods,
  getTPPFuelTypes,
  getTPPFullReport,
  getTPPGasTemperatures,
  getTPPInstallationTypes,
  getTPPNitricOxidePresenceOptions,
  getTPPOperationModes,
  getTPPSlagRemovalTypes,
  getTPPSulfurPresenceOptions,
  getTPPSuperheaterPresenceOptions,
  getTPPVariables,
} from '../../api/TermalPowerPlants';

import { IMethodologyModule, ITPPFormValues } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const TermalPowerPlants: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('TPPCalculationTypes', getTPPCalculationTypes);
  const installationTypes = useQuery('TPPInstallationTypes', getTPPInstallationTypes);
  const boilerTypes = useQuery('TPPBoilerTypes', getTPPBoilerTypes);
  const fuelTypes = useQuery('TPPFuelTypes', () => getTPPFuelTypes(form.getFieldValue('installation_type'), 1));
  const sulfurPresenceOptions = useQuery('TPPSulfurPresenceOptions', getTPPSulfurPresenceOptions);
  const nitricOxidePresenceOptions = useQuery('TPPNitricOxidePresenceOptions', getTPPNitricOxidePresenceOptions);
  const operationModes = useQuery('TPPOperationModes', getTPPOperationModes);
  const ashCollectorTypes = useQuery('TPPAshCollectorTypes', getTPPAshCollectorTypes);
  const flueGasRecirculationMethods = useQuery('TPPFlueGasRecirculationMethods', getTPPFlueGasRecirculationMethods);
  const burnerDesigns = useQuery('TPPBurnerDesigns', getTPPBurnerDesigns);
  const slagRemovalTypes = useQuery('TPPSlagRemovalTypes', getTPPSlagRemovalTypes);
  const aditionalAirFractions = useQuery('TPPAditionalAirFractions', () => getTPPAditionalAirFractions(1));
  const gasTemperatures = useQuery('TPPGasTemperatures', () => getTPPGasTemperatures(1));
  const superheaterPresenceOptions = useQuery('TPPSuperheaterPresenceOptions', getTPPSuperheaterPresenceOptions);
  const variables = useQuery('TPPVariables', () =>
    getTPPVariables(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('installation_type'),
      form.getFieldValue('fuel_type'),
      form.getFieldValue('sulfur_presence_option'),
    ),
  );

  const onFinish = (values: ITPPFormValues) => {
    const {
      calculation_type,
      installation_type,
      boiler_type,
      fuel_type,
      sulfur_presence_option,
      superheater_presence_option,
      operation_mode,
      ash_сollector_type,
      flue_gas_recirculation_method,
      burner_design,
      slag_removal_type,
      nitric_oxide_presence_option,
      aditional_air_fraction,
      gas_temperature,
    } = values;

    if (variables.data) {
      getFormResult(getTPPFullReport, {
        calculationTypeId: calculation_type,
        installationTypeId: installation_type,
        boilerTypeId: boiler_type || 1,
        fuelTypeId: fuel_type || 1,
        sulfurPresenceOption: sulfur_presence_option || 1,
        superheaterPresenceOptionId: superheater_presence_option || 1,
        operationModeId: operation_mode || 1,
        ashCollectorTypeId: ash_сollector_type || 1,
        flueGasRecirculationMethodId: flue_gas_recirculation_method || 1,
        burnerDesignId: burner_design || 1,
        slagRemovalTypeId: slag_removal_type || 1,
        nitricOxidePresenceOptionId: nitric_oxide_presence_option || 1,
        aditionalAirFractionId: aditional_air_fraction || 1,
        gasTemperatureId: gas_temperature || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  const onChangeInstallationType = () => {
    fuelTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            label="Вид расчета"
            cyPrefix="TPP"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          <FormBaseSelect
            data={installationTypes.data ? installationTypes.data : []}
            name="installation_type"
            label="Вид установки"
            cyPrefix="TPP"
            onChange={onChangeInstallationType}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') === 1 ? (
            <FormBaseSelect
              data={boilerTypes.data ? boilerTypes.data : []}
              name="boiler_type"
              label="Вид котла"
              cyPrefix="TPP"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="TPP"
            label="Вид топлива"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          {form.getFieldValue('fuel_type') === 2 ? (
            <FormBaseSelect
              data={superheaterPresenceOptions.data ? superheaterPresenceOptions.data : []}
              name="superheater_presence_option"
              label="Наличие промпароперегревателя в котлоагрегате"
              cyPrefix="TPP"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={sulfurPresenceOptions.data ? sulfurPresenceOptions.data : []}
            name="sulfur_presence_option"
            label="Наличие серы в топливе"
            cyPrefix="TPP"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={operationModes.data ? operationModes.data : []}
              name="operation_mode"
              label="Режим работы"
              cyPrefix="TPP"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={ashCollectorTypes.data ? ashCollectorTypes.data : []}
              name="ash_сollector_type"
              cyPrefix="TPP"
              label="Вид золоуловителя"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={flueGasRecirculationMethods.data ? flueGasRecirculationMethods.data : []}
              name="flue_gas_recirculation_method"
              cyPrefix="TPP"
              label="Способ рециркуляции дымовых газов"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={burnerDesigns.data ? burnerDesigns.data : []}
              name="burner_design"
              cyPrefix="TPP"
              label="Конструкция горелок"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={slagRemovalTypes.data ? slagRemovalTypes.data : []}
              name="slag_removal_type"
              cyPrefix="TPP"
              label="Вид шлакоудаления"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') === 1 ? (
            <FormBaseSelect
              data={nitricOxidePresenceOptions.data ? nitricOxidePresenceOptions.data : []}
              name="nitric_oxide_presence_option"
              cyPrefix="TPP"
              label="Наличие наличие оксида озота в расчетах"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 1 ? (
            <FormBaseSelect
              data={aditionalAirFractions.data ? aditionalAirFractions.data : []}
              name="aditional_air_fraction"
              cyPrefix="TPP"
              label="Доля воздуха, подоваемого помимо основных горелок, %"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 && form.getFieldValue('installation_type') === 2 ? (
            <FormBaseSelect
              data={gasTemperatures.data ? gasTemperatures.data : []}
              name="gas_temperature"
              cyPrefix="TPP"
              label="Температура газов перед турбиной"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          {variables.data
            ? variables.data.map((variable, index) => (
                <Form.Item key={index} label={variable.name} name={variable.name}>
                  <InputNumber data-cy={`TPP-input-${index}`} min={0} placeholder={'0'} />
                </Form.Item>
              ))
            : ''}
        </Col>
      </Row>
      {children}
    </Form>
  );
};
