import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IWLBOperationType, IWLBPollutant, IWLBReportData, IWLBTankType, IWLBVariable } from './interfaces';

const START_URL = 'ZhDTransportMestoOtstoyaClass';

export const getWLBTankTypes = async (): Promise<IWLBTankType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipCisterny`);
  return data;
};

export const getWLBOperationTypes = async (): Promise<IWLBOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOperacii`);
  return data;
};

export const getWLBPollutants = async (tankTypeId: string | number): Promise<IWLBPollutant[]> => {
  let response;

  switch (tankTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetVidZvSvetlye`);
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetVidZvTemnye`);
      break;
  }

  return response?.data;
};

export const getWLBVariables = async (): Promise<IWLBVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getWLBFullReport = async (reportData: IWLBReportData): Promise<IToolsReport[]> => {
  const { tankTypeId, pollutantId, operationTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: tankTypeId,
      paramName: 'TipCisterny',
    },
    {
      id: pollutantId,
      paramName: 'VidZv',
    },
    {
      id: operationTypeId,
      paramName: 'VidOperacii',
    },
    ...variables,
  ]);
  return data;
};
