import React from 'react';
import { Form, Row, Col } from 'antd';
import { IHEEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getHEEEquipmentTypes,
  getHEEFullReport,
  getHEEOils,
  getHEEVariables,
} from '../../../../api/HeatExchangersEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const HeatExchangersEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const oils = useQuery('HEEOils', getHEEOils);
  const equipmentTypes = useQuery('HEEEquipmentTypes', getHEEEquipmentTypes);
  const variables = useQuery('HEEVariables', getHEEVariables);

  const onFinish = (values: IHEEFormValues) => {
    const { oil, equipment_type } = values;

    if (variables.data) {
      getFormResult(getHEEFullReport, {
        oilId: oil,
        equipmentTypeId: equipment_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={oils.data ? oils.data : []}
            name="oil"
            cyPrefix="HEE"
            label="Нефтепродукт"
            defaultValue={1}
          />
          <FormBaseSelect
            data={equipmentTypes.data ? equipmentTypes.data : []}
            name="equipment_type"
            cyPrefix="HEE"
            label="Вид оборудования"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="HEE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
