import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IDIE2Condition, IDIE2Group, IDIE2Manufacturer, IDIE2Variable } from './interfaces';

const START_URL = 'DieselInstallationsLibrary';

export const getDIE2Manufacturers = async (): Promise<IDIE2Manufacturer[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProizvoditel`);
  return data;
};

export const getDIE2Groups = async (): Promise<IDIE2Group[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGruppa`);
  return data;
};

export const getDIE2Conditions = async (): Promise<IDIE2Condition[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSostoyanie`);
  return data;
};

export const getDIE2Variables = async (): Promise<IDIE2Variable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getDIE2FullReport = async (reportData: {
  manufacturerId: string | number;
  groupId: string | number;
  conditionId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { manufacturerId, groupId, conditionId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: manufacturerId,
      paramName: 'ProizvoditelId',
    },
    {
      id: groupId,
      paramName: 'GruppaId',
    },
    {
      id: conditionId,
      paramName: 'SostoyanieId',
    },
    ...variables,
  ]);
  return data;
};
