import React from 'react';
import { Form, Row, Col } from 'antd';
import { ICRWFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getCRWBusTypes,
  getCRWEngineDisplacements,
  getCRWEngineTypes,
  getCRWFuelTypes,
  getCRWFullReport,
  getCRWInjectionCarburetors,
  getCRWLiftingCapacities,
  getCRWNeutralizerTypes,
  getCRWRoomTypes,
  getCRWSources,
  getCRWVariables,
} from '../../../../api/CarWash';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const CarWash: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const roomTypes = useQuery('CRWRoomTypes', getCRWRoomTypes);
  const sources = useQuery('CRWSources', getCRWSources);
  const engineTypes = useQuery('CRWEngineTypes', getCRWEngineTypes);
  const fuelTypes = useQuery('CRWFuelTypes', () => getCRWFuelTypes(form.getFieldValue('engine_type')));
  const engineDisplacements = useQuery('CRWEngineDisplacements', getCRWEngineDisplacements);
  const injectionCarburetors = useQuery('CRWInjectionCarburetors', getCRWInjectionCarburetors);
  const neutralizerTypes = useQuery('CRWNeutralizerTypes', () => getCRWNeutralizerTypes(form.getFieldValue('source')));
  const liftingCapacities = useQuery('CRWLiftingCapacities', () =>
    getCRWLiftingCapacities(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const busTypes = useQuery('CRWBusTypes', () =>
    getCRWBusTypes(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const variables = useQuery('CRWVariables', () => getCRWVariables(form.getFieldValue('room_type')));

  const onFinish = (values: ICRWFormValues) => {
    const {
      room_type,
      source,
      lifting_capacity,
      engine_type,
      injection_carburetor,
      bus_type,
      neutralizer_type,
      engine_displacement,
      fuel_type,
    } = values;

    if (variables.data) {
      getFormResult(getCRWFullReport, {
        roomTypeId: room_type,
        sourceId: source,
        liftingCapacityId: lifting_capacity || 1,
        engineTypeId: engine_type,
        injectionCarburetorId: injection_carburetor || 1,
        busTypeId: bus_type || 1,
        neutralizerTypeId: neutralizer_type || 1,
        engineDisplacementId: engine_displacement,
        fuelTypeId: fuel_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEngineType = () => {
    fuelTypes.refetch();
    liftingCapacities.refetch();
    busTypes.refetch();
  };

  const onChangeSource = () => {
    neutralizerTypes.refetch();
  };

  const onChangeRoomType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={roomTypes.data ? roomTypes.data : []}
            name="room_type"
            cyPrefix="CRW"
            label="Вид помещения мойки"
            onChange={onChangeRoomType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="CRW"
            label="Передвижные источники"
            onChange={onChangeSource}
            defaultValue={1}
          />
          {form.getFieldValue('source') == 3 || form.getFieldValue('source') == 4 ? (
            <FormBaseSelect
              data={liftingCapacities.data ? liftingCapacities.data : []}
              name="lifting_capacity"
              cyPrefix="CRW"
              label="Грузоподъемность"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={engineTypes.data ? engineTypes.data : []}
            name="engine_type"
            cyPrefix="CRW"
            label="Тип двигателя"
            onChange={onChangeEngineType}
            defaultValue={1}
          />
          {form.getFieldValue('engine_type') == 2 ? (
            <FormBaseSelect
              data={injectionCarburetors.data ? injectionCarburetors.data : []}
              name="injection_carburetor"
              cyPrefix="CRW"
              label="Впрыск или карбюратор"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('source') == 5 || form.getFieldValue('source') == 6 ? (
            <FormBaseSelect
              data={busTypes.data ? busTypes.data : []}
              name="bus_type"
              cyPrefix="CRW"
              label="Класс автобуса"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('engine_type') == 4 ? (
            <FormBaseSelect
              data={neutralizerTypes.data ? neutralizerTypes.data : []}
              name="neutralizer_type"
              cyPrefix="CRW"
              label="Вид сертифицированного каталичисекого нейтрализатора "
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={engineDisplacements.data ? engineDisplacements.data : []}
            name="engine_displacement"
            cyPrefix="CRW"
            label="Рабочий обьем двигателя"
            defaultValue={1}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="CRW"
            label="Вид топлива"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CRW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
