import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IFSEBlowingSpeed,
  IFSECalculationType,
  IFSEDropHeight,
  IFSELiftCapacity,
  IFSELocalCondition,
  IFSEMaterial,
  IFSEPieceSize,
  IFSEReportData,
  IFSERoadType,
  IFSEStockMaterial,
  IFSETransportSpeed,
  IFSEVariable,
  IFSEWindSpeed,
} from './interfaces';

const START_URL = 'Class1';

export const getFSECalculationTypes = async (): Promise<IFSECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getFSEMaterials = async (): Promise<IFSEMaterial[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMaterial`);
  return data;
};

export const getFSEWindSpeeds = async (): Promise<IFSEWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSkorostVetra`);
  return data;
};

export const getFSELocalConditions = async (): Promise<IFSEWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMestnyeUsloviya`);
  return data;
};

export const getFSEHumidities = async (): Promise<IFSELocalCondition[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVlajnost`);
  return data;
};

export const getFSEPieceSizes = async (): Promise<IFSEPieceSize[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRazmerKuska`);
  return data;
};

export const getFSEStockMaterials = async (): Promise<IFSEStockMaterial[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUPsFfakt`);
  return data;
};

export const getFSEDropHeights = async (): Promise<IFSEDropHeight[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVysota`);
  return data;
};

export const getFSELiftCapacities = async (): Promise<IFSELiftCapacity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSredGP`);
  return data;
};

export const getFSERoadTypes = async (): Promise<IFSERoadType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDorogi`);
  return data;
};

export const getFSETransportSpeeds = async (): Promise<IFSETransportSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSredST`);
  return data;
};

export const getFSEBlowingSpeeds = async (): Promise<IFSEBlowingSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetObduv`);
  return data;
};

export const getFSEVariables = async (
  calculationTypeId: string | number,
  coalTypeCount: string | number,
  drillTypeCount: string | number,
): Promise<IFSEVariable[]> => {
  const variablesResponse = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRascheta: calculationTypeId },
  });
  const coalVariablesResponse = await apiClient.get(`${START_URL}/GetKolUglya`, {
    params: { kolUglya: coalTypeCount },
  });
  const drillVariablesResponse = await apiClient.get(`${START_URL}/GetInten`, {
    params: { kolSystem: drillTypeCount },
  });

  if (calculationTypeId == 3) {
    return [...variablesResponse.data, ...coalVariablesResponse.data];
  } else if (calculationTypeId == 6) {
    return [...variablesResponse.data, ...drillVariablesResponse.data];
  } else {
    return [...variablesResponse.data];
  }
};

export const getFSEFullReport = async (reportData: IFSEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    materialId,
    windSpeedId,
    localCondition,
    humidityId,
    pieceSizeId,
    stockMaterialId,
    dropHeightId,
    liftCapacityId,
    roadTypeId,
    transportSpeedId,
    blowingSpeedId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: materialId,
      paramName: 'MaterialId',
    },
    {
      id: windSpeedId,
      paramName: 'SkorostVetraId',
    },
    {
      id: localCondition,
      paramName: 'MestnyeUsloviyaId',
    },
    {
      id: humidityId,
      paramName: 'VlajnostId',
    },
    {
      id: pieceSizeId,
      paramName: 'RazmerKuskaId',
    },
    {
      id: dropHeightId,
      paramName: 'VysotaId',
    },
    {
      id: liftCapacityId,
      paramName: 'SGPId',
    },
    {
      id: transportSpeedId,
      paramName: 'SSTId',
    },
    {
      id: roadTypeId,
      paramName: 'RoadId',
    },
    {
      id: blowingSpeedId,
      paramName: 'ObduvId',
    },
    {
      id: stockMaterialId,
      paramName: 'Materialk3k5Id',
    },
    ...variables,
  ]);
  return data;
};
