import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IWIAmmoniaContent,
  IWICalculationType,
  IWICountry,
  IWIDustCollectionEquipment,
  IWILatexPollutantType,
  IWILatexType,
  IWIMachineMark,
  IWIMachineSubType,
  IWIMachineType,
  IWIOnlyResinType,
  IWIPassportData,
  IWIPlaywoodProductionArea,
  IWIReportData,
  IWIResinMark,
  IWIResinType,
  IWISuctionSystem,
  IWIWoodMaterial,
} from './interfaces';

const START_URL = 'DerevoObrabotkaClass';

export const getWICalculationTypes = async (): Promise<IWICalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getWIPassportData = async (): Promise<IWIPassportData[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPasport`);
  return data;
};

export const getWISuctionSystems = async (): Promise<IWISuctionSystem[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSystemOts`);
  return data;
};

export const getWIMachineTypes = async (): Promise<IWIMachineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidStanka`);
  return data;
};

export const getWIMachineSubTypes = async (machineTypeId: string | number): Promise<IWIMachineSubType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPodvidStanka`, { params: { vidStanka: machineTypeId } });
  return data;
};

export const getWIMachineMarks = async (
  machineTypeId: string | number,
  machineSubTypeId: string | number,
): Promise<IWIMachineMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarkaStanka`, {
    params: { vidStanka: machineTypeId, podvidStanka: machineSubTypeId },
  });
  return data;
};

export const getWITechnologicalProcesses = async (): Promise<IWIMachineMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTehProcShepa`);
  return data;
};

export const getWIWoodMaterials = async (): Promise<IWIWoodMaterial[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDrevesMater`);
  return data;
};

export const getWIResinTypes = async (): Promise<IWIResinType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipSmoly`);
  return data;
};

export const getWIOnlyResinTypes = async (resinTypeId: string | number): Promise<IWIOnlyResinType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSmoly`, { params: { vidSmoly: resinTypeId } });
  return data;
};

export const getWIResinMarks = async (
  resinTypeId: string | number,
  countryId: string | number,
): Promise<IWIResinMark[]> => {
  let responce;

  if (countryId == 0) {
    responce = await apiClient.get(`${START_URL}/GetMarkaSmoly`, { params: { vidSmoly: resinTypeId } });
  } else {
    responce = await apiClient.get(`${START_URL}/GetMarkaSmolyZarub`, { params: { strana: countryId } });
  }

  return responce?.data;
};

export const getWIAmmoniaContents = async (): Promise<IWIAmmoniaContent[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSoderjAmmiak`);
  return data;
};

export const getWIPlaywoodProductionAreas = async (): Promise<IWIPlaywoodProductionArea[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUchastok`);
  return data;
};

export const getWIDustCollectionEquipments = async (): Promise<IWIDustCollectionEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipPyleulOborud`);
  return data;
};

export const getWICountries = async (): Promise<IWICountry[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetStrana`);
  return data;
};

export const getWILatexTypes = async (): Promise<IWILatexType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidLatex`);
  return data;
};

export const getWILatexPollutantTypes = async (): Promise<IWILatexPollutantType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZvLatex`);
  return data;
};

export const getWIVariables = async (
  calculationTypeId: string | number,
  suctionSystemId: string | number,
  passportDataId: string | number,
  resinTypeId: string | number,
  onlyResinTypeId: string | number,
  countryId: string | number,
  resinMark: string | number,
  playwoodProductionAreaId: string | number,
): Promise<IWIPlaywoodProductionArea[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidRascheta: calculationTypeId,
      pasport: passportDataId,
      systemOts: suctionSystemId,
      tipSmoly: resinTypeId,
      vidSmoly: onlyResinTypeId,
      strana: countryId,
      dBMarkaSmoly: resinMark,
      uchastok: playwoodProductionAreaId,
    },
  });
  return data;
};

export const getWIFullReport = async (reportData: IWIReportData): Promise<IToolsReport[]> => {
  const {
    latexTypeId,
    latexPollutantTypeId,
    calculationTypeId,
    suctionSystemId,
    passportDataId,
    resinTypeId,
    onlyResinTypeId,
    countryId,
    resinMark,
    playwoodProductionAreaId,
    machineTypeId,
    machineSubTypeId,
    machineMarkId,
    technologicalProcessId,
    woodMaterialId,
    ammoniaContentId,
    dustCollectionEquipmentId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: latexTypeId,
      paramName: 'DBZvLatex',
    },
    {
      id: latexPollutantTypeId,
      paramName: 'VidLatex',
    },
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: suctionSystemId,
      paramName: 'SystemOts',
    },
    {
      id: passportDataId,
      paramName: 'Pasport',
    },
    {
      id: dustCollectionEquipmentId,
      paramName: 'DBPyleulOborud',
    },
    {
      id: playwoodProductionAreaId,
      paramName: 'Uchastok',
    },
    {
      id: resinTypeId,
      paramName: 'TipSmoly',
    },
    {
      id: onlyResinTypeId,
      paramName: 'VidSmoly',
    },
    {
      id: countryId,
      paramName: 'Strana',
    },
    {
      id: resinMark,
      paramName: 'DBMarkaSmoly',
    },
    {
      id: ammoniaContentId,
      paramName: 'SoderjAmmiak',
    },
    {
      id: machineTypeId,
      paramName: 'VidStanka',
    },
    {
      id: machineSubTypeId,
      paramName: 'PodvidStanka',
    },
    {
      id: machineMarkId,
      paramName: 'MarkaStanka',
    },
    {
      id: technologicalProcessId,
      paramName: 'VidTehProcShepa',
    },
    {
      id: woodMaterialId,
      paramName: 'VidDrevesMater',
    },
    ...variables,
  ]);
  return data;
};
