import { AxiosError } from 'axios';
import { downloadFileFromResponse, openNotificationWithIcon } from '.';

import { getFileReport } from '../api/Tools';
import { IToolsReport } from '../api/Tools/interface';

import { $fileNameState } from '../modules/Methodologies/models/fileNameState';
import { setPollutantsTableData } from '../modules/Tables/models/pollutantsTableState';

export function getFormResult<IReportFunctionArgs>(
  reportFunction: (args: IReportFunctionArgs) => Promise<IToolsReport[]>,
  reportData: IReportFunctionArgs,
): void {
  const { fileName } = $fileNameState.getState();

  reportFunction(reportData)
    .then((data) => {
      getFileReport(data, fileName).then((data) => {
        downloadFileFromResponse(data, fileName, 'docx');
      });
      setPollutantsTableData(data.slice(0, -1));
    })
    .catch((data: AxiosError) => {
      console.log(data);
      openNotificationWithIcon('error', 'Ошибка', data.response?.data.message);
    });
}
