import React from 'react';
import { Form, Col, Row } from 'antd';
import { IGSTEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getGSTECalculationTypes,
  getGSTEClimaticZones,
  getGSTEFuelTypes,
  getGSTEFullReport,
  getGSTETankDesigns,
  getGSTEVariables,
} from '../../../../api/GasStationTanksEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const GasStationTanksEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('GSTECalculationTypes', getGSTECalculationTypes);
  const fuelTypes = useQuery('GSTEFuelTypes', getGSTEFuelTypes);
  const climaticZones = useQuery('GSTEClimaticZones', getGSTEClimaticZones);
  const tankDesigns = useQuery('GSTETankDesigns', () => getGSTETankDesigns(form.getFieldValue('calculation_type')));
  const variables = useQuery('GSTEVariables', () => getGSTEVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IGSTEFormValues) => {
    const { calculation_type, fuel_type, climatic_zone, tank_design } = values;

    if (variables.data) {
      getFormResult(getGSTEFullReport, {
        calculatonTypeId: calculation_type,
        fuelTypeId: fuel_type,
        climaticZoneId: climatic_zone,
        tankDesingId: tank_design,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    tankDesigns.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="GSTE"
            label="Вид расчета"
            onChange={onChangeCalculationType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="GSTE"
            label="Вид топлива"
            defaultValue={1}
          />
          <FormBaseSelect
            data={climaticZones.data ? climaticZones.data : []}
            name="climatic_zone"
            cyPrefix="GSTE"
            label="Климатическая зона"
            defaultValue={1}
          />
          <FormBaseSelect
            data={tankDesigns.data ? tankDesigns.data : []}
            name="tank_design"
            cyPrefix="GSTE"
            label="Тип конструкции резервуаров"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="GSTE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
