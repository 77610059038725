import React from 'react';
import { Form, Row, Col } from 'antd';
import { ICAWDFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import {
  getCAWDAnnualOutputs,
  getCAWDAshCarryovers,
  getCAWDAshCharacteristics,
  getCAWDCalculationTypes,
  getCAWDCollectorPresences,
  getCAWDFields,
  getCAWDFuelMarks,
  getCAWDFuelTypes,
  getCAWDFullReport,
  getCAWDFuranceBoilerTypes,
  getCAWDVariables,
} from '../../api/CalculationAshWasteDisposal';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const CalculationAshWasteDisposal: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationType = useQuery('CAWDCalculationTypes', getCAWDCalculationTypes);
  const field = useQuery('CAWDFields', getCAWDFields);
  const fuelMarks = useQuery('CAWDFuelMarks', () => getCAWDFuelMarks(form.getFieldValue('field')));
  const annualOutputs = useQuery('CAWDAnnualOutputs', getCAWDAnnualOutputs);
  const fuelTypes = useQuery('CAWDFuelTypes', () => getCAWDFuelTypes(form.getFieldValue('furance_boiler_type')));
  const collectorPresences = useQuery('CAWDCollectorPresences', getCAWDCollectorPresences);
  const ashCharacteristics = useQuery('CAWDAshCharacteristics', getCAWDAshCharacteristics);
  const furanceBoilerTypes = useQuery('CAWDFuranceBoilerTypes', getCAWDFuranceBoilerTypes);
  const ashCarryovers = useQuery('CAWDAshCarryovers', getCAWDAshCarryovers);
  const variables = useQuery('CAWDVariables', () =>
    getCAWDVariables(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('ash_presence'),
      form.getFieldValue('ash_carryover'),
    ),
  );

  const onFinish = (values: ICAWDFormValues) => {
    const {
      field,
      fuel_mark,
      fuel_type,
      furance_boiler_type,
      annual_output,
      ash_carryover,
      ash_characteristic,
      calculation_type,
      collector_presence,
    } = values;

    if (variables.data) {
      getFormResult(getCAWDFullReport, {
        ashCharacteristicId: ash_characteristic || 1,
        calculationTypeId: calculation_type,
        fieldsId: field || 1,
        fuelMarkId: fuel_mark || 1,
        fuelTypeId: fuel_type || 1,
        annualOutputId: annual_output || 1,
        collectorPresenceId: collector_presence || 1,
        ashCarryoverId: ash_carryover || 1,
        furanceBoilerTypeId: furance_boiler_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  const onChangeField = () => {
    fuelMarks.refetch();
  };

  const onChangeFuranceBoilerType = () => {
    fuelTypes.refetch();
  };

  const onChangeAshPresence = () => {
    variables.refetch();
  };

  const onChangeAshCarryover = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationType.data ? calculationType.data : []}
            name="calculation_type"
            cyPrefix="CAWD"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalculationType}
          />
          {form.getFieldValue('calculation_type') === 2 ? (
            <FormBaseSelect
              data={annualOutputs.data ? annualOutputs.data : []}
              name="annual_output"
              cyPrefix="CAWD"
              label="Годовой выход золошлаков материала, *10^3 т"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 3 ? (
            <>
              <FormBaseSelect
                data={ashCharacteristics.data ? ashCharacteristics.data : []}
                name="ash_characteristic"
                cyPrefix="CAWD"
                label="Наличие характеристик золы и шлака"
                defaultValue={1}
              />
              <FormBaseSelect
                data={field.data ? field.data : []}
                name="field"
                cyPrefix="CAWD"
                label="Месторождение"
                defaultValue={1}
                onChange={onChangeField}
              />
              <FormBaseSelect
                data={fuelMarks.data ? fuelMarks.data : []}
                name="fuel_mark"
                cyPrefix="CAWD"
                label="Марка"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 3 && form.getFieldValue('ash_characteristic') === 2 ? (
            <>
              <FormBaseSelect
                data={ashCarryovers.data ? ashCarryovers.data : []}
                name="ash_carryover"
                cyPrefix="CAWD"
                label="Доля уноса золы из топки, а"
                defaultValue={1}
                onChange={onChangeAshCarryover}
              />
              <FormBaseSelect
                data={furanceBoilerTypes.data ? furanceBoilerTypes.data : []}
                name="furance_boiler_type"
                cyPrefix="CAWD"
                label="Вид топки и котлов"
                defaultValue={1}
                onChange={onChangeFuranceBoilerType}
              />
              <FormBaseSelect
                data={fuelTypes.data ? fuelTypes.data : []}
                name="fuel_type"
                cyPrefix="CAWD"
                label="Топливо"
                defaultValue={1}
              />
              <FormBaseSelect
                data={collectorPresences.data ? collectorPresences.data : []}
                name="collector_presence"
                cyPrefix="CAWD"
                label="Наличие золоуловителя"
                defaultValue={1}
                onChange={onChangeAshPresence}
              />
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CAWD"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
