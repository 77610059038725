import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IMMPAvailabilityDustCollectionEquipment,
  IMMPGravitySubsidenceCoefficient,
  IMMPImpuritySuctionRatio,
  IMMPProcessinType,
  IMMPReportData,
  IMMPSpecificEmissionEquipment,
  IMMPSpecificOilEmissionEquipment,
  IMMPTechnologicalProcess,
  IMMPVariable,
} from './interfaces';

const START_URL = 'MehobrabotkaMetallov';

export const getMMPProcessingTypes = async (): Promise<IMMPProcessinType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidMehObrabotki`);
  return data;
};

export const getMMPAvailabilityDustCollectionEquipments = async (): Promise<
  IMMPAvailabilityDustCollectionEquipment[]
> => {
  const { data } = await apiClient.get(`${START_URL}/GetNalichiePyleulOborud`);
  return data;
};

export const getMMPGravitySubsidenceCoefficients = async (): Promise<IMMPGravitySubsidenceCoefficient[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffGravitacOsed`);
  return data;
};

export const getMMPTechnologicalProcessTypes = async (): Promise<IMMPTechnologicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTehnolProcessa`);
  return data;
};

export const getMMPSpecificEmissionEquipments = async (
  technologicalProcessTypeId: string | number,
  technologicalOperationTypeId: string | number = 0,
): Promise<IMMPSpecificEmissionEquipment[]> => {
  let response;

  switch (technologicalProcessTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetUdelnoeVydelOsnovnym`);
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetUdelnoeVydelGalvanich`, {
        params: { vidTehnolOperaciiGalvanich: technologicalOperationTypeId },
      });
      break;

    case 3:
      response = await apiClient.get(`${START_URL}/GetUdelnoeVydelAbrazivn`);
      break;

    case 4:
      response = await apiClient.get(`${START_URL}/GetUdelnoeVydelChugun`, {
        params: { vidTehnolOperiiChugun: technologicalOperationTypeId },
      });
      break;

    case 5:
      response = await apiClient.get(`${START_URL}/GetUdelnoeVydelCvetn`, {
        params: { vidTehnolOperaciiCvetn: technologicalOperationTypeId },
      });
      break;

    default:
      response = { data: [] };
      break;
  }

  return response?.data;
};

export const getMMPSpecificOilEmissionEquipments = async (): Promise<IMMPSpecificOilEmissionEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUdelnoeVydelAerozol`);
  return data;
};

export const getMMPImpuritySuctionRatios = async (): Promise<IMMPImpuritySuctionRatio[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffEffectivn`);
  return data;
};

export const getMMPTechnologicalOperationTypes = async (
  technologicalProcessTypeId: string | number,
): Promise<IMMPProcessinType[]> => {
  let response;

  switch (technologicalProcessTypeId) {
    case 2:
      response = await apiClient.get(`${START_URL}/GetVidTehnolOperaciiGalvanich`);
      break;

    case 4:
      response = await apiClient.get(`${START_URL}/GetVidTehnolOperaciiChugun`);
      break;

    case 5:
      response = await apiClient.get(`${START_URL}/GetVidTehnolOperaciiCvetn`);
      break;

    default:
      response = { data: [] };
      break;
  }

  return response?.data;
};

export const getMMPVariables = async (
  availabilityDustCollectionEquipmentId: string | number,
  impuritySuctionRatioId: string | number,
  processingTypeId: string | number,
): Promise<IMMPVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      nalichiePyleulOborud: availabilityDustCollectionEquipmentId,
      koeffEffectivn: impuritySuctionRatioId,
      vidMehObrabotki: processingTypeId,
    },
  });

  return data;
};

export const getMMPFullReport = async (reportData: IMMPReportData): Promise<IToolsReport[]> => {
  const {
    processingTypeId,
    availabilityDustCollectionEquipmentId,
    gravitySubsidenceCoefficientId,
    technologicalOperationTypeId,
    technologicalProcessTypeId,
    impuritySuctionRatioId,
    specificEmissionEquipmentId,
    specificOilEmissionEquipmentId,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: processingTypeId,
      paramName: 'VidMehObrabotki',
    },
    {
      id: availabilityDustCollectionEquipmentId,
      paramName: 'NalichiePyleulOborud',
    },
    {
      id: gravitySubsidenceCoefficientId,
      paramName: 'KoeffGravitacOsed',
    },
    {
      id: technologicalProcessTypeId,
      paramName: 'VidTehnolProcessa',
    },
    {
      id: impuritySuctionRatioId,
      paramName: 'KoeffEffectivn',
    },
    {
      id: technologicalOperationTypeId,
      paramName: 'VidTehnolOperacii',
    },
    {
      id: specificEmissionEquipmentId,
      paramName: 'DBUdelVydel',
    },
    {
      id: specificEmissionEquipmentId,
      paramName: 'DBUdelVydelChugun',
    },
    {
      id: specificEmissionEquipmentId,
      paramName: 'DBUdelVydelCvetn',
    },
    {
      id: specificOilEmissionEquipmentId,
      paramName: 'DBUdelVydelAerozol',
    },
    ...variables,
  ]);
  return data;
};
