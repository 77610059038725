import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  ITEEECalculationType,
  ITEEECleaningType,
  ITEEEEquipmentType,
  ITEEEInstallation,
  ITEEEMeltingType,
  ITEEEMetalType,
  ITEEEProductionType,
  ITEEETechnicalProcess,
  ITEEETreatmentPlant,
  ITEEEVariable,
} from './interfaces';

const START_URL = 'MachineBuildingClass';

export const getTEEECalculationTypes = async (): Promise<ITEEECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetCalcType`);
  return data;
};

export const getTEEEMeltingTypes = async (): Promise<ITEEEMeltingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPlavkaType`);
  return data;
};

export const getTEEEMetalTypes = async (): Promise<ITEEEMetalType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMetalType`);
  return data;
};

export const getTEEETreatmentPlants = async (): Promise<ITEEETreatmentPlant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetCleanConstrType`);
  return data;
};

export const getTEEEProductionTypes = async (): Promise<ITEEEProductionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProductionType`);
  return data;
};

export const getTEEEInstallations = async (productionTypeId: string | number): Promise<ITEEEInstallation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDataPerShohtForm`, {
    params: { prodTypeId: productionTypeId },
  });
  return data;
};

export const getTEEEEquipmentTypes = async (): Promise<ITEEEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetEquipmentType`);
  return data;
};

export const getTEEECleaningTypes = async (): Promise<ITEEECleaningType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetCleanLitType`);
  return data;
};

export const getTEEETechnicalProcesses = async (): Promise<ITEEETechnicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTehProc`);
  return data;
};

export const getTEEEVariables = async (calculationTypeId: string | number): Promise<ITEEEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { calcTypeId: calculationTypeId } });
  return data.filter((element: ITEEEVariable) => element.name !== 'Данные из базы');
};

export const getTEEEFullReport = async (reportData: {
  calculationTypeId: string | number;
  equipmentTypeId: string | number;
  metalTypeId: string | number;
  meltingTypeId: string | number;
  productionTypeId: string | number;
  cleaningTypeId: string | number;
  technicalProcessId: string | number;
  installationId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    cleaningTypeId,
    equipmentTypeId,
    meltingTypeId,
    metalTypeId,
    productionTypeId,
    technicalProcessId,
    installationId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'calcTypeId',
    },
    {
      id: installationId,
      paramName: 'dataBaseId',
    },
    {
      id: equipmentTypeId,
      paramName: 'equipmId',
    },
    {
      id: metalTypeId,
      paramName: 'metalTypeId',
    },
    {
      id: meltingTypeId,
      paramName: 'plavkaTypeId',
    },
    {
      id: productionTypeId,
      paramName: 'prodTypeId',
    },
    {
      id: cleaningTypeId,
      paramName: 'cleanTypeId',
    },
    {
      id: technicalProcessId,
      paramName: 'tehProcId',
    },
    ...variables,
  ]);
  return data;
};
