import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { getTPEFullReport, getTPESources, getTPEVariables } from '../../api/TabaccoProductionEmissions';

import { IMethodologyModule, ITPEFormValues } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const TobaccoProductionEmission: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const sources = useQuery('TPESources', getTPESources);
  const variables = useQuery('TPEVariables', getTPEVariables);

  const onFinish = (values: ITPEFormValues) => {
    const { source } = values;

    if (variables.data) {
      getFormResult(getTPEFullReport, {
        sourceId: source,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="TPE"
            label="Наименование источника выделения"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TPE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
