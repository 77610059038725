import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col, Checkbox, Select } from 'antd';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getCHPEClimaticZones,
  getCHPEFullReport,
  getCHPEOilGroups,
  getCHPEOilTypes,
  getCHPEOperationModes,
  getCHPETankDesigns,
  getCHPEVaporConcentrations,
  getCHPEVariables,
} from '../../../../api/CHPEmissions';

import { ICHPEFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const CHPEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const operationModes = useQuery('CHPEOperationModes', getCHPEOperationModes);
  const tankDesings = useQuery('CHPETankDesings', () => getCHPETankDesigns(form.getFieldValue('operation_mode')));
  const oilGroups = useQuery('CHPEOilGroups', getCHPEOilGroups);
  const oilTypes = useQuery('CHPEOilTypes', getCHPEOilTypes);
  const climaticZones = useQuery('CHPEClimaticZones', getCHPEClimaticZones);
  const vaporConcentrations = useQuery('CHPEVaporConcentration', () =>
    getCHPEVaporConcentrations(form.getFieldValue('climatic_zone')),
  );
  const variables = useQuery('CHPEVariables', () => getCHPEVariables(!!form.getFieldValue('gas_piping_tanks')));

  const onFinish = (value: ICHPEFormValues) => {
    const { oil_group, oil_type, operation_mode, climatic_zone, tank_desing, vapor_concentration, gas_piping_tanks } =
      value;

    if (variables.data) {
      getFormResult(getCHPEFullReport, {
        operationModeId: operation_mode,
        oilGroupId: oil_group,
        oilTypeId: oil_type,
        climaticZoneId: climatic_zone,
        vaporConcentrationId: vapor_concentration,
        tankDesignId: tank_desing,
        gasPiping: gas_piping_tanks || 'false',
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeOperationMode = () => {
    tankDesings.refetch();
  };

  const onChangeClimaticZone = () => {
    vaporConcentrations.refetch();
  };

  const onChangeGasPipingTanks = () => {
    variables.refetch();
    console.log(form.getFieldValue('gas_piping_tanks'));
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={operationModes.data ? operationModes.data : []}
            name="operation_mode"
            cyPrefix="CHPE"
            label="Режим экплуатации"
            defaultValue={1}
            onChange={onChangeOperationMode}
          />
          <FormBaseSelect
            data={tankDesings.data ? tankDesings.data : []}
            name="tank_desing"
            cyPrefix="CHPE"
            label="Конструкция резервуара"
            defaultValue={1}
          />
          <Form.Item name="gas_piping_tanks" label="Газовая обвязка резервуара" valuePropName="checked">
            <Checkbox data-cy="CHPE-gas_piping_tanks-checkbox" onChange={onChangeGasPipingTanks} />
          </Form.Item>
          <FormBaseSelect
            data={oilGroups.data ? oilGroups.data : []}
            name="oil_group"
            cyPrefix="CHPE"
            label="Группа нефтепродукта"
            defaultValue={1}
          />
          <FormBaseSelect
            data={oilTypes.data ? oilTypes.data : []}
            name="oil_type"
            cyPrefix="CHPE"
            label="Нефтепродукт"
            defaultValue={1}
          />
          <FormBaseSelect
            data={climaticZones.data ? climaticZones.data : []}
            name="climatic_zone"
            cyPrefix="CHPE"
            label="Климатическая зона"
            defaultValue={1}
            onChange={onChangeClimaticZone}
          />
          <Form.Item name="vapor_concentration" label="Концентрация паров" initialValue={1}>
            <Select data-cy="CHPE-vapor_concentration-select">
              {vaporConcentrations.data
                ? vaporConcentrations.data.map((concentration) => (
                    <Option
                      data-cy={`CHPE-vapor_concentration-option-${concentration.id}`}
                      key={concentration.id}
                      value={concentration.id}
                    >
                      {`${concentration.nefteprod} - C1, г/м3 = ${concentration.c1}; Угоз, г/т = ${concentration.yoz};  Увл, г/т = ${concentration.yvl}; Кнп = ${concentration.knp}`}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CHPE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
