import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { ISIEEquipmentType, ISIEVariable } from './interfaces';

const START_URL = 'PishepromSaharClass';

export const getSIEEquipmentTypes = async (): Promise<ISIEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOborudovanie`);
  return data;
};

export const getSIEVariables = async (): Promise<ISIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getSIEFullReport = async (reportData: {
  equipmentTypeId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { equipmentTypeId, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: equipmentTypeId,
      paramName: 'DBOborudovanie',
    },
    ...variables,
  ]);
  return data;
};
