import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getCTCountOperationModes,
  getCTFilterTypes,
  getCTFullReport,
  getCTLifetimes,
  getCTMarks,
  getCTOperationModes,
  getCTPollutants,
  getCTVariables,
} from '../../../../api/CareerTransport';

import { ICTFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const CareerTransport: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();
  const [countOperationMode, setCountOperationMode] = React.useState(1);

  const operationModes = useQuery('CTOperationModes', getCTOperationModes);
  const marks = useQuery('CTMarks', getCTMarks);
  const countOperationModes = useQuery('CTCountOperationModes', getCTCountOperationModes);
  const lifetimes = useQuery('CTLifetimes', getCTLifetimes);
  const pollutants = useQuery('CTPollutants', getCTPollutants);
  const filterTypes = useQuery('CTFilterType', getCTFilterTypes);
  const variables = useQuery('CTVariables', getCTVariables);

  const onFinish = (values: ICTFormValues) => {
    const {
      mark,
      count_operation_mode,
      operation_mode1,
      operation_mode2,
      operation_mode3,
      filter_type,
      lifetime,
      pollutant,
    } = values;

    if (variables.data) {
      getFormResult(getCTFullReport, {
        markId: mark,
        countOperationMode: count_operation_mode,
        operationMode1: operation_mode1,
        operationMode2: operation_mode2 || 1,
        operationMode3: operation_mode3 || 1,
        filterTypeId: filter_type,
        lifetimeId: lifetime,
        pollutantId: pollutant,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCountOperationMode = () => {
    setCountOperationMode(form.getFieldValue('count_operation_mode'));
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={marks.data ? marks.data : []}
            name="mark"
            cyPrefix="CT"
            label="Марка автомобиля и двигателя, грузоподъемность"
            defaultValue={1}
          />
          <FormBaseSelect
            data={countOperationModes.data ? countOperationModes.data : []}
            name="count_operation_mode"
            cyPrefix="CT"
            label="Число режимов работы двигателя"
            defaultValue={1}
            onChange={onChangeCountOperationMode}
          />
          <FormBaseSelect
            data={operationModes.data ? operationModes.data : []}
            name="operation_mode1"
            cyPrefix="CT"
            label="Режим работы двигателя 1"
            defaultValue={1}
          />
          {countOperationMode >= 2 ? (
            <FormBaseSelect
              data={operationModes.data ? operationModes.data : []}
              name="operation_mode2"
              cyPrefix="CT"
              label="Режим работы двигателя 2"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {countOperationMode === 3 ? (
            <FormBaseSelect
              data={operationModes.data ? operationModes.data : []}
              name="operation_mode3"
              cyPrefix="CT"
              label="Режим работы двигателя 3"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={pollutants.data ? pollutants.data : []}
            name="pollutant"
            cyPrefix="CT"
            label="Вид ЗВ"
            defaultValue={1}
          />
          <FormBaseSelect
            data={lifetimes.data ? lifetimes.data : []}
            name="lifetime"
            cyPrefix="CT"
            label="Срок эксплуатации"
            defaultValue={1}
          />
          <FormBaseSelect
            data={filterTypes.data ? filterTypes.data : []}
            name="filter_type"
            cyPrefix="CT"
            label="Вид фильтра"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CT"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
