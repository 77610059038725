import React from 'react';
import { Form, Col, Row } from 'antd';
import { IEPIFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { getEPIFullReport, getEPIProductionStages, getEPIVariables } from '../../api/EmissionsPastaIndustry';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const EmissionsPastaIndustry: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const productionStages = useQuery('EPIProductionStages', getEPIProductionStages);
  const variables = useQuery('EPIVariables', getEPIVariables);

  const onFinish = (values: IEPIFormValues) => {
    const { production_stage } = values;

    if (variables.data) {
      getFormResult(getEPIFullReport, {
        productionStageId: production_stage,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={productionStages.data ? productionStages.data : []}
            name="production_stage"
            cyPrefix="EPI"
            label="Этапы производства хлебобулочных изделий"
            defaultValue={0}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="EPI"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
