import React from 'react';
import { Form, Select } from 'antd';

import { IFormBaseSelect } from './interfaces';

const { Option } = Select;

export const FormBaseSelect: React.FC<IFormBaseSelect> = ({
  data,
  onChange,
  name,
  cyPrefix,
  defaultValue,
  label,
}: IFormBaseSelect) => {
  return (
    <Form.Item name={name} label={label} initialValue={defaultValue}>
      <Select data-cy={`${cyPrefix}-${name}-select`} onChange={onChange} defaultValue={defaultValue}>
        {data.map((element) => (
          <Option
            data-cy={`${cyPrefix}-${name}-option-${element.id}`}
            key={element.id + element.name}
            value={element.id}
          >
            {element.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
