import React from 'react';
import { Table } from 'antd';

import { IPollutantsTable } from '../interfaces';
import { IToolsReport } from '../../api/Tools/interface';

const { Column } = Table;

export const PollutantsTableTemplate: React.FC<IPollutantsTable> = (props: IPollutantsTable) => {
  return (
    <Table data-cy="pollutant-table" style={{ marginTop: '30px' }} dataSource={props.data}>
      <Column title="Код ЗВ" dataIndex="code" key="pollutant_code" />
      <Column title="Наименование ЗВ" dataIndex="name" key="pollutant_name" />
      <Column
        title="Максимальный разовый выброс, г/с"
        dataIndex="max"
        key="max"
        render={(values, record: IToolsReport) => <p>{record.values[0].value}</p>}
      />
      <Column
        title="Валовый выброс, т/год"
        dataIndex="gross"
        key="gross"
        render={(values, record: IToolsReport) => <p>{record.values.length < 2 ? 0 : record.values[1].value}</p>}
      />
    </Table>
  );
};
