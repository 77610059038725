import React from 'react';
import { Form, Row, Col } from 'antd';
import { ICNIEFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import { getCNIEFullReport, getCNIESources, getCNIEVatriables } from '../../api/CanningIndustryEmissions';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const CanningIndustryEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const sources = useQuery('ICNIESources', getCNIESources);
  const variables = useQuery('ICNIEVariables', getCNIEVatriables);

  const onFinish = (values: ICNIEFormValues) => {
    const { source } = values;

    if (variables.data) {
      getFormResult(getCNIEFullReport, {
        sourceId: source,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="CNIE"
            label="Наименование источника выделения"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CNIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
