import { createStore, createApi } from 'effector';

interface IFileNameStore {
  fileName: string;
}

export const $fileNameState = createStore<IFileNameStore>({ fileName: '' });

export const { setFileName } = createApi($fileNameState, {
  setFileName: (state: IFileNameStore, newFileName: string) => ({ ...state, fileName: newFileName }),
});
