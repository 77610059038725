import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IOFIEPollutant, IOFIEProductionType, IOFIESource, IOFIEVariable } from './interfaces';

const START_URL = 'PishepromMaslozhirClass';

export const getOFIEProductionTypes = async (): Promise<IOFIEProductionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProizvodstvo`);
  return data;
};

export const getOFIESources = async (productionTypeId: string | number): Promise<IOFIESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`, { params: { proizvodstvo: productionTypeId } });
  return data;
};

export const getOFIEPollutants = async (
  productionTypeId: string | number,
  sourceId: string | number,
): Promise<IOFIEPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVeshestvo`, {
    params: { proizvodstvo: productionTypeId, istochnik: sourceId },
  });
  return data;
};

export const getOFIEVariables = async (): Promise<IOFIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getOFIEFullReport = async (reportData: {
  productionTypeId: string | number;
  sourceId: string | number;
  pollutantId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { productionTypeId, sourceId, pollutantId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: productionTypeId,
      paramName: 'Proizvodstvo',
    },
    {
      id: sourceId,
      paramName: 'Istochnik',
    },
    {
      id: pollutantId,
      paramName: 'DBVeshestvo',
    },
    ...variables,
  ]);
  return data;
};
