import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IBIEProductionType, IBIESource, IBIEVariable } from './interfaces';

const START_URL = 'PishepromPivoClass';

export const getBIEProductiontypes = async (): Promise<IBIEProductionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProizvodstvo`);
  return data;
};

export const getBIESources = async (productionTypeId: string | number): Promise<IBIESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`, {
    params: { proizvodstvo: productionTypeId },
  });
  return data;
};

export const getBIEVariables = async (): Promise<IBIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getBIEFullReport = async (reportData: {
  productionTypeId: string | number;
  sourceId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { productionTypeId, sourceId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: productionTypeId,
      paramName: 'Proizvodstvo',
    },
    {
      id: sourceId,
      paramName: 'DBIstochnik',
    },
    ...variables,
  ]);
  return data;
};
