import React from 'react';
import { Form, Col, Row, InputNumber } from 'antd';
import { IMethodologyModule, IWDEFormValues } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getWDEBreeds,
  getWDECoefficientK1,
  getWDECoefficientK2,
  getWDEDecommissionings,
  getWDEDeflations,
  getWDEDustEmissions,
  getWDEEquipmentMarks,
  getWDEEquipmentNames,
  getWDEFullReport,
  getWDELoadingTransportTypes,
  getWDEStrengths,
  getWDESurfaceWindSpeeds,
  getWDETransportTypes,
  getWDEVariables,
} from '../../../../api/WasteDumbsEmissions';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const WasteDumbsEmissions: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const loadingTransportTypes = useQuery('WDELoadingTransportTypes', getWDELoadingTransportTypes);
  const transportTypes = useQuery('WDETransportTypes', () =>
    getWDETransportTypes(form.getFieldValue('loading_transport_type')),
  );
  const equipmentNames = useQuery('WDEEquipmentNames', getWDEEquipmentNames);
  const equipmentMarks = useQuery('WDEEquipmentMarks', () =>
    getWDEEquipmentMarks(form.getFieldValue('equipment_name')),
  );
  const breeds = useQuery('WDEBreeds', () => getWDEBreeds(form.getFieldValue('equipment_name')));
  const strengths = useQuery('WDEStrengths', () =>
    getWDEStrengths(form.getFieldValue('equipment_name'), form.getFieldValue('breed')),
  );
  const coefficientK1 = useQuery('WDECoefficientK1', getWDECoefficientK1);
  const coefficientK2 = useQuery('WDECoefficientK2', getWDECoefficientK2);
  const dustEmissions = useQuery('WDEDustEmissions', () => getWDEDustEmissions(form.getFieldValue('breed')));
  const surfaceWindSpeeds = useQuery('WDESurfaceWindSpeeds', getWDESurfaceWindSpeeds);
  const deflations = useQuery('WDEDeflations', () => getWDEDeflations(form.getFieldValue('surface_wind_speed')));
  const decommissionings = useQuery('WDEDecommissionings', () =>
    getWDEDecommissionings(form.getFieldValue('surface_wind_speed')),
  );
  const variables = useQuery('WDEVariables', () =>
    getWDEVariables(
      form.getFieldValue('transport_type'),
      form.getFieldValue('tier'),
      form.getFieldValue('equipment_name'),
      form.getFieldValue('equipment_mark'),
    ),
  );

  const onFinish = (values: IWDEFormValues) => {
    const {
      loading_transport_type,
      transport_type,
      equipment_name,
      equipment_mark,
      breed,
      strength,
      dust_emission,
      k1,
      k2,
      surface_wind_speed,
      deflaiton,
      decommissioning,
    } = values;

    if (variables.data) {
      getFormResult(getWDEFullReport, {
        loadingTransportTypeId: loading_transport_type,
        transportTypeId: transport_type,
        equipmentNameId: equipment_name,
        equipmentMarkId: equipment_mark,
        breedId: breed,
        strengthId: strength,
        coefficientK1Id: k1,
        coefficientK2Id: k2,
        dustEmissionId: dust_emission,
        surfaceWindSpeedId: surface_wind_speed,
        deflationId: deflaiton,
        decommissioningId: decommissioning,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeLoadingTransportType = () => {
    transportTypes.refetch();
  };

  const onChangeEquipmentName = () => {
    equipmentMarks.refetch();
    breeds.refetch();
    strengths.refetch();
  };

  const onChangeBreeed = () => {
    strengths.refetch();
    dustEmissions.refetch();
  };

  const onChangeSurfaceWindSpeed = () => {
    deflations.refetch();
    decommissionings.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={loadingTransportTypes.data ? loadingTransportTypes.data : []}
            name="loading_transport_type"
            cyPrefix="WDE"
            label="Вид транспорта, используемого при выгрузке и складировании пород"
            onChange={onChangeLoadingTransportType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={transportTypes.data ? transportTypes.data : []}
            name="transport_type"
            cyPrefix="WDE"
            label="Вид транспорта, используемого при выгрузке и складировании пород"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          {form.getFieldValue('transport_type') === 2 && form.getFieldValue('loading_transport_type') == 2 ? (
            <Form.Item name="tier" label="Количество ярусов" initialValue={2}>
              <InputNumber
                onChange={handleChangeVariables}
                data-cy="WDE-tier-input"
                min={2}
                max={9}
                placeholder={'2'}
                defaultValue={2}
              />
            </Form.Item>
          ) : (
            ''
          )}
          <FormBaseSelect
            data={equipmentNames.data ? equipmentNames.data : []}
            name="equipment_name"
            cyPrefix="WDE"
            label="Наименование оборудования"
            onChange={onChangeEquipmentName}
            defaultValue={1}
          />
          <FormBaseSelect
            data={equipmentMarks.data ? equipmentMarks.data : []}
            name="equipment_mark"
            cyPrefix="WDE"
            label="Марка оборудования"
            onChange={handleChangeVariables}
            defaultValue={1}
          />
          <FormBaseSelect
            data={breeds.data ? breeds.data : []}
            name="breed"
            cyPrefix="WDE"
            label="Порода"
            onChange={onChangeBreeed}
            defaultValue={1}
          />
          <FormBaseSelect
            data={strengths.data ? strengths.data : []}
            name="strength"
            cyPrefix="WDE"
            label="Крепость породы"
            defaultValue={1}
          />
          <FormBaseSelect
            data={dustEmissions.data ? dustEmissions.data : []}
            name="dust_emission"
            cyPrefix="WDE"
            label="Удельное пылевыделение при разгрузке автосамосвалов и думкаров"
            defaultValue={1}
          />
          <FormBaseSelect
            data={coefficientK1.data ? coefficientK1.data : []}
            name="k1"
            cyPrefix="WDE"
            label="Скорость ветра g, м/с"
            defaultValue={1}
          />
          <FormBaseSelect
            data={coefficientK2.data ? coefficientK2.data : []}
            name="k2"
            cyPrefix="WDE"
            label="Влажность материала"
            defaultValue={1}
          />
          <FormBaseSelect
            data={surfaceWindSpeeds.data ? surfaceWindSpeeds.data : []}
            name="surface_wind_speed"
            cyPrefix="WDE"
            label="Приземная скорость"
            onChange={onChangeSurfaceWindSpeed}
            defaultValue={1}
          />
          <FormBaseSelect
            data={deflations.data ? deflations.data : []}
            name="deflaiton"
            cyPrefix="WDE"
            label="Удельная сдуваемость qo, мг/(м2*с) при высоте отвала"
            defaultValue={1}
          />
          <FormBaseSelect
            data={decommissionings.data ? decommissionings.data : []}
            name="decommissioning"
            cyPrefix="WDE"
            label="Год после прекращение эксплуатации"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="WDE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
