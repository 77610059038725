import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IMBCalculationType, IMBMark, IMBVariable } from './interfaces';

const START_URL = 'RailwaysMednitskyLibrary';

export const getMBCalculationTypes = async (): Promise<IMBCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getMBMarks = async (calculationTypeId: string | number): Promise<IMBMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarkaPripoya`, {
    params: { vidRaschetaId: calculationTypeId },
  });
  return data;
};

export const getMBVariables = async (calculationTypeId: string | number): Promise<IMBVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRaschetaId: calculationTypeId },
  });
  return data;
};

export const getMBFullReport = async (reportData: {
  calculationTypeId: string | number;
  markId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { calculationTypeId, markId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: markId,
      paramName: 'MarkaPripoyaId',
    },
    ...variables,
  ]);
  return data;
};
