import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { IMethodologyModule, IRTEFormValues } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getRTECalculationTypes,
  getRTEClimaticZones,
  getRTECoefficientKT,
  getRTEFullReport,
  getRTEGasConnections,
  getRTEGasTypes,
  getRTELiquidTypes,
  getRTEOilGroups,
  getRTEOilTypes,
  getRTEOperatingModes,
  getRTETankDesings,
  getRTEVariables,
} from '../../../../api/RefineryTanksEmissions';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const RefineryTanksEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationsTypes = useQuery('RTECalculationTypes', getRTECalculationTypes);
  const oilGroups = useQuery('RTEOilGroups', getRTEOilGroups);
  const tankDesigns = useQuery('RTETankDesigns', getRTETankDesings);
  const oilTypes = useQuery('RTEOilTypes', () => getRTEOilTypes(form.getFieldValue('calculation_type')));
  const liquidTypes = useQuery('RTELiquidTypes', getRTELiquidTypes);
  const operatingModes = useQuery('RTEOperatingModes', getRTEOperatingModes);
  const coefficientKT = useQuery('RTECoefficientKT', getRTECoefficientKT);
  const gasTypes = useQuery('RTEGasTypes', getRTEGasTypes);
  const climaticZones = useQuery('RTEClimaticZones', getRTEClimaticZones);
  const gasConnections = useQuery('RTEGasConnections', getRTEGasConnections);
  const variables = useQuery('RTEVariables', () =>
    getRTEVariables(form.getFieldValue('calculation_type'), form.getFieldValue('oil_type')),
  );

  const onFinish = (values: IRTEFormValues) => {
    const {
      calculation_type,
      liquid_type,
      gas_type,
      climatic_zone,
      oil_type,
      oil_group,
      coefficient_kt,
      tank_design,
      operating_mode,
      gas_connection,
    } = values;

    if (variables.data) {
      getFormResult(getRTEFullReport, {
        calculationTypeId: calculation_type,
        oilGroupId: oil_group || 1,
        oilTypeId: oil_type || 1,
        liquidTypeId: liquid_type || [1],
        operatingModeId: operating_mode || 1,
        coefficientKTId: coefficient_kt || 1,
        gasTypeId: gas_type || [1],
        climaticZoneId: climatic_zone || 1,
        tankDesingId: tank_design || 1,
        gasConnectionId: gas_connection || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationType = () => {
    oilTypes.refetch();
    variables.refetch();
  };

  const onChangeOilType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationsTypes.data ? calculationsTypes.data : []}
            name="calculation_type"
            cyPrefix="RTE"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalculationType}
          />
          {form.getFieldValue('calculation_type') == 3 ? (
            <Form.Item name="liquid_type" label="Виды житкостей" initialValue={1}>
              <Select data-cy="RTE-liquid_type-select" mode="multiple">
                {liquidTypes.data
                  ? liquidTypes.data.map((liquidType) => (
                      <Option
                        data-cy={`RTE-liquid_type-option-${liquidType.id}`}
                        key={liquidType.id}
                        value={liquidType.id}
                      >
                        {liquidType.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 2 ? (
            <Form.Item name="liquid_type" label="Виды житкостей" initialValue={1}>
              <Select data-cy="RTE-liquid_type-select">
                {liquidTypes.data
                  ? liquidTypes.data.map((liquidType) => (
                      <Option
                        data-cy={`RTE-liquid_type-option-${liquidType.id}`}
                        key={liquidType.id}
                        value={liquidType.id}
                      >
                        {liquidType.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 4 ? (
            <Form.Item name="gas_type" label="Виды газа " initialValue={1}>
              <Select data-cy="RTE-gas_type-select" mode="multiple">
                {gasTypes.data
                  ? gasTypes.data.map((gasType) => (
                      <Option data-cy={`RTE-gas_type-option-${gasType.id}`} key={gasType.id} value={gasType.id}>
                        {gasType.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 5 ? (
            <FormBaseSelect
              data={climaticZones.data ? climaticZones.data : []}
              name="climatic_zone"
              cyPrefix="RTE"
              label="Климатическая зона"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('calculation_type') == 1 || form.getFieldValue('calculation_type') == 5 ? (
            <FormBaseSelect
              data={oilTypes.data ? oilTypes.data : []}
              name="oil_type"
              cyPrefix="RTE"
              label="Вид нефтепродукта"
              defaultValue={1}
              onChange={onChangeOilType}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') == 1 ? (
            <>
              <FormBaseSelect
                data={oilGroups.data ? oilGroups.data : []}
                name="oil_group"
                cyPrefix="RTE"
                label="Группа нефтепродукта"
                defaultValue={1}
              />
              <FormBaseSelect
                data={coefficientKT.data ? coefficientKT.data : []}
                name="coefficient_kt"
                cyPrefix="RTE"
                label="Выбрать коэффициент Kt"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}

          <FormBaseSelect
            data={tankDesigns.data ? tankDesigns.data : []}
            name="tank_design"
            cyPrefix="RTE"
            label="Конструкция резервуаров"
            defaultValue={1}
          />
          <FormBaseSelect
            data={operatingModes.data ? operatingModes.data : []}
            name="operating_mode"
            cyPrefix="RTE"
            label="Режим эксплуатации"
            defaultValue={1}
          />
          <FormBaseSelect
            data={gasConnections.data ? gasConnections.data : []}
            name="gas_connection"
            cyPrefix="RTE"
            label="Газовая обвязка"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="RTE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
