import { AxiosResponse } from 'axios';
import { apiClient } from '../Client';
import { IStandartData } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IECPCalculationType, IECPDust, IECPFuelType, IECPNitrogen, IECPReportData, IECPVariable } from './interfaces';

const START_URL = 'CementClass';

export const getECPCalculationTypes = async (): Promise<IECPCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getECPFuelTypes = async (): Promise<IECPFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getECPNitrogens = async (): Promise<IECPNitrogen[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetAzotConstants`);
  return data;
};

export const getECPDusts = async (): Promise<IECPDust[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPilConstants`);
  return data;
};

export const getECPVariables = async (calculationTypeId: string | number): Promise<IECPVariable[]> => {
  const commonVariables: AxiosResponse<IStandartData[]> = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRaschetaId: calculationTypeId },
  });
  const gasVariables: AxiosResponse<IStandartData[]> = await apiClient.get(`${START_URL}/GetOthGasInputData`);

  commonVariables.data = commonVariables.data.filter(
    (variable: { paramName: string }) =>
      variable.paramName != 'DataBaseIdAzot' && variable.paramName != 'DataBaseIdPil',
  );

  const data = [...gasVariables.data, ...commonVariables.data];
  for (let i = 0; i <= data.length - 1; i++) {
    data[i].id = i;
  }

  console.log(data);

  return data;
};

export const getECPFullReport = async (reportData: IECPReportData): Promise<IToolsReport[]> => {
  const { calculationTypeId, dustId, nitrogenId, fuelTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: fuelTypeId,
      paramName: 'VidToplivaId',
    },
    {
      id: nitrogenId,
      paramName: 'DataBaseIdAzot',
    },
    {
      id: dustId,
      paramName: 'DataBaseIdPil',
    },
    ...variables,
  ]);
  return data;
};
