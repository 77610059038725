import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICMEAmountExplosive,
  ICMECalculationType,
  ICMEDrillingType,
  ICMEDustCollectorType,
  ICMEEquipmentName,
  ICMEExplosiveConsumption,
  ICMEExplosiveType,
  ICMEHumidity,
  ICMELifetime,
  ICMELocalCondition,
  ICMEPollutant,
  ICMEReportData,
  ICMESource,
  ICMEVariable,
  ICMEWindSpeed,
} from './interfaces';

const START_URL = 'UgolClass';

export const getCMECalculationTypes = async (): Promise<ICMECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getCMEDustCollectorTypes = async (): Promise<ICMEDustCollectorType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPyleulov`);
  return data;
};

export const getCMESources = async (): Promise<ICMESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIstochnik`);
  return data;
};

export const getCMEHumidities = async (): Promise<ICMEHumidity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVlazhnost`);
  return data;
};

export const getCMEWindSpeeds = async (): Promise<ICMEWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSkorostVetra`);
  return data;
};

export const getCMEEquipmentNames = async (): Promise<ICMEEquipmentName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOborudov`);
  return data;
};

export const getCMELifetimes = async (): Promise<ICMELifetime[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSrokEkspl`);
  return data;
};

export const getCMELocalConditions = async (): Promise<ICMELocalCondition[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMestUsloviya`);
  return data;
};

export const getCMEAmountExplosives = async (): Promise<ICMEAmountExplosive[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKolVzryvchVeshestv`);
  return data;
};

export const getCMEPollutants = async (): Promise<ICMEPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZV`);
  return data;
};

export const getCMEExplosiveTypes = async (pollutantId: string | number): Promise<ICMEExplosiveType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVzryvVeshestva`, { params: { vidZV: pollutantId } });
  return data;
};

export const getCMEExplosiveConsumptions = async (): Promise<ICMEExplosiveConsumption[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUdelRashod`);
  return data;
};

export const getCMEDrillingTypes = async (): Promise<ICMEDrillingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidBurovyhRabot`);
  return data;
};

export const getCMEVariables = async (
  calculationTypeId: string | number,
  dustCollectorTypeId: string | number,
): Promise<ICMEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRascheta: calculationTypeId, pyleulov: dustCollectorTypeId, n: 1 },
  });
  return data;
};

export const getCMEFullReport = async (reportData: ICMEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    dustCollectorTypeId,
    sourceId,
    humidityId,
    windSpeedId,
    equipmentNameId,
    lifetimeId,
    localConditionId,
    amountExplosiveId,
    pollutantId,
    explosiveTypeId,
    explosiveConsumptionId,
    drillingTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: dustCollectorTypeId,
      paramName: 'Pyleulov',
    },
    {
      id: equipmentNameId,
      paramName: 'Oborudov',
    },
    {
      id: windSpeedId,
      paramName: 'SkorostVetra',
    },
    {
      id: humidityId,
      paramName: 'Vlazhnost',
    },
    {
      id: lifetimeId,
      paramName: 'SrokEkspl',
    },
    {
      id: localConditionId,
      paramName: 'MestUsloviya',
    },
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: sourceId,
      paramName: 'Istochnik',
    },
    {
      id: amountExplosiveId,
      paramName: 'KolVzryvchVeshestv',
    },
    {
      id: explosiveTypeId,
      paramName: 'VidVzryvVeshestva',
    },
    {
      id: drillingTypeId,
      paramName: 'VidBurovyhRabot',
    },
    {
      id: pollutantId,
      paramName: 'VidZV',
    },
    {
      id: explosiveConsumptionId,
      paramName: 'UdelRashod',
    },
    ...variables,
  ]);
  return data;
};
