import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getMPCalculationTypes,
  getMPFullReport,
  getMPSituations,
  getMPVariables,
} from '../../api/MetallurgicalProduction';

import { IMethodologyModule, IMPFormValues } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const MetallurgicProduction: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('MPCalculatoinTypes', getMPCalculationTypes);
  const situations = useQuery('MPSituations', () => getMPSituations(form.getFieldValue('calculation_type')));
  const variables = useQuery('MPVariables', () =>
    getMPVariables(form.getFieldValue('calculation_type'), form.getFieldValue('situation')),
  );

  const onFinish = (values: IMPFormValues) => {
    const { calculation_type, situation } = values;

    if (variables.data) {
      getFormResult(getMPFullReport, {
        calculationTypeId: calculation_type,
        situationId: situation,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalcualtionType = () => {
    situations.refetch();
    variables.refetch();
  };

  const onChangeSituation = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="MP"
            label="Вид расчета"
            defaultValue={1}
            onChange={onChangeCalcualtionType}
          />
          {form.getFieldValue('calculation_type') > 3 ? (
            <Form.Item name="situation" label="Вид ситуации" initialValue={'Выберете ситуацию'}>
              <Select data-cy={`MP-situation-select`} onChange={onChangeSituation}>
                {situations.data
                  ? situations.data.map((element) => (
                      <Option
                        data-cy={`MP-situation-option-${element.id}`}
                        key={element.id + element.name}
                        value={element.id}
                      >
                        {element.name}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="MP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
