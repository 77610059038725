import React from 'react';
import { Form, Row, Col } from 'antd';
import { ICPFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getCPBusTypes,
  getCPControls,
  getCPEngineTypes,
  getCPEngineVolumes,
  getCPFuelTypes,
  getCPFullReport,
  getCPHeatings,
  getCPInjectionCarburetors,
  getCPLiftingCapacities,
  getCPNeutralizerTypes,
  getCPParkingTypes,
  getCPSources,
  getCPVariables,
} from '../../../../api/CarParking';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const CarParking: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const parkingTypes = useQuery('CPParkingTypes', getCPParkingTypes);
  const sources = useQuery('CPSources', getCPSources);
  const controls = useQuery('CPControls', getCPControls);
  const engineTypes = useQuery('CPEngineTypes', () => getCPEngineTypes(form.getFieldValue('source')));
  const fuelTypes = useQuery('CPFuelTypes', () => getCPFuelTypes(form.getFieldValue('engine_type')));
  const engineVolumes = useQuery('CPEngineVolumes', getCPEngineVolumes);
  const injectionCarburetors = useQuery('CPInjectionCarburetor', getCPInjectionCarburetors);
  const neutralizerType = useQuery('CPNeutralizerTypes', () => getCPNeutralizerTypes(form.getFieldValue('source')));
  const heatings = useQuery('CPHeatings', getCPHeatings);
  const liftingCapacities = useQuery('CPLiftingCapacities', () =>
    getCPLiftingCapacities(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const busTypes = useQuery('CPBusTypes', () =>
    getCPBusTypes(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const variables = useQuery('CPVariables', () => getCPVariables(form.getFieldValue('parking_type')));

  const onFinish = (values: ICPFormValues) => {
    const {
      parking_type,
      control,
      source,
      engine_type,
      injection_сarburetor,
      engine_volume,
      neutralizer_type,
      heating,
      bus_type,
      lifting_capacity,
      fuel_type,
    } = values;

    if (variables.data) {
      getFormResult(getCPFullReport, {
        parkingTypeId: parking_type,
        sourceId: source,
        engineTypeId: engine_type,
        controlId: control,
        engineVolumeId: engine_volume,
        injectionCarburetor: injection_сarburetor,
        fuelTypeId: fuel_type,
        neutralizerId: neutralizer_type || 1,
        liftingCapacityId: lifting_capacity || 1,
        busTypeId: bus_type || 1,
        heatingId: heating,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeParkingType = () => {
    variables.refetch();
  };

  const onChangeSource = () => {
    engineTypes.refetch();
    injectionCarburetors.refetch();
    liftingCapacities.refetch();
    busTypes.refetch();
  };

  const onChangeEngineType = () => {
    fuelTypes.refetch();
    liftingCapacities.refetch();
    busTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={parkingTypes.data ? parkingTypes.data : []}
            name="parking_type"
            cyPrefix="CP"
            label="Вид стоянки"
            onChange={onChangeParkingType}
            defaultValue={1}
          />
          <FormBaseSelect
            data={controls.data ? controls.data : []}
            name="control"
            cyPrefix="CP"
            label="Наличие регулярного контроля и регулирования движения"
            defaultValue={1}
          />
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="CP"
            label="Передвижные источники"
            onChange={onChangeSource}
            defaultValue={1}
          />
          <FormBaseSelect
            data={engineTypes.data ? engineTypes.data : []}
            name="engine_type"
            cyPrefix="CP"
            label="Вид двигателя"
            onChange={onChangeEngineType}
            defaultValue={2}
          />
          <FormBaseSelect
            data={injectionCarburetors.data ? injectionCarburetors.data : []}
            name="injection_сarburetor"
            cyPrefix="CP"
            label="Впрыск или карбюратор"
            defaultValue={1}
          />
          <FormBaseSelect
            data={engineVolumes.data ? engineVolumes.data : []}
            name="engine_volume"
            cyPrefix="CP"
            label="Рабочий обьем двигателя"
            defaultValue={1}
          />
          {form.getFieldValue('engine_type') == 3 ? (
            <FormBaseSelect
              data={neutralizerType.data ? neutralizerType.data : []}
              name="neutralizer_type"
              cyPrefix="CP"
              label="Вид сертифицированного каталитического нейтрализатора"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={heatings.data ? heatings.data : []}
            name="heating"
            cyPrefix="CP"
            label="Наличие подогрева"
            defaultValue={1}
          />
          {form.getFieldValue('source') == 3 || form.getFieldValue('source') == 4 ? (
            <FormBaseSelect
              data={liftingCapacities.data ? liftingCapacities.data : []}
              name="lifting_capacity"
              cyPrefix="CP"
              label="Грузоподъемность"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('source') >= 5 ? (
            <FormBaseSelect
              data={busTypes.data ? busTypes.data : []}
              name="bus_type"
              cyPrefix="CP"
              label="Класс автобуса"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="CP"
            label="Вид топлива"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
