import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import {
  AuxiliaryDomesticEmissions,
  ChemicalLaboratoriesEmissions,
  CleaningIndustryEmissions,
  FinishedDosageFormsEmissions,
  LivestockFacilitiesEmissions,
} from './Submethodologies';

const { Option } = Select;

export const Order7221: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selecTEdSubmethodology, setSelecTEdSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`O7221-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelecTEdSubmethodology(value)}
              >
                <Option data-cy={`O7221-submethodology-option-0`} value={0}>
                  Выбросы от вспомогательных и бытовых служб предприятий
                </Option>
                <Option data-cy={`O7221-submethodology-option-1`} value={1}>
                  Выбросы при производстве готовых лекарственных форм
                </Option>
                <Option data-cy={`O7221-submethodology-option-2`} value={2}>
                  Выбросы от химических лабораторий
                </Option>
                <Option data-cy={`O7221-submethodology-option-3`} value={3}>
                  Выбросы от предприятий химчистки
                </Option>
                <Option data-cy={`O7221-submethodology-option-4`} value={4}>
                  Выбросы от объектов животноводства
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selecTEdSubmethodology == 0 ? (
        <AuxiliaryDomesticEmissions marginBetween={marginBetween}>{children}</AuxiliaryDomesticEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 1 ? (
        <FinishedDosageFormsEmissions marginBetween={marginBetween}>{children}</FinishedDosageFormsEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 2 ? (
        <ChemicalLaboratoriesEmissions marginBetween={marginBetween}>{children}</ChemicalLaboratoriesEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 3 ? (
        <CleaningIndustryEmissions marginBetween={marginBetween}>{children}</CleaningIndustryEmissions>
      ) : (
        ''
      )}
      {selecTEdSubmethodology == 4 ? (
        <LivestockFacilitiesEmissions marginBetween={marginBetween}>{children}</LivestockFacilitiesEmissions>
      ) : (
        ''
      )}
    </>
  );
};
