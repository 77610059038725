import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IGWCalculationType, IGWVariable, IGWWeldingOrCuttingType } from './interfaces';

const START_URL = 'SvarkaClass';

export const getGWCalculationTypes = async (): Promise<IGWCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getGWWeldingOrCuttingTypes = async (
  calculationType: string | number,
): Promise<IGWWeldingOrCuttingType[]> => {
  let response;

  switch (calculationType) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetRashod`);
      break;
    case 2:
      response = await apiClient.get(`${START_URL}/GetRabObor`);
      break;
    case 3:
      response = await apiClient.get(`${START_URL}/GetPloshadStika`);
      break;
    case 4:
      response = await apiClient.get(`${START_URL}/GetRezkaType`);
      break;
    case 5:
      response = await apiClient.get(`${START_URL}/GetRezkaType`);
      break;
  }

  return response?.data;
};

export const getGWVariables = async (calculationType: string | number): Promise<IGWVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { vidRaschetaId: calculationType } });
  return data;
};

export const getGWFullReport = async (reportData: {
  calculationType: string | number;
  WeldingTypeOrCuttingType: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { calculationType, WeldingTypeOrCuttingType, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationType,
      value: 1,
      paramName: 'VidRaschetaId',
    },
    {
      id: WeldingTypeOrCuttingType,
      value: 1,
      paramName: 'DataBaseId',
    },
    ...variables,
  ]);
  return data;
};
