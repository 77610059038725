import React from 'react';
import { Form, Row, Col } from 'antd';
import { IMethodologyModule, IVFormValues } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getVEmissionSeparations,
  getVFullReport,
  getVLatitudes,
  getVLifetimes,
  getVMarks,
  getVOperatingModeCounts,
  getVOperatingModes,
  getVPollutants,
  getVSectionTypes,
  getVSpeeds,
  getVTrackMachines,
  getVVariables,
} from '../../../../api/Vehicles';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const Vehicles: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const emissionSeparations = useQuery('VEmissionSeparations', getVEmissionSeparations);
  const pollutants = useQuery('VPollutants', getVPollutants);
  const speeds = useQuery('VSpeeds', getVSpeeds);
  const lifetimes = useQuery('VLifetimes', getVLifetimes);
  const latitudes = useQuery('VLatitudes', getVLatitudes);
  const operatingModes = useQuery('VOperatingModes', getVOperatingModes);
  const operatingModeCounts = useQuery('VOperatingModeCounts', getVOperatingModeCounts);
  const marks = useQuery('VMarks', () => getVMarks(form.getFieldValue('emission_separation')));
  const sectionsTypes = useQuery('VSelectionTypes', getVSectionTypes);
  const trackMachines = useQuery('VTrackMachines', getVTrackMachines);
  const variables = useQuery('VVariables', () => getVVariables(form.getFieldValue('emission_separation')));

  const [operationModeCount, setOperationModeCount] = React.useState(0);

  const onFinish = (values: IVFormValues) => {
    const {
      emission_separation,
      pollutant,
      sections_type,
      latitude,
      lifetime,
      operating_mode1,
      operating_mode2,
      operating_mode3,
      operating_mode4,
      operating_mode5,
      operating_mode_count,
      mark,
      track_machine,
      speed,
    } = values;

    if (variables.data) {
      getFormResult(getVFullReport, {
        emissionSeparationId: emission_separation,
        markId: mark || 1,
        pollutant: pollutant || 1,
        speedId: speed || 1,
        lifetimeId: lifetime || 1,
        latitudeId: latitude || 1,
        operatingMode1: operating_mode1 || 1,
        operatingMode2: operating_mode2 || 1,
        operatingMode3: operating_mode3 || 1,
        operatingMode4: operating_mode4 || 1,
        operatingMode5: operating_mode5 || 1,
        operatingModeCountId: operating_mode_count || 1,
        sectionTypeId: sections_type || 1,
        trackMachineId: track_machine || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEmissionSeparation = () => {
    marks.refetch();
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={emissionSeparations.data ? emissionSeparations.data : []}
            name="emission_separation"
            cyPrefix="V"
            label="Определение выбросов от"
            defaultValue={1}
            onChange={onChangeEmissionSeparation}
          />
          {form.getFieldValue('emission_separation') <= 3 ? (
            <FormBaseSelect
              data={marks.data ? marks.data : []}
              name="mark"
              cyPrefix="V"
              label="Марка тепловоза"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={pollutants.data ? pollutants.data : []}
            name="pollutant"
            cyPrefix="V"
            label="Вид ЗВ"
            defaultValue={1}
          />
          {form.getFieldValue('emission_separation') === 1 ? (
            <FormBaseSelect
              data={speeds.data ? speeds.data : []}
              name="speed"
              cyPrefix="V"
              label="Величина участковой скорости"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={lifetimes.data ? lifetimes.data : []}
            name="lifetime"
            cyPrefix="V"
            label="Срок эксплуатации тепловоза"
            defaultValue={1}
          />
          {form.getFieldValue('emission_separation') !== 4 ? (
            <FormBaseSelect
              data={latitudes.data ? latitudes.data : []}
              name="latitude"
              cyPrefix="V"
              label="Северная широта района"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('emission_separation') !== 1 && form.getFieldValue('emission_separation') !== 5 ? (
            <>
              <FormBaseSelect
                data={operatingModeCounts.data ? operatingModeCounts.data : []}
                name="operating_mode_count"
                cyPrefix="V"
                label="Число режимов работы двигателя"
                onChange={() => setOperationModeCount(form.getFieldValue('operating_mode_count'))}
                defaultValue={1}
              />
              <FormBaseSelect
                data={operatingModes.data ? operatingModes.data : []}
                name="operating_mode1"
                cyPrefix="V"
                label="Режим работы двигателя 1"
                defaultValue={1}
              />
              {operationModeCount >= 2 ? (
                <FormBaseSelect
                  data={operatingModes.data ? operatingModes.data : []}
                  name="operating_mode2"
                  cyPrefix="V"
                  label="Режим работы двигателя 2"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
              {operationModeCount >= 3 ? (
                <FormBaseSelect
                  data={operatingModes.data ? operatingModes.data : []}
                  name="operating_mode3"
                  cyPrefix="V"
                  label="Режим работы двигателя 3"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
              {operationModeCount >= 4 ? (
                <FormBaseSelect
                  data={operatingModes.data ? operatingModes.data : []}
                  name="operating_mode4"
                  cyPrefix="V"
                  label="Режим работы двигателя 4"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
              {operationModeCount >= 5 ? (
                <FormBaseSelect
                  data={operatingModes.data ? operatingModes.data : []}
                  name="operating_mode5"
                  cyPrefix="V"
                  label="Режим работы двигателя 5"
                  defaultValue={1}
                />
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('emission_separation') === 4 ? (
            <FormBaseSelect
              data={sectionsTypes.data ? sectionsTypes.data : []}
              name="sections_type"
              cyPrefix="V"
              label="Тип секции"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('emission_separation') != 2 ? (
            <FormBaseSelect
              data={trackMachines.data ? trackMachines.data : []}
              name="track_machine"
              cyPrefix="V"
              label="Путевая машина"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="V"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
