import React from 'react';
import { useQuery } from 'react-query';
import { Form, Select, Row, Col } from 'antd';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { IMethodologyModule, IPWFormValues } from './interfaces';

import {
  getPWCleaningDevices,
  getPWFullReport,
  getPWMarks,
  getPWMaterials,
  getPWPaintingMethods,
  getPWPaintingTypes,
  getPWProcesses,
  getPWVariables,
} from '../../api/PaintWork';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const PaintWork: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const processes = useQuery('PWProcesses', getPWProcesses);
  const paintingMethods = useQuery('PWPaintingMethods', getPWPaintingMethods);
  const materials = useQuery('PWMaterials', getPWMaterials);
  const marks = useQuery('PWMarks', () => getPWMarks(form.getFieldValue('material')));
  const paintingTypes = useQuery('PWPaintingTypes', () => getPWPaintingTypes(form.getFieldValue('painting_method')));
  const cleaningDevices = useQuery('PWCleaningDevices', getPWCleaningDevices);
  const variables = useQuery('PWVariables', () =>
    getPWVariables(form.getFieldValue('cleaning_device'), form.getFieldValue('process')),
  );

  const onFinish = (values: IPWFormValues) => {
    const { process, painting_method, material, mark, painting_type, cleaning_device } = values;

    if (variables.data) {
      getFormResult(getPWFullReport, {
        paintMethodId: painting_method,
        paintingType: painting_type,
        markId: mark,
        processId: process,
        cleaningDeviceId: cleaning_device,
        materialId: material,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeMaterial = () => {
    marks.refetch();
  };

  const onChangeProcessOrCleaningDevice = () => {
    variables.refetch();
  };

  const onChangePaintingMethod = () => {
    paintingTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={processes.data ? processes.data : []}
            name="process"
            cyPrefix="PW"
            label="Процесс"
            onChange={onChangeProcessOrCleaningDevice}
            defaultValue={1}
          />
          <Form.Item name="painting_method" label="Способ окраски" initialValue={1}>
            <Select data-cy="PW-painting_method-select" onChange={onChangePaintingMethod}>
              {paintingMethods.data
                ? paintingMethods.data.map((method) => (
                    <Option data-cy={`PW-painting_method-option-${method.id}`} key={method.id} value={method.id}>
                      {method.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <FormBaseSelect
            data={materials.data ? materials.data : []}
            name="material"
            cyPrefix="PW"
            label="Лакокрасочный материал"
            onChange={onChangeMaterial}
            defaultValue={1}
          />
          <FormBaseSelect
            data={marks.data ? marks.data : []}
            name="mark"
            cyPrefix="PW"
            label="Марка"
            defaultValue={1}
          />
          <FormBaseSelect
            data={paintingTypes.data ? paintingTypes.data : []}
            name="painting_type"
            cyPrefix="PW"
            label="Вид окраски"
            defaultValue={1}
          />
          <FormBaseSelect
            data={cleaningDevices.data ? cleaningDevices.data : []}
            name="cleaning_device"
            cyPrefix="PW"
            label="Наличие очистного устройства"
            onChange={onChangeProcessOrCleaningDevice}
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="PW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
