import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IEPIProductionStage, IEPIVariable } from './interfaces';

const START_URL = 'PishepromMakaronClass';

export const getEPIProductionStages = async (): Promise<IEPIProductionStage[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetEtap`);
  return data;
};

export const getEPIVariables = async (): Promise<IEPIVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getEPIFullReport = async (reportData: {
  productionStageId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { productionStageId, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: productionStageId,
      paramName: 'Etap',
    },
    ...variables,
  ]);
  return data;
};
