import React from 'react';
import { Form, Row, Col } from 'antd';
import { IMethodologyModule, ITSGFormValues } from './interfaces';
import { useQuery } from 'react-query';
import {
  getTSGDiametrs,
  getTSGDischargeTypes,
  getTSGFuelNames,
  getTSGGasCompressibilityFactors,
  getTSGOrganosulfurs,
  getTSGSteamSupplies,
  getTSGUnits,
  getTSGVariables,
  getTSGFurnaceTypes,
  getTSGFullReport,
} from '../../api/TransportationStorageGas';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const TransportationStorageGas: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const dischargeTypes = useQuery('TSGDischargeTypes', getTSGDischargeTypes);
  const organosulfurs = useQuery('TSGOrganosulfurs', getTSGOrganosulfurs);
  const diametrs = useQuery('TSGDiametrs', getTSGDiametrs);
  const fuelNames = useQuery('TSGFuelNames', getTSGFuelNames);
  const steamSupplies = useQuery('TSGSteamSupplies', getTSGSteamSupplies);
  const gasCompressibilityFactors = useQuery('TSGGasCompressibilityFactors', getTSGGasCompressibilityFactors);
  const units = useQuery('TSGUnits', getTSGUnits);
  const furnaceTypes = useQuery('TSGFurnaceTypes', getTSGFurnaceTypes);
  const variables = useQuery('TSGVariables', () =>
    getTSGVariables(form.getFieldValue('discharge_type'), form.getFieldValue('organosulfur')),
  );

  const onFinish = (values: ITSGFormValues) => {
    const {
      diametr,
      discharge_type,
      organosulfur,
      fuel_name,
      furnace_type,
      steam_supply,
      unit,
      gas_compressibility_factor,
    } = values;

    if (variables.data) {
      getFormResult(getTSGFullReport, {
        dischargeTypeId: discharge_type,
        organosulfurId: organosulfur || 1,
        diametrId: diametr || 1,
        fuelNameId: fuel_name || 1,
        steamSupplyId: steam_supply || 1,
        gasCompressibilityFactorId: gas_compressibility_factor || 1,
        unitId: unit || 1,
        furnaceTypeId: furnace_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeDischargeType = () => {
    variables.refetch();
  };

  const onChangeOrganosulfur = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={dischargeTypes.data ? dischargeTypes.data : []}
            name="discharge_type"
            cyPrefix="TSG"
            label="Вид выброса"
            defaultValue={1}
            onChange={onChangeDischargeType}
          />
          {form.getFieldValue('discharge_type') == 1 ? (
            <FormBaseSelect
              data={diametrs.data ? diametrs.data : []}
              name="diametr"
              cyPrefix="TSG"
              label="Диаметр дренажной линии"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('discharge_type') == 2 || form.getFieldValue('discharge_type') == 4 ? (
            <FormBaseSelect
              data={gasCompressibilityFactors.data ? gasCompressibilityFactors.data : []}
              name="gas_compressibility_factor"
              cyPrefix="TSG"
              label="Коэфицент cжимаемости газа"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('discharge_type') == 7 ? (
            <>
              <FormBaseSelect
                data={fuelNames.data ? fuelNames.data : []}
                name="fuel_name"
                cyPrefix="TSG"
                label="Сероорганика"
                defaultValue={1}
              />
              <FormBaseSelect
                data={steamSupplies.data ? steamSupplies.data : []}
                name="steam_supply"
                cyPrefix="TSG"
                label="Подача пара"
                defaultValue={1}
              />
            </>
          ) : (
            ''
          )}
          {form.getFieldValue('discharge_type') == 8 ? (
            <>
              <FormBaseSelect
                data={furnaceTypes.data ? furnaceTypes.data : []}
                name="furnace_type"
                cyPrefix="TSG"
                label="Тип топки"
                defaultValue={1}
              />
              <FormBaseSelect
                data={organosulfurs.data ? organosulfurs.data : []}
                name="organosulfur"
                cyPrefix="TSG"
                label="Сероорганика"
                defaultValue={1}
                onChange={onChangeOrganosulfur}
              />
            </>
          ) : (
            ''
          )}

          <FormBaseSelect
            data={units.data ? units.data : []}
            name="unit"
            cyPrefix="TSG"
            label="Возможность посчитать в г/с и т/год"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TSG"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
