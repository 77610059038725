import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { IMPCalculationType, IMPReportData, IMPSituation, IMPVariables } from './interfaces';

const START_URL = 'MET';

export const getMPCalculationTypes = async (): Promise<IMPCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetCalcType`);
  return data;
};

export const getMPSituations = async (calculationTypeId: string | number): Promise<IMPSituation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSituations`, { params: { calcType: calculationTypeId } });
  return data;
};

export const getMPVariables = async (
  calculationTypeId: string | number,
  situationId: string | number,
): Promise<IMPVariables[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetData`, {
    params: { calcType: calculationTypeId, situationId: situationId },
  });
  return data;
};

export const getMPFullReport = async (reportData: IMPReportData): Promise<IToolsReport[]> => {
  const { calculationTypeId, situationId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      value: 1,
      paramName: 'CalcType',
    },
    {
      id: situationId,
      value: 1,
      paramName: 'SituationId',
    },
    ...variables,
  ]);
  return data;
};
