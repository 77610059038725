import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import { getRRPFullReport, getRRPTechnologicalProcesses, getRRPVariables } from '../../../../api/RepairRubberProducts';

import { IMethodologyModule, IRRPFormValues } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const RepairRubberProducts: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const technologicalProcesses = useQuery('RRPTechnologicalProcesses', getRRPTechnologicalProcesses);
  const variables = useQuery('RRPVariables', () => getRRPVariables(form.getFieldValue('technological_process')));

  const onFinish = (values: IRRPFormValues) => {
    const { technological_process } = values;

    if (variables.data) {
      getFormResult(getRRPFullReport, {
        technologicalProcessId: technological_process,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeTechnologicalProcess = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={technologicalProcesses.data ? technologicalProcesses.data : []}
            name="technological_process"
            cyPrefix="RRP"
            label="Технологический процесс"
            defaultValue={1}
            onChange={handleChangeTechnologicalProcess}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="RRP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
