import React from 'react';
import { Form, Row, Col, InputNumber, Select } from 'antd';
import { useQuery } from 'react-query';
import {
  getHTBoilerTypes,
  getHTCalorificValues,
  getHTCollectorTypes,
  getHTFuelTypes,
  getHTFullReport,
  getHTFurnaceTypes,
  getHTPresenceOxides,
  getHTVariables,
} from '../../api/HeatTreatment';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { IHTFormValues, IMethodologyModule } from './interfaces';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const HeatTreatment: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const collectorTypes = useQuery('HTCollectorTypes', getHTCollectorTypes);
  const furnaceTypes = useQuery('HTFurnaceTypes', getHTFurnaceTypes);
  const boilerTypes = useQuery('HTBoilerTypes', getHTBoilerTypes);
  const presenceOxides = useQuery('HTPresenceOxides', getHTPresenceOxides);
  const calorificValues = useQuery('HTCalorificValues', getHTCalorificValues);
  const fuelTypes = useQuery('HTFuelTypes', getHTFuelTypes);
  const variables = useQuery('HTVariables', () =>
    getHTVariables(
      form.getFieldValue('collector_type'),
      form.getFieldValue('boiler_type'),
      form.getFieldValue('presence_oxide'),
      form.getFieldValue('furnace_type'),
      form.getFieldValue('component_count'),
    ),
  );

  const onFinish = (values: IHTFormValues) => {
    const { fuel_type, furnace_type, collector_type, component_count, boiler_type, presence_oxide, calorific_values } =
      values;

    if (variables.data) {
      getFormResult(getHTFullReport, {
        fuelTypeId: fuel_type || 1,
        furnaceTypeId: furnace_type || 1,
        boilerTypeId: boiler_type || 1,
        presenceOxideId: presence_oxide || 1,
        collectorTypeId: collector_type || 1,
        componentsCount: component_count || 1,
        calorificValueId: calorific_values || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="HT"
            label="Вид топлива"
            defaultValue={1}
          />
          <FormBaseSelect
            data={furnaceTypes.data ? furnaceTypes.data : []}
            name="furnace_type"
            cyPrefix="HT"
            label="Тип топки для сжигания отходов"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <Form.Item name="component_count" label="Количество отдельных компонентов видов отходов, n" initialValue={1}>
            <InputNumber
              data-cy="HT-component_count-input"
              min={1}
              placeholder={'1'}
              defaultValue={1}
              onChange={handleChangeVariables}
            />
          </Form.Item>
          <Form.Item name="calorific_value" label="Параметры горелок" initialValue={1}>
            <Select data-cy={`HT-calorific_value-select`}>
              {calorificValues.data
                ? calorificValues.data.map((element) => (
                    <Option
                      data-cy={`HT-calorific_value-option-${element.id}`}
                      key={element.id + element.qphTBO}
                      value={element.id}
                    >
                      {`${element.qphTBO}`}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <FormBaseSelect
            data={collectorTypes.data ? collectorTypes.data : []}
            name="collector_type"
            cyPrefix="HT"
            label="Тип золоуловителя"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={boilerTypes.data ? boilerTypes.data : []}
            name="boiler_type"
            cyPrefix="HT"
            label="Тип котла"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={presenceOxides.data ? presenceOxides.data : []}
            name="presence_oxide"
            cyPrefix="HT"
            label="Наличие анализов содержания оксидов ванадия"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="HT"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
