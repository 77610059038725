import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICPBusType,
  ICPControl,
  ICPEngineType,
  ICPEngineVolume,
  ICPHeating,
  ICPInjectionCarburetor,
  ICPLiftingCapacity,
  ICPNeutralizer,
  ICPParkingType,
  ICPReportData,
  ICPSource,
  ICPVariable,
} from './interfaces';

const START_URL = 'AvtotransportLibrary2';

export const getCPParkingTypes = async (): Promise<ICPParkingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidStoyanki`);
  return data;
};

export const getCPSources = async (): Promise<ICPSource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPeredvizhnyeIstochniki`);
  return data;
};

export const getCPControls = async (): Promise<ICPControl[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNalichieKontrolya`);
  return data;
};

export const getCPEngineTypes = async (sourceId: string | number): Promise<ICPEngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDvigatelya`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getCPFuelTypes = async (engineTypeId: string | number): Promise<ICPEngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, { params: { vidDvigatelyaId: engineTypeId } });
  return data;
};

export const getCPEngineVolumes = async (): Promise<ICPEngineVolume[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVDvigatelya`);
  return data;
};

export const getCPInjectionCarburetors = async (): Promise<ICPInjectionCarburetor[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVpryskKarbyurator`);
  return data;
};

export const getCPNeutralizerTypes = async (sourceId: string | number): Promise<ICPNeutralizer[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNeitralizatora`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getCPHeatings = async (): Promise<ICPHeating[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNalichiePodogreva`);
  return data;
};

export const getCPLiftingCapacities = async (
  sourceId: string | number,
  engineType: string | number,
): Promise<ICPLiftingCapacity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidGruzopodemnost`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineType },
  });
  return data;
};

export const getCPBusTypes = async (sourceId: string | number, engineType: string | number): Promise<ICPBusType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlassAvtobusa`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineType },
  });
  return data;
};

export const getCPVariables = async (parkingTypeId: string | number): Promise<ICPVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { vidStoyankiId: parkingTypeId } });
  return data;
};

export const getCPFullReport = async (reportData: ICPReportData): Promise<IToolsReport[]> => {
  const {
    parkingTypeId,
    sourceId,
    engineTypeId,
    controlId,
    engineVolumeId,
    injectionCarburetor,
    fuelTypeId,
    neutralizerId,
    liftingCapacityId,
    busTypeId,
    heatingId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: parkingTypeId,
      paramName: 'StoyankaId',
    },
    {
      id: sourceId,
      paramName: 'PeredvizhnyeIstochnikiId',
    },
    {
      id: engineTypeId,
      paramName: 'DvigatelId',
    },
    {
      id: controlId,
      paramName: 'NalichieKontrolyaId',
    },
    {
      id: engineVolumeId,
      paramName: 'VDvigatelyaId',
    },
    {
      id: injectionCarburetor,
      paramName: 'VpryskKarbyuratorId',
    },
    {
      id: fuelTypeId,
      paramName: 'ToplivoId',
    },
    {
      id: neutralizerId,
      paramName: 'NeitralizatorId',
    },
    {
      id: liftingCapacityId,
      paramName: 'GruzopodemnostId',
    },
    {
      id: busTypeId,
      paramName: 'KlassAvtobusaId',
    },
    {
      id: heatingId,
      paramName: 'NalichiePodogrevaId',
    },
    ...variables,
  ]);
  return data;
};
