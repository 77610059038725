import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IPMEBathShelter,
  IPMECalculationType,
  IPMEDegreasingType,
  IPMEDetailCoating,
  IPMEOperationType,
  IPMEProcessingType,
  IPMEProductionArea,
  IPMEReportData,
  IPMETechnologicalOperation,
  IPMETechnologicalProcess,
  IPMEVariable,
} from './interfaces';

const START_URL = 'GalvanicLibrary';

export const getPMECalculationTypes = async (): Promise<IPMECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getPMEProcessingTypes = async (): Promise<IPMEProcessingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidObrabotki`);
  return data;
};

export const getPMEProductionAreas = async (): Promise<IPMEProductionArea[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidUchastokProizvodstva`);
  return data;
};

export const getPMEBathShelters = async (): Promise<IPMEBathShelter[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidK1`);
  return data;
};

export const getPMEDetailCoatings = async (): Promise<IPMEDetailCoating[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidK4`);
  return data;
};

export const getPMEProcessAutomations = async (): Promise<IPMEProductionArea[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidK5`);
  return data;
};

export const getPMETechnologicalProcesses = async (
  calculationTypeId: string | number,
  productionAreaId: string | number,
  degreasingTypeId: string | number,
  operationTypeId: string | number,
): Promise<IPMETechnologicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechProcess`, {
    params: {
      vidRaschetaId: calculationTypeId,
      uchastokProizvodstvaId: productionAreaId,
      obezzhirivaniyeId: degreasingTypeId,
      operaciyaId: operationTypeId,
    },
  });
  return data;
};

export const getPMETechnologicalOperations = async (
  calculationTypeId: string | number,
  productionAreaId: string | number,
  degreasingTypeId: string | number,
  operationTypeId: string | number,
  technologicalProcess: string | number,
): Promise<IPMETechnologicalOperation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechOperacii`, {
    params: {
      vidRaschetaId: calculationTypeId,
      uchastokProizvodstvaId: productionAreaId,
      vidObezzhirivaniyaId: degreasingTypeId,
      vidOperaciiId: operationTypeId,
      techProcessId: technologicalProcess,
    },
  });
  return data;
};

export const getPMEMaterialCompositions = async (
  calculationTypeId: string | number,
  productionAreaId: string | number,
  degreasingTypeId: string | number,
  operationTypeId: string | number,
  technologicalOperation: string | number,
  technologicalProcess: string | number,
): Promise<IPMETechnologicalOperation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSostav`, {
    params: {
      vidRascheta: calculationTypeId,
      uchastokProizvodstvaId: productionAreaId,
      vidObezzirivaniya: degreasingTypeId,
      vidOperacii: operationTypeId,
      techProcessId: technologicalProcess,
      techOperaciyaId: technologicalOperation,
    },
  });
  return data;
};

export const getPMEDegreasingTypes = async (): Promise<IPMEDegreasingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidObezzhirivaniya`);
  return data;
};

export const getPMEOperationTypes = async (
  calculationTypeId: string | number,
  degreasingTypeId: string | number,
): Promise<IPMEOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOperacii`, {
    params: { vidRaschetaId: calculationTypeId, vidObezzhirivaniyaId: degreasingTypeId },
  });
  return data;
};

export const getPMEVariables = async (
  calculationTypeId: string | number,
  processingTypeId: string | number,
  degreasingTypeId: string | number,
): Promise<IPMEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidraschetaId: calculationTypeId,
      vidObrabotkiId: processingTypeId,
      vidObezzhirivaniyaId: degreasingTypeId,
    },
  });
  return data;
};

export const getPMEFullReport = async (reportData: IPMEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    processingTypeId,
    productionAreaId,
    bathShelterId,
    detailCoatingId,
    processAutomationId,
    technologicalProcessId,
    technologicalOperationId,
    materialCompositionId,
    degreasingTypeId,
    operationTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: processingTypeId,
      paramName: 'VidObrabotkiId',
    },
    {
      id: productionAreaId,
      paramName: 'VidUchastokProizvodstvaId',
    },
    {
      id: degreasingTypeId,
      paramName: 'VidObezzhirivaniyaId',
    },
    {
      id: operationTypeId,
      paramName: 'OperaciyaId',
    },
    {
      id: bathShelterId,
      paramName: 'K1Id',
    },
    {
      id: detailCoatingId,
      paramName: 'K4Id',
    },
    {
      id: processAutomationId,
      paramName: 'K5Id',
    },
    {
      id: processAutomationId,
      paramName: 'K5Id',
    },
    {
      id: technologicalProcessId,
      paramName: 'TechProcessId',
    },
    {
      id: technologicalOperationId,
      paramName: 'TechOperaciyaId',
    },
    {
      id: materialCompositionId,
      paramName: 'SostavId',
    },
    ...variables,
  ]);
  return data;
};
