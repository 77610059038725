import React from 'react';
import { Form, Col, Row } from 'antd';
import { useQuery } from 'react-query';
import {
  getTGCBusTypes,
  getTGCEngineDisplacements,
  getTGCEngineTypes,
  getTGCFuelTypes,
  getTGCFullReport,
  getTGCHeatingTypes,
  getTGCInjectionCarburetor,
  getTGCLiftingCapacities,
  getTGCNeutralizerTypes,
  getTGCSources,
  getTGCVariables,
} from '../../../../api/ToxicityGasControl';
import { IMethodologyModule, ITGCFormValues } from '../../interfaces';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const ToxicityGasControl: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const sources = useQuery('TGCSources', getTGCSources);
  const engineTypes = useQuery('TGCEngineTypes', () => getTGCEngineTypes(form.getFieldValue('source')));
  const fuelTypes = useQuery('TGCFuelTypes', () => getTGCFuelTypes(form.getFieldValue('engine_type')));
  const engineDisplacements = useQuery('TGCEngineDisplacements', getTGCEngineDisplacements);
  const injectionCarburetor = useQuery('TGCInjectionCarburetor', getTGCInjectionCarburetor);
  const neutralizerTypes = useQuery('TGCNeutralizerTypes', () => getTGCNeutralizerTypes(form.getFieldValue('source')));
  const heatingTypes = useQuery('TGCHeatingTypes', getTGCHeatingTypes);
  const liftingCapacities = useQuery('TGCLiftingCapacities', () =>
    getTGCLiftingCapacities(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const busTypes = useQuery('TGCBusTypes', () =>
    getTGCBusTypes(form.getFieldValue('source'), form.getFieldValue('engine_type')),
  );
  const variables = useQuery('TGCVariables', getTGCVariables);

  const onFinish = (values: ITGCFormValues) => {
    const {
      heating_type,
      source,
      lifting_capacity,
      engine_type,
      injection_carburetor,
      bus_type,
      neutralizer_type,
      engine_displacement,
      fuel_type,
    } = values;

    if (variables.data) {
      getFormResult(getTGCFullReport, {
        sourceId: source,
        engineTypeId: engine_type,
        liftingCapacityId: lifting_capacity || 1,
        injectionCarburetorId: injection_carburetor || 1,
        busTypeId: bus_type || 1,
        neutralizerTypeId: neutralizer_type || 1,
        engineDisplacementId: engine_displacement,
        fuelTypeId: fuel_type,
        heatingId: heating_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeSource = () => {
    engineTypes.refetch();
    neutralizerTypes.refetch();
  };

  const onChangeEngineType = () => {
    fuelTypes.refetch();
    liftingCapacities.refetch();
    busTypes.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={sources.data ? sources.data : []}
            name="source"
            cyPrefix="TGC"
            label="Передвежные источники"
            onChange={onChangeSource}
            defaultValue={1}
          />
          <FormBaseSelect
            data={engineTypes.data ? engineTypes.data : []}
            name="engine_type"
            cyPrefix="TGC"
            label="Вид двигателя"
            defaultValue={1}
            onChange={onChangeEngineType}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="TGC"
            label="Вид топлива"
            defaultValue={1}
          />
          <FormBaseSelect
            data={engineDisplacements.data ? engineDisplacements.data : []}
            name="engine_displacement"
            cyPrefix="TGC"
            label="Рабочий обьем двигателя"
            defaultValue={1}
          />
          {form.getFieldValue('engine_type') == 2 ? (
            <FormBaseSelect
              data={injectionCarburetor.data ? injectionCarburetor.data : []}
              name="injection_carburetor"
              cyPrefix="TGC"
              label="Впрыск или карбюратор"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('engine_type') == 4 ? (
            <FormBaseSelect
              data={neutralizerTypes.data ? neutralizerTypes.data : []}
              name="neutralizer_type"
              cyPrefix="TGC"
              label="Вид сертифицированного каталитического нейтрализатора"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={heatingTypes.data ? heatingTypes.data : []}
            name="heating_type"
            cyPrefix="TGC"
            label="Наличие подогрева"
            defaultValue={1}
          />
          {form.getFieldValue('source') == 3 || form.getFieldValue('source') == 4 ? (
            <FormBaseSelect
              data={liftingCapacities.data ? liftingCapacities.data : []}
              name="lifting_capacity"
              cyPrefix="TGC"
              label="Грузоподъемность"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          {form.getFieldValue('source') == 5 || form.getFieldValue('source') == 6 ? (
            <FormBaseSelect
              data={busTypes.data ? busTypes.data : []}
              name="bus_type"
              cyPrefix="TGC"
              label="Класс автобуса"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TGC"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
