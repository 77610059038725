import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { ILFEAnimalType, ILFECalculationType, ILFEStorageType, ILFEVariables } from './interfaces';

const START_URL = 'ForthCatZhivotnovodstvoClass';

export const getLFECalculationTypes = async (): Promise<ILFECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRaschetaZhivotnoe`);
  return data;
};

export const getLFEAnimalTypes = async (): Promise<ILFEAnimalType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetZhivotnoeName`);
  return data;
};

export const getLFEStorageTypes = async (): Promise<ILFEStorageType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMestHranNavoza`);
  return data;
};

export const getLFEVariables = async (
  calculationTypeId: string | number,
  storageTypeId: string | number,
): Promise<ILFEVariables[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRaschetaZhivotnoe: calculationTypeId, mestHranNavoza: storageTypeId },
  });
  return data;
};

export const getLFEFullReport = async (reportData: {
  calculationTypeId: string | number;
  animalTypeId: string | number;
  storageTypeId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { calculationTypeId, animalTypeId, storageTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponce`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaZhivotnoe',
    },
    {
      id: animalTypeId,
      paramName: 'ZhivotnoeName',
    },
    {
      id: storageTypeId,
      paramName: 'MestHranNavoza',
    },
    ...variables,
  ]);
  return data;
};
