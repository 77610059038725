import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IPEBoilerHeatingType,
  IPECalculationType,
  IPECatalystType,
  IPECleaningSystem,
  IPEClimaticZone,
  IPEEmission,
  IPEFuel,
  IPEFuelType,
  IPEInstallationName,
  IPEMechanicalCleaningObject,
  IPEObjectShelterDegrees,
  IPEObjectShelterPercentage,
  IPEOilName,
  IPEOperatingMode,
  IPEOvenType,
  IPEReportData,
  IPETankDesign,
  IPEVacuumSystem,
  IPEVariable,
  IPEVehicleGroup,
  IPEVehicleYear,
  IPEVentilationSystemType,
  IPEWaterSupplySystem,
} from './interfaces';

const START_URL = 'NeftepererabotkaClass';

export const getPECalculationTypes = async (): Promise<IPECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getPEEmissions = async (calculationTypeId: string | number): Promise<IPEEmission[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVybrosa`, { params: { vidRaschetaId: calculationTypeId } });
  return data;
};

export const getPEOilNames = async (
  calculationTypeId: string | number,
  emissionsId: string | number,
): Promise<IPEOilName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVybrosa`, {
    params: { vidRaschetaId: calculationTypeId, vidVybrosaId: emissionsId },
  });
  return data;
};

export const getPEOperatingModes = async (): Promise<IPEOperatingMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUseMode`);
  return data;
};

export const getPETankDesigns = async (operatingModeId: string | number): Promise<IPETankDesign[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKonstrukciaRezervuara`, {
    params: { useModeId: operatingModeId },
  });
  return data;
};

export const getPEClimaticZones = async (): Promise<IPEClimaticZone[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetClimaticZone`);
  return data;
};

export const getPECleaningSystems = async (emissionsId: string | number): Promise<IPECleaningSystem[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSistema`, { params: { vidVybrosaId: emissionsId } });
  return data;
};

export const getPEObjectShelterDegrees = async (): Promise<IPEObjectShelterDegrees[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetStepenUkr`);
  return data;
};

export const getPEObjectShelterPercentages = async (): Promise<IPEObjectShelterPercentage[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetProcentUkr`);
  return data;
};

export const getPEMechanicalCleaningObjects = async (
  cleaningSystemId: string | number,
): Promise<IPEMechanicalCleaningObject[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMehOch`, { params: { sistemaId: cleaningSystemId } });
  return data;
};

export const getPEWaterSupplySystems = async (): Promise<IPEWaterSupplySystem[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSistema2`);
  return data;
};

export const getPEFuelTypes = async (): Promise<IPEFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getPEFuels = async (): Promise<IPEFuel[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetToplivo`);
  return data;
};

export const getPEInstallationNames = async (): Promise<IPEInstallationName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUstanovka`);
  return data;
};

export const getPEBoilerHeatingTypes = async (): Promise<IPEBoilerHeatingType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNagrevKotlov`);
  return data;
};

export const getPEVacuumSystems = async (): Promise<IPEVacuumSystem[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVacuumSystem`);
  return data;
};

export const getPECatalystTypes = async (): Promise<IPECatalystType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKatalizatora`);
  return data;
};

export const getPETechnologicalInstallations = async (
  calculationTypeId: string | number,
): Promise<IPECatalystType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTehUst`, { params: { vidRaschetaId: calculationTypeId } });
  return data;
};

export const getPEVentilationSystemTypes = async (): Promise<IPEVentilationSystemType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVentSystem`);
  return data;
};

export const getPEOvenTypes = async (): Promise<IPEOvenType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPech`);
  return data;
};

export const getPEVehicleGroups = async (): Promise<IPEVehicleGroup[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGrupAvto`);
  return data;
};

export const getPEVehicleYears = async (): Promise<IPEVehicleYear[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGodAvto`);
  return data;
};

export const getPETimeIntervals = async (): Promise<IPEVehicleYear[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGodKvartal`);
  return data;
};

export const getPEVariables = async (
  calculationTypeId: string | number,
  emissionsId: string | number,
  fuelTypeId: string | number,
  fuelId: string | number,
  timeIntervalId: string | number,
): Promise<IPEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGodKvartal`, {
    params: {
      vidRaschetaId: calculationTypeId,
      vidVybrosaId: emissionsId,
      vidToplivaId: fuelTypeId,
      toplivoId: fuelId,
      godKvartal: timeIntervalId,
    },
  });
  return data;
};

export const getPEFullReport = async (reportData: IPEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    emissionsId,
    oilNameId,
    tankDesignId,
    climaticZoneId,
    operatingModes,
    cleaningSystemId,
    objectShelterDegreeId,
    objectShelterPercentagesId,
    mechanicalCleaningObjectId,
    waterSupplySystemId,
    fuelTypeId,
    fuelId,
    installationNameId,
    boilerHeatingTypeId,
    vacuumSystemId,
    catalystTypeId,
    technologicalInstallationId,
    ventilationSystemTypeId,
    ovenTypeId,
    vehicleGroupId,
    vehicleYearId,
    timeIntervals,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      value: 1,
      paramName: 'VidRaschetaId',
    },
    {
      id: emissionsId,
      value: 1,
      paramName: 'VidVybrosaId',
    },
    {
      id: oilNameId,
      value: 1,
      paramName: 'NazvanieId',
    },
    {
      id: tankDesignId,
      value: 1,
      paramName: 'KonstrukciaRezervuaraId',
    },
    {
      id: operatingModes,
      value: 1,
      paramName: 'UseModeId',
    },
    {
      id: climaticZoneId,
      value: 1,
      paramName: 'ClimaticZoneId',
    },
    {
      id: cleaningSystemId,
      value: 1,
      paramName: 'SistemaId',
    },
    {
      id: objectShelterPercentagesId,
      value: 1,
      paramName: 'ProcentUkrId',
    },
    {
      id: objectShelterDegreeId,
      value: 1,
      paramName: 'StepenUkrId',
    },
    {
      id: mechanicalCleaningObjectId,
      value: 1,
      paramName: 'MehOchId',
    },
    {
      id: waterSupplySystemId,
      value: 1,
      paramName: 'Sistema2Id',
    },
    {
      id: fuelTypeId,
      value: 1,
      paramName: 'VidToplivaId',
    },
    {
      id: fuelId,
      value: 1,
      paramName: 'ToplivoId',
    },
    {
      id: installationNameId,
      value: 1,
      paramName: 'UstanovkaId',
    },
    {
      id: boilerHeatingTypeId,
      value: 1,
      paramName: 'NagrevKotlovId',
    },
    {
      id: vacuumSystemId,
      value: 1,
      paramName: 'VacuumSystemId',
    },
    {
      id: catalystTypeId,
      value: 1,
      paramName: 'KatalizatorId',
    },
    {
      id: technologicalInstallationId,
      value: 1,
      paramName: 'TehUstId',
    },
    {
      id: ventilationSystemTypeId,
      value: 1,
      paramName: 'VentSystemId',
    },
    {
      id: ovenTypeId,
      value: 1,
      paramName: 'PechId',
    },
    {
      id: vehicleGroupId,
      value: 1,
      paramName: 'GrupAvtoId',
    },
    {
      id: vehicleYearId,
      value: 1,
      paramName: 'GodAvtoId',
    },
    {
      id: timeIntervals,
      value: 1,
      paramName: 'GodKvartal',
    },
    ...variables,
  ]);
  return data;
};
