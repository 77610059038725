import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IBPEAirSupply,
  IBPEAshCatcherType,
  IBPEBoilerType,
  IBPECalculationType,
  IBPECleaningSurface,
  IBPEHeatLoss,
  IBPENetherHeat,
  IBPEReportData,
  IBPESprayingOil,
  IBPEVariable,
} from './interfaces';

const START_URL = 'BenzapirenClass';

export const getBPECalculationTypes = async (): Promise<IBPECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getBPEFuelTypes = async (): Promise<IBPECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`);
  return data;
};

export const getBPEAshCatcherTypes = async (): Promise<IBPEAshCatcherType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZoloulovitelya`);
  return data;
};

export const getBPEBoilerTypes = async (): Promise<IBPEBoilerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipKotloagregata`);
  return data;
};

export const getBPEAirSupplies = async (): Promise<IBPEAirSupply[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipKotloagregata`);
  return data;
};

export const getBPEHeatLosses = async (): Promise<IBPEHeatLoss[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipTopkiQ`);
  return data;
};

export const getBPESprayingOils = async (): Promise<IBPESprayingOil[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSposobRaspylivaniya`);
  return data;
};

export const getBPECleaningSurfaces = async (): Promise<IBPECleaningSurface[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDrobevOchistka`);
  return data;
};

export const getBPENetherHeats = async (fuelTypeId: string | number): Promise<IBPENetherHeat[]> => {
  let response;

  switch (fuelTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetMestorojConstGaz`);
      break;
    case 2 || 3 || 4:
      response = await apiClient.get(`${START_URL}/GetMestorojConstZhidkoe`);
      break;
    case 7 || 8:
      response = await apiClient.get(`${START_URL}/GetMestorojConstTverdoe`);
      break;
  }
  return response?.data;
};

export const getBPEVariables = async (
  calculationTypeId: string | number,
  fuelTypeId: string | number,
): Promise<IBPEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidraschetaId: calculationTypeId, dataBaseVidToplivaId: fuelTypeId },
  });
  return data;
};

export const getBPEFullReport = async (reportData: IBPEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    fuelTypeId,
    ashCatcherId,
    boilerTypeId,
    airSupplyId,
    heatLossId,
    sprayingOil,
    cleaningSurfaceId,
    netherHeatId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculationTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: fuelTypeId,
      paramName: 'DataBaseVidToplivaId',
    },
    {
      id: heatLossId,
      paramName: 'DataBaseTipTopkiQ4Id',
    },
    {
      id: netherHeatId,
      paramName: 'DataBaseMestorojId',
    },
    {
      id: boilerTypeId,
      paramName: 'TipKotloagregata',
    },
    {
      id: ashCatcherId,
      paramName: 'DataBaseVidZoloulId',
    },
    {
      id: airSupplyId,
      paramName: 'MestoPodachi',
    },
    {
      id: sprayingOil,
      paramName: 'SposobRaspyl',
    },
    {
      id: cleaningSurfaceId,
      paramName: 'DrobevOchistka',
    },
    ...variables,
  ]);
  return data;
};
