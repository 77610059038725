import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import {
  getLFEAnimalTypes,
  getLFECalculationTypes,
  getLFEFullReport,
  getLFEStorageTypes,
  getLFEVariables,
} from '../../../../api/LivestockFacilitiesEmissions';

import { ILFEFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const LivestockFacilitiesEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('LFECalculationType', getLFECalculationTypes);
  const animalTypes = useQuery('LFEAnimalTypes', getLFEAnimalTypes);
  const storageTypes = useQuery('LFEStorageTypes', getLFEStorageTypes);
  const variables = useQuery('LFEVariables', () =>
    getLFEVariables(form.getFieldValue('calculation_type'), form.getFieldValue('storage_type')),
  );

  const onFinish = (values: ILFEFormValues) => {
    const { calculation_type, animal_type, storage_type } = values;

    if (variables.data) {
      getFormResult(getLFEFullReport, {
        calculationTypeId: calculation_type,
        animalTypeId: animal_type || 1,
        storageTypeId: storage_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="LFE"
            label="Вид расчета выбросов животноводства"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          {form.getFieldValue('calculation_type') === 1 ? (
            <FormBaseSelect
              data={animalTypes.data ? animalTypes.data : []}
              name="animal_type"
              cyPrefix="LFE"
              label="Наименование животного"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('calculation_type') === 2 ? (
            <FormBaseSelect
              data={storageTypes.data ? storageTypes.data : []}
              name="storage_type"
              cyPrefix="LFE"
              label="Вид навозохранилища"
              defaultValue={1}
              onChange={handleChangeVariables}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="LFE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
