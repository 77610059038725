import React from 'react';
import { useStore } from 'effector-react';
import { Select, Input, Space, Button } from 'antd';

import { PollutantsTable } from '../modules/Tables/PollutantsTable';
import { $fileNameState, setFileName } from '../modules/Methodologies/models/fileNameState';

import { methodologies } from '../configs/methodologies';

const { Option } = Select;

export const Main: React.FC = () => {
  const { fileName } = useStore($fileNameState);

  const [selectedMetodologyId, setSelectedMetodologyId] = React.useState(1);

  const onSelectChange = (newSelectValue: number) => {
    setSelectedMetodologyId(newSelectValue);
    setFileName(methodologies[newSelectValue - 1].name);
  };

  const RenderSelectedMethodologyComponent = React.useCallback(() => {
    const MethodologyComponent = methodologies[selectedMetodologyId - 1].component;
    return (
      <MethodologyComponent marginBetween="30px">
        <Button data-cy="submit-button" type="primary" htmlType="submit">
          Расчитать
        </Button>
      </MethodologyComponent>
    );
  }, [selectedMetodologyId]);

  React.useEffect(() => {
    setFileName(methodologies[selectedMetodologyId - 1].name);
  }, []);

  return (
    <div style={{ padding: '30px' }}>
      <Space style={{ marginBottom: '50px' }} direction="horizontal">
        <Select
          data-cy="methodology-select"
          defaultValue={selectedMetodologyId}
          onChange={(e) => onSelectChange(e)}
          showSearch
          style={{ width: '600px' }}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {methodologies.map((methodology) => (
            <Option data-cy={`methodology-option-${methodology.id}`} key={methodology.id} value={methodology.id}>
              {methodology.name}
            </Option>
          ))}
        </Select>
        <Input
          data-cy="methodology-input"
          placeholder="Наименование источника выбросов"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          style={{ width: '350px', marginLeft: '10px' }}
        />
      </Space>
      <RenderSelectedMethodologyComponent />
      <PollutantsTable />
    </div>
  );
};
