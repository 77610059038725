import React from 'react';
import { Form, Row, Col } from 'antd';
import { IFDFEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getFDFECalculationTypes,
  getFDFECurrentProcessStages,
  getFDFEDensities,
  getFDFEDustySurfaces,
  getFDFEFullReport,
  getFDFEHumidities,
  getFDFEMachineTypes,
  getFDFEOperationTypes,
  getFDFEProcessNames,
  getFDFEProcessStages,
  getFDFEProductionTypes,
  getFDFETemperatures,
  getFDFEVariables,
} from '../../../../api/FinishedDosageFormsEmissions';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const FinishedDosageFormsEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const productionTypes = useQuery('FDFEProductionTypes', getFDFEProductionTypes);
  const processNames = useQuery('FDFEProcessNames', () => getFDFEProcessNames(form.getFieldValue('production_type')));
  const processStages = useQuery('FDFEProcessStages', () =>
    getFDFEProcessStages(form.getFieldValue('production_type'), form.getFieldValue('process_name')),
  );
  const operationTypes = useQuery('FDFEOperationTypes', () =>
    getFDFEOperationTypes(form.getFieldValue('process_stages'), form.getFieldValue('process_name')),
  );
  const humidities = useQuery('FDFEHumidities', getFDFEHumidities);
  const temperatures = useQuery('FDFETemperatures', getFDFETemperatures);
  const densities = useQuery('FDFEDensities', getFDFEDensities);
  const currentProcessStages = useQuery('FDFECurrentProcessStages', getFDFECurrentProcessStages);
  const machineTypes = useQuery('FDFEMachineTypes', getFDFEMachineTypes);
  const calculationTypes = useQuery('FDFECalculationTypes', getFDFECalculationTypes);
  const dustySurfaces = useQuery('FDFEDustySurfaces', getFDFEDustySurfaces);
  const variables = useQuery('FDFEVariables', () =>
    getFDFEVariables(
      form.getFieldValue('operation_type'),
      form.getFieldValue('current_process_stage'),
      form.getFieldValue('calculation_type'),
      form.getFieldValue('dusty_surface'),
    ),
  );

  const onFinish = (values: IFDFEFormValues) => {
    const {
      production_type,
      process_name,
      process_stage,
      operation_type,
      dusty_surface,
      humidity,
      temperature,
      density,
      calculation_type,
      current_process_stage,
      machine_type,
    } = values;

    if (variables.data) {
      getFormResult(getFDFEFullReport, {
        productionTypeId: production_type,
        processNameId: process_name,
        processStageId: process_stage,
        operationTypeId: operation_type,
        humidityId: humidity,
        temperatureId: temperature,
        densityId: density,
        currentProccesStageId: current_process_stage,
        machineTypeId: machine_type,
        calculationTypeId: calculation_type,
        dustySurface: dusty_surface,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeProductionType = () => {
    processNames.refetch();
    processStages.refetch();
  };

  const onChangeProcessName = () => {
    processStages.refetch();
    operationTypes.refetch();
  };

  const onChangeProcessStage = () => {
    operationTypes.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={productionTypes.data ? productionTypes.data : []}
            name="production_type"
            cyPrefix="FDFE"
            label="Вид производства"
            defaultValue={1}
            onChange={onChangeProductionType}
          />
          <FormBaseSelect
            data={processNames.data ? processNames.data : []}
            name="process_name"
            cyPrefix="FDFE"
            label="Наименование процесса"
            defaultValue={1}
            onChange={onChangeProcessName}
          />
          <FormBaseSelect
            data={processStages.data ? processStages.data : []}
            name="process_stage"
            cyPrefix="FDFE"
            label="Стадия процесса"
            defaultValue={1}
            onChange={onChangeProcessStage}
          />
          <FormBaseSelect
            data={operationTypes.data ? operationTypes.data : []}
            name="operation_type"
            cyPrefix="FDFE"
            label="Тип операции"
            defaultValue={5}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={dustySurfaces.data ? dustySurfaces.data : []}
            name="dusty_surface"
            cyPrefix="FDFE"
            label="Площадь пылящей поверхности"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={humidities.data ? humidities.data : []}
            name="humidity"
            cyPrefix="FDFE"
            label="Влажность воздуха"
            defaultValue={1}
          />
          <FormBaseSelect
            data={temperatures.data ? temperatures.data : []}
            name="temperature"
            cyPrefix="FDFE"
            label="Температура воздуха"
            defaultValue={1}
          />
          <FormBaseSelect
            data={densities.data ? densities.data : []}
            name="density"
            cyPrefix="FDFE"
            label="Плотность воздуха"
            defaultValue={1}
          />
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="FDFE"
            label="Вид расчета выброса"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={currentProcessStages.data ? currentProcessStages.data : []}
            name="current_process_stage"
            cyPrefix="FDFE"
            label="Намименование выполняемой стадии процесса"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          <FormBaseSelect
            data={machineTypes.data ? machineTypes.data : []}
            name="machine_type"
            cyPrefix="FDFE"
            label="Тип машины"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="FDFE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
