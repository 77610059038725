import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getBWBatteryTypes,
  getBWElectrolyteTypes,
  getBWFullReport,
  getBWTechnologicalOperations,
  getBWTechnologicalProcesses,
  getBWVariables,
} from '../../../../api/BatteryWork';

import { IBWFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const BatterWork: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const technologicalProcesses = useQuery('BWTechnologicalProcesses', getBWTechnologicalProcesses);
  const technologicalOperations = useQuery('BWTechnologicalOperations', getBWTechnologicalOperations);
  const electrolyteTypes = useQuery('BWElectrolyteTypes', getBWElectrolyteTypes);
  const batteryTypes = useQuery('BWBatteryTypes', getBWBatteryTypes);
  const variables = useQuery('BWVariables', () => getBWVariables(form.getFieldValue('technological_process')));

  const onFinish = (values: IBWFormValues) => {
    const { technological_operation, technological_process, electrolyte_type, battery_type } = values;

    if (variables.data) {
      getFormResult(getBWFullReport, {
        technologicalProcessId: technological_process,
        technologicalOperationId: technological_operation || 1,
        electrolyteTypeId: electrolyte_type || 1,
        batteryTypeId: battery_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeTechnologicalType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={technologicalProcesses.data ? technologicalProcesses.data : []}
            name="technological_process"
            cyPrefix="BW"
            label="Технологический процесс"
            defaultValue={1}
            onChange={onChangeTechnologicalType}
          />
          {form.getFieldValue('technological_process') === 1 ? (
            <FormBaseSelect
              data={technologicalOperations.data ? technologicalOperations.data : []}
              name="technological_operation"
              cyPrefix="BW"
              label="Технологическая операция"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('technological_process') === 3 ? (
            <FormBaseSelect
              data={electrolyteTypes.data ? electrolyteTypes.data : []}
              name="electrolyte_type"
              cyPrefix="BW"
              label="Вид электролита"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('technological_process') === 4 ? (
            <FormBaseSelect
              data={batteryTypes.data ? batteryTypes.data : []}
              name="battery_type"
              cyPrefix="BW"
              label="Вид аккумуляторов"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BW"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
