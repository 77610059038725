import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ILUOEBreed,
  ILUOEBulldozerMark,
  ILUOECalculationType,
  ILUOEEquipmentMark,
  ILUOEEquipmentName,
  ILUOEOperatingMode,
  ILUOEPollutant,
  ILUOEReportData,
  ILUOERockFortress,
  ILUOESpecialEquipment,
  ILUOEVariable,
  ILUOEWindSpeed,
  ILUOEWorkingEquipment,
} from './interfaces';

const START_URL = 'PogruzRazgruzRabotyClass';

export const getLUOESpecialEquipments = async (): Promise<ILUOESpecialEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSpectehniki`);
  return data;
};

export const getLUOEEquipmentNames = async (): Promise<ILUOEEquipmentName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNameOborud`);
  return data;
};

export const getLUOECalculationTypes = async (): Promise<ILUOECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getLUOEEquipmentMarks = async (equipmentNameId: string | number): Promise<ILUOEEquipmentMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetEskavator`, { params: { nameOborud: equipmentNameId } });
  return data;
};

export const getLUOEBreeds = async (
  specialEquipmentId: string | number,
  equipmentNameId: string | number,
): Promise<ILUOEBreed[]> => {
  let response;

  switch (specialEquipmentId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetPoroda`, { params: { nameOborud: equipmentNameId } });
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetPorodaBul`);
      break;
  }

  return response?.data;
};

export const getLUOERockFortresses = async (
  specialEquipmentId: string | number,
  breedId: string | number,
  equipmentNameId: string | number,
): Promise<ILUOERockFortress[]> => {
  let response;

  switch (specialEquipmentId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetKrepost`, {
        params: { poroda: breedId, nameOborud: equipmentNameId },
      });
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetKrepostBul`, {
        params: { porodaBul: breedId },
      });
      break;
  }

  return response?.data;
};

export const getLUOEWorkingEquipments = async (): Promise<ILUOEWorkingEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRabOborud`);
  return data;
};

export const getLUOEExcavationDifficulties = async (
  workingEquipmentId: string | number,
): Promise<ILUOEWorkingEquipment[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKategoriya`, { params: { vidRabOborud: workingEquipmentId } });
  return data;
};

export const getLUOEWindSpeeds = async (): Promise<ILUOEWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetK1`);
  return data;
};

export const getLUOEHumidities = async (): Promise<ILUOEWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetK2`);
  return data;
};

export const getLUOEBulldozerMarks = async (): Promise<ILUOEBulldozerMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetMarkaBuldozera`);
  return data;
};

export const getLUOEPollutants = async (): Promise<ILUOEPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetZV`);
  return data;
};

export const getLUOEOperatingModes = async (): Promise<ILUOEOperatingMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRezhimRaboty`);
  return data;
};

export const getLUOEVariables = async (
  specialEquipmentId: string | number,
  equipmentNameId: string | number,
  calculationTypeId: string | number,
  equipmentMarkId: string | number,
): Promise<ILUOEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidSpectehniki: specialEquipmentId,
      nameOborud: equipmentNameId,
      vidRascheta: calculationTypeId,
      dBEskavator: equipmentMarkId,
    },
  });
  return data;
};

export const getLUOEFullReport = async (reportData: ILUOEReportData): Promise<IToolsReport[]> => {
  const {
    specialEquipmentId,
    equipmentNameId,
    calculationTypeId,
    equipmentMarkId,
    breedId,
    rockFortressId,
    workingEquipmentId,
    excavationDifficultyId,
    windSpeed,
    humidityId,
    bulldozerMarkId,
    pollutantId,
    operatingMode,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: specialEquipmentId,
      paramName: 'VidSpectehniki',
    },
    {
      id: rockFortressId,
      paramName: 'Krepost',
    },
    {
      id: rockFortressId,
      paramName: 'KrepostUg',
    },
    {
      id: equipmentMarkId,
      paramName: 'DBEskavator',
    },
    {
      id: breedId,
      paramName: 'DBPorody',
    },
    {
      id: breedId,
      paramName: 'Poroda',
    },
    {
      id: workingEquipmentId,
      paramName: 'VidRabOborud',
    },
    {
      id: excavationDifficultyId,
      paramName: 'DBKategoriya',
    },
    {
      id: windSpeed,
      paramName: 'K1',
    },
    {
      id: humidityId,
      paramName: 'K2',
    },
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: bulldozerMarkId,
      paramName: 'MarkaBuldozera',
    },
    {
      id: breedId,
      paramName: 'PorodaBul',
    },
    {
      id: rockFortressId,
      paramName: 'KrepostBul',
    },
    {
      id: operatingMode,
      paramName: 'RezhimRaboty',
    },
    {
      id: pollutantId,
      paramName: 'Zv',
    },
    {
      id: equipmentNameId,
      paramName: 'NameOborud',
    },
    ...variables,
  ]);
  return data;
};
