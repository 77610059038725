import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { ICWAppliedMaterial, ICWCalculationType, ICWReportData, ICWVariable } from './interfaces';

const START_URL = 'AutoMednitskyLibrary';

export const getCWCalculationTypes = async (): Promise<ICWCalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getCWAppliedMaterials = async (calculationTypeId: string | number): Promise<ICWAppliedMaterial[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPrimenyaemieMaterialy`, {
    params: { vidRaschetaId: calculationTypeId },
  });
  return data;
};

export const getCWVariables = async (calculationTypeId: string | number): Promise<ICWVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRaschetaId: calculationTypeId },
  });
  return data;
};

export const getCWFullReport = async (reportData: ICWReportData): Promise<IToolsReport[]> => {
  const { calculatoinTypeId, appliedMaterialId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: calculatoinTypeId,
      paramName: 'VidRaschetaId',
    },
    {
      id: appliedMaterialId,
      paramName: 'PrimenyaemieMaterialyId',
    },
    ...variables,
  ]);
  return data;
};
