import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ITSGDiametr,
  ITSGDischargeType,
  ITSGFuelName,
  ITSGFurnaceType,
  ITSGGasCompressibilityFactor,
  ITSGOrganosulfur,
  ITSGReportData,
  ITSGSteamSupply,
  ITSGUnit,
  ITSGVariable,
} from './interfaces';

const START_URL = 'HraneniegazaClass';

export const getTSGDischargeTypes = async (): Promise<ITSGDischargeType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVybrosa`);
  return data;
};

export const getTSGOrganosulfurs = async (): Promise<ITSGOrganosulfur[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSeroorganikaId`);
  return data;
};

export const getTSGDiametrs = async (): Promise<ITSGDiametr[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDiametr`);
  return data;
};

export const getTSGFuelNames = async (): Promise<ITSGFuelName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNazvanie`);
  return data;
};

export const getTSGSteamSupplies = async (): Promise<ITSGSteamSupply[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPodachaPara`);
  return data;
};

export const getTSGGasCompressibilityFactors = async (): Promise<ITSGGasCompressibilityFactor[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffZ`);
  return data;
};

export const getTSGUnits = async (): Promise<ITSGUnit[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTGod`);
  return data;
};

export const getTSGFurnaceTypes = async (): Promise<ITSGFurnaceType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetТипТопки`);
  return data;
};

export const getTSGVariables = async (
  dischargeType: string | number,
  organosulfurId: string | number,
): Promise<ITSGVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidVybrosaId: dischargeType,
      seroorganikaId: organosulfurId,
      rejimVvodaId: 1,
    },
  });
  return data;
};

export const getTSGFullReport = async (reportData: ITSGReportData): Promise<IToolsReport[]> => {
  const {
    diametrId,
    organosulfurId,
    dischargeTypeId,
    fuelNameId,
    steamSupplyId,
    gasCompressibilityFactorId,
    unitId,
    furnaceTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, {
    list: [
      {
        id: dischargeTypeId,
        paramName: 'VidVybrosaId',
      },
      {
        id: 1,
        paramName: 'KolVeshestvId',
      },
      {
        id: diametrId,
        paramName: 'diametrId',
      },
      {
        id: fuelNameId,
        paramName: 'NazvanieId',
      },
      {
        id: steamSupplyId,
        paramName: 'PodachaparaId',
      },
      {
        id: organosulfurId,
        paramName: 'SeroorganikaId',
      },
      {
        id: furnaceTypeId,
        paramName: 'ТипТопки',
      },
      {
        id: unitId,
        paramName: 'TGod',
      },
      {
        id: gasCompressibilityFactorId,
        paramName: 'KoeffZ',
      },
      ...variables,
    ],
    listVeshestvo: [],
  });
  return data;
};
