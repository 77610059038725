import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IDSOOperationType, IDSOVariable } from './interfaces';

const START_URL = 'ZhDTransportSushkaPeskaClass';

export const getDSOOperationTypes = async (): Promise<IDSOOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOperacii`);
  return data;
};

export const getDSOVariables = async (): Promise<IDSOVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getDSOFullReport = async (reportData: {
  operationType: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { operationType, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: operationType,
      paramName: 'VidOperacii',
    },
    ...variables,
  ]);
  return data;
};
