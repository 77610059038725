import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getCIEAdditionalCleanings,
  getCIECleanings,
  getCIEConsumptions,
  getCIEFullReport,
  getCIEMachineTypes,
  getCIEMeanNames,
  getCIEMeanTypes,
  getCIEPollutants,
  getCIEVariables,
} from '../../../../api/CleaningIndustryEmissions';

import { ICIEFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const CleaningIndustryEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const meanTypes = useQuery('CIEMeanTypes', getCIEMeanTypes);
  const meanNames = useQuery('CIEMeanNames', () => getCIEMeanNames(form.getFieldValue('mean_type')));
  const pollutants = useQuery('CIEPollutants', getCIEPollutants);
  const machineTypes = useQuery('CIEMachineTypes', getCIEMachineTypes);
  const cleanings = useQuery('CIECleanings', getCIECleanings);
  const additionalCleanings = useQuery('CIEAdditionalCleanings', getCIEAdditionalCleanings);
  const consumptions = useQuery('CIEConsupmtions', () => getCIEConsumptions(form.getFieldValue('machine_type')));
  const variables = useQuery('CIEVariables', () =>
    getCIEVariables(form.getFieldValue('cleaning'), form.getFieldValue('additional_cleaning')),
  );

  const onFinish = (values: ICIEFormValues) => {
    const { mean_name, mean_type, pollutant, machine_type, additional_cleaning, cleaning, consumption } = values;

    if (variables.data) {
      getFormResult(getCIEFullReport, {
        meanTypeId: mean_type,
        meanNameId: mean_name,
        pollutantId: pollutant,
        cleaningId: cleaning,
        additionalCleaningId: additional_cleaning || 1,
        machineTypeId: machine_type,
        consumptionId: consumption,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };
  const onChangeMeanType = () => {
    meanNames.refetch();
  };

  const onChangeMachineType = () => {
    consumptions.refetch();
  };

  const onChangeCleaning = () => {
    variables.refetch();
  };

  const onChangeAdditionalCleaning = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={meanTypes.data ? meanTypes.data : []}
            name="mean_type"
            cyPrefix="CIE"
            label="Вид используемого средства"
            defaultValue={1}
            onChange={onChangeMeanType}
          />
          <FormBaseSelect
            data={meanNames.data ? meanNames.data : []}
            name="mean_name"
            cyPrefix="CIE"
            label="Наименование используемого средства"
            defaultValue={1}
          />
          <FormBaseSelect
            data={machineTypes.data ? machineTypes.data : []}
            name="machine_type"
            cyPrefix="CIE"
            label="Тип машины"
            defaultValue={1}
            onChange={onChangeMachineType}
          />
          <FormBaseSelect
            data={consumptions.data ? consumptions.data : []}
            name="consumption"
            cyPrefix="CIE"
            label="Норма расхода растворителя, г/кг"
            defaultValue={1}
          />
          <FormBaseSelect
            data={pollutants.data ? pollutants.data : []}
            name="pollutant"
            cyPrefix="CIE"
            label="Технологические и венциляционные выбросы объединены в один источник"
            defaultValue={1}
          />
          <FormBaseSelect
            data={cleanings.data ? cleanings.data : []}
            name="cleaning"
            cyPrefix="CIE"
            label="Предварительная очистка"
            defaultValue={1}
            onChange={onChangeCleaning}
          />
          {form.getFieldValue('cleaning') === 2 ? (
            <FormBaseSelect
              data={additionalCleanings.data ? additionalCleanings.data : []}
              name="additional_cleaning"
              cyPrefix="CIE"
              label="Дополнительная ступень очистки"
              defaultValue={1}
              onChange={onChangeAdditionalCleaning}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="CIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
