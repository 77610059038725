import React from 'react';
import { useQuery } from 'react-query';
import { Form, Col, Row, Select } from 'antd';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import {
  getBHSCCalculationTypes,
  getBHSCFuelTypes,
  getBHSCFurnaceTypes,
  getBHSCHeatLosses,
  getBHSCFuelAggregationState,
  getBHSCSulfurOxides,
  getBHSCVanadiumProportions,
  getBHSCFields,
  getBHSCVariables,
  getBHSCFullReport,
} from '../../../../api/BoilerHouseSmallClass';

import { IBHSCFormValues, ISelectedFuelState, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const BoilerHouseSmallClass: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const [selectedFuelState, setSelectedFuelState] = React.useState<ISelectedFuelState>({
    type: 'solid',
    name: '',
    id: 1,
  });

  const calculationTypes = useQuery('BHSCCalculationTypes', getBHSCCalculationTypes);
  const fuelTypes = useQuery('BHSCFuelTypes', getBHSCFuelTypes);
  const fields = useQuery('BHSCFields', getBHSCFields);
  const furnaceTypes = useQuery('BHSCFurnaceTypes', getBHSCFurnaceTypes);
  const heatLosses = useQuery('BHSCHeatLosses', getBHSCHeatLosses);
  const sulfurOxides = useQuery('BHSCSulfurOxides', getBHSCSulfurOxides);
  const vanadiumProportions = useQuery('BHSCVanadiumProportions', getBHSCVanadiumProportions);
  const variables = useQuery('BHSCVariables', () =>
    getBHSCVariables(form.getFieldValue('calculation_type'), form.getFieldValue('fuel_type')),
  );

  const onChangeFuelType = () => {
    variables.refetch();

    const selectedFuelId = form.getFieldValue('fuel_type');
    if (fuelTypes.data) {
      getBHSCFuelAggregationState(selectedFuelId).then((data) =>
        setSelectedFuelState({ type: data.type, name: fuelTypes.data[selectedFuelId - 1].name, id: selectedFuelId }),
      );
    }
  };

  const onChangeCalculationType = () => {
    variables.refetch();
  };

  const onFinish = (values: IBHSCFormValues) => {
    const { calculation_type, fuel_type, fields, heat_loss, furnace_type, sulfur_oxide, vanadium_proportion } = values;

    if (variables.data) {
      getFormResult(getBHSCFullReport, {
        calculationTypeId: calculation_type,
        fuelTypeId: fuel_type,
        fieldId: fields,
        heatLossId: heat_loss,
        furnaceTypeId: furnace_type,
        sulfurOxideId: sulfur_oxide,
        vanadiumProportion: vanadium_proportion || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            onChange={onChangeCalculationType}
            cyPrefix="BHSC"
            label="Расчет выполнить по"
            defaultValue={1}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            onChange={onChangeFuelType}
            cyPrefix="BHSC"
            label="Вид топлива"
            defaultValue={1}
          />
          <Form.Item name="fields" label="Месторождение" initialValue={1}>
            <Select data-cy="BHSC-fields-select">
              {fields.data
                ? fields.data[selectedFuelState.type].map((field) => (
                    <Option data-cy={`BHSC-fields-option-${field.id}`} key={field.id} value={field.id}>
                      {field.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <Form.Item name="furnace_type" label="Тип топки" initialValue={1}>
            <Select data-cy="BHSC-furnace_type-select">
              {furnaceTypes.data
                ? furnaceTypes.data.map((furnaceType) => (
                    <Option
                      data-cy={`BHSC-furnace_type-option-${furnaceType.id}`}
                      key={furnaceType.id}
                      value={furnaceType.id}
                    >
                      {`${furnaceType.tip} - Топливо: ${furnaceType.toplivo}`}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <Form.Item name="heat_loss" label="Потери тепла от механической неполноты сгорания топлива" initialValue={1}>
            <Select data-cy="BHSC-heat_loss-select">
              {heatLosses.data
                ? heatLosses.data.map((heatLoss) => (
                    <Option data-cy={`BHSC-heat_loss-option-${heatLoss.id}`} key={heatLoss.id} value={heatLoss.id}>
                      {heatLoss.toplivo}
                    </Option>
                  ))
                : []}
            </Select>
          </Form.Item>
          <FormBaseSelect
            data={sulfurOxides.data ? sulfurOxides.data : []}
            name="sulfur_oxide"
            cyPrefix="BHSC"
            label="Доля окислов серы"
            defaultValue={1}
          />
          {selectedFuelState.name == 'Мазут, нефть' ? (
            <FormBaseSelect
              data={vanadiumProportions.data ? vanadiumProportions.data : []}
              name="vanadium_proportion"
              cyPrefix="BHSC"
              label="Доля ванадия"
              defaultValue={1}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="BHSC"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
