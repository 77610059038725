import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import {
  getEEFOPAreas,
  getEEFOPClimaticZoneTypes,
  getEEFOPFullReport,
  getEEFOPVariables,
} from '../../api/EvaporationEarthenFuelOilPits';

import { IEEFOPFormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const EvaporationEathenFuelOilPits: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const climaticZoneTypes = useQuery('EEFOPClimaticZoneTypes', getEEFOPClimaticZoneTypes);
  const areas = useQuery('EEFOPAreas', () => getEEFOPAreas(form.getFieldValue('climatic_zone_type')));
  const variables = useQuery('EEFOPVariables', getEEFOPVariables);

  const onFinish = (values: IEEFOPFormValues) => {
    const { climatic_zone_type, area } = values;

    if (variables.data) {
      getFormResult(getEEFOPFullReport, {
        climaticZoneTypeId: climatic_zone_type,
        area: area,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeClimaticZoneType = () => {
    areas.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={climaticZoneTypes.data ? climaticZoneTypes.data : []}
            name="climatic_zone_type"
            cyPrefix={'EEFOP'}
            label="Вид климатической зоны"
            defaultValue={1}
            onChange={onChangeClimaticZoneType}
          />
          <FormBaseSelect
            data={areas.data ? areas.data : []}
            name="area"
            cyPrefix={'EEFOP'}
            label="Области РК"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="EEFOP"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
