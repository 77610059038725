import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  ICIEAdditionalCleaning,
  ICIECleaning,
  ICIEConsumption,
  ICIEMachineType,
  ICIEMeanName,
  ICIEMeanType,
  ICIEPollutant,
  ICIEVariable,
} from './interfaces';

const START_URL = 'ForthCatHimchistkaClass';

export const getCIEMeanTypes = async (): Promise<ICIEMeanType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetIspSredstvo`);
  return data;
};

export const getCIEPollutants = async (): Promise<ICIEPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTehVentil`);
  return data;
};

export const getCIECleanings = async (): Promise<ICIECleaning[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPredvoritelnayaOchistka`);
  return data;
};

export const getCIEAdditionalCleanings = async (): Promise<ICIEAdditionalCleaning[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetDopStupen`);
  return data;
};

export const getCIEMachineTypes = async (): Promise<ICIEMachineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipMashiny`);
  return data;
};

export const getCIEConsumptions = async (machineTypeId: string | number): Promise<ICIEConsumption[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNormaRashoda`, { params: { tipMashiny: machineTypeId } });
  return data;
};

export const getCIEMeanNames = async (meanTypeId: string | number): Promise<ICIEMeanName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVeshestvo`, { params: { ispSredstva: meanTypeId } });
  return data;
};

export const getCIEVariables = async (
  cleaningId: string | number,
  additionalCleaningId: string | number,
): Promise<ICIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetData`, {
    params: { predvoritelnayaOchistka: cleaningId, dopStupen: additionalCleaningId },
  });
  return data;
};

export const getCIEFullReport = async (reportData: {
  meanTypeId: string | number;
  meanNameId: string | number;
  pollutantId: string | number;
  cleaningId: string | number;
  additionalCleaningId: string | number;
  machineTypeId: string | number;
  consumptionId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    meanNameId,
    meanTypeId,
    pollutantId,
    cleaningId,
    consumptionId,
    additionalCleaningId,
    machineTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponce`, [
    {
      id: meanTypeId,
      paramName: 'IspSredstva',
    },
    {
      id: meanNameId,
      paramName: 'Veshestvo',
    },
    {
      id: cleaningId,
      paramName: 'PredvoritelnayaOchistka',
    },
    {
      id: additionalCleaningId,
      paramName: 'DopStupen',
    },
    {
      id: pollutantId,
      paramName: 'TehVentil',
    },
    {
      id: machineTypeId,
      paramName: 'TipMashiny',
    },
    {
      id: consumptionId,
      paramName: 'NormaRashoda',
    },
    ...variables,
  ]);
  return data;
};
