import React from 'react';
import { useQuery } from 'react-query';
import { Form } from 'antd';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { getSWLFullReport, getSWLVariables } from '../../api/SolidWasteLandfill';

import { IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const SolidWasteLandfill: React.FC<IMethodologyModule> = ({ children }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const variables = useQuery('SWLVariables', getSWLVariables);

  const onFinish = () => {
    if (variables.data) {
      getFormResult(getSWLFullReport, {
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <FormVariableInputList
        data={variables.data ? variables.data : []}
        cyPrefix="SWL"
        placeholder="0"
        minValue={0}
        defaultValue={0}
      />
      {children}
    </Form>
  );
};
