import React from 'react';
import { Form, Row, Col, InputNumber } from 'antd';
import { IGPIEFormValues, IMethodologyModule } from './interfaces';
import { useQuery } from 'react-query';
import {
  getGPIEAirs,
  getGPIEDryerTypes,
  getGPIEDusts,
  getGPIEEquipmentNames,
  getGPIEEquipmentTypes,
  getGPIEFactoryNames,
  getGPIEFactoryTypes,
  getGPIEFullReport,
  getGPIEProcessTypes,
  getGPIEVariables,
} from '../../api/GrainProcessingIndustryEmissions';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const GrainProcessingIndustryEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const processTypes = useQuery('GPIEProcessTypes', getGPIEProcessTypes);
  const dryerTypes = useQuery('GPIEDryerTypes', getGPIEDryerTypes);
  const dusts = useQuery('GPIEDusts', getGPIEDusts);
  const airs = useQuery('GPIEAirs', getGPIEAirs);
  const equipmentTypes = useQuery('GPIEEquipmentTypes', getGPIEEquipmentTypes);
  const equipmentNames = useQuery('GPIEEquipmentNames', () =>
    getGPIEEquipmentNames(form.getFieldValue('equipment_type')),
  );
  const factoryTypes = useQuery('GPIEFactoryTypes', getGPIEFactoryTypes);
  const factoryNames = useQuery('GPIEFactoryNames', () => getGPIEFactoryNames(form.getFieldValue('factory_type')));
  const variables = useQuery('GPIEVariables', () =>
    getGPIEVariables(
      form.getFieldValue('process_type'),
      form.getFieldValue('air'),
      form.getFieldValue('dust'),
      form.getFieldValue('source_count'),
      form.getFieldValue('equipment_count'),
    ),
  );

  const onFinish = (values: IGPIEFormValues) => {
    const {
      process_type,
      dryer_type,
      dust,
      air,
      equipment_count,
      equipment_name,
      equipment_type,
      factory_name,
      factory_type,
      source_count,
    } = values;

    if (variables.data) {
      getFormResult(getGPIEFullReport, {
        processTypeId: process_type,
        airId: air || 1,
        dustId: dust || 1,
        sourceCount: source_count || 1,
        equipmentCount: equipment_count || 1,
        equipmentTypeId: equipment_type || 1,
        equipmentNameId: equipment_name || 1,
        factoryTypeId: factory_type || 1,
        factoryNameId: factory_name || 1,
        dryerTypeId: dryer_type || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEquipmentType = () => {
    equipmentNames.refetch();
  };

  const onChangeFactoryType = () => {
    factoryNames.refetch();
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={processTypes.data ? processTypes.data : []}
            name="process_type"
            cyPrefix="GPIE"
            label="Вид процесса"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
          {form.getFieldValue('process_type') === 2 ? (
            <FormBaseSelect
              data={dryerTypes.data ? dryerTypes.data : []}
              name="dryer_type"
              cyPrefix="GPIE"
              label="Вид зерносушилки"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('process_type') === 1 ? (
            <>
              <Form.Item
                name="source_count"
                label="Количество источников выделения пыли в атмосферу, шт, n"
                initialValue={1}
              >
                <InputNumber
                  data-cy="GPIE-source_count-input"
                  min={1}
                  placeholder={'1'}
                  defaultValue={1}
                  onChange={handleChangeVariables}
                />
              </Form.Item>
              <FormBaseSelect
                data={airs.data ? airs.data : []}
                name="air"
                cyPrefix="GPIE"
                label="Наличие данных о количестве воздуха, поступающего в пылеуловитель (тыс.м3/час), Qn"
                defaultValue={1}
                onChange={handleChangeVariables}
              />
              {form.getFieldValue('air') === 2 ? (
                <>
                  {' '}
                  <FormBaseSelect
                    data={equipmentTypes.data ? equipmentTypes.data : []}
                    name="equipment_type"
                    cyPrefix="GPIE"
                    label="Вид оборудования зерноперерабытывающих предприятий"
                    defaultValue={1}
                    onChange={onChangeEquipmentType}
                  />
                  <FormBaseSelect
                    data={equipmentNames.data ? equipmentNames.data : []}
                    name="equipment_name"
                    cyPrefix="GPIE"
                    label="Наименование оборудования"
                    defaultValue={1}
                  />
                </>
              ) : (
                ''
              )}

              <FormBaseSelect
                data={dusts.data ? dusts.data : []}
                name="dust"
                cyPrefix="GPIE"
                label="Наличие замеров концентрации пыли, поступающей в пылеуловитель (г/м3), Zn"
                defaultValue={1}
                onChange={handleChangeVariables}
              />
              {form.getFieldValue('dust') === 2 ? (
                <>
                  {' '}
                  <Form.Item
                    name="equipment_count"
                    label="Количество оборудования обьединеных в п-ую установку, m"
                    initialValue={1}
                  >
                    <InputNumber
                      data-cy="GPIE-equipment_count-input"
                      min={1}
                      placeholder={'1'}
                      defaultValue={1}
                      onChange={handleChangeVariables}
                    />
                  </Form.Item>
                  <FormBaseSelect
                    data={factoryTypes.data ? factoryTypes.data : []}
                    name="factory_type"
                    cyPrefix="GPIE"
                    label="Вид оборудования заводов по переработке круп"
                    defaultValue={1}
                    onChange={onChangeFactoryType}
                  />
                  <FormBaseSelect
                    data={factoryNames.data ? factoryNames.data : []}
                    name="factory_name"
                    cyPrefix="GPIE"
                    label="Наименование оборудования заводов по переработке круп"
                    defaultValue={1}
                  />
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="GPIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
