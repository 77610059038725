import {
  AutoGasFillingStation,
  PaintWork,
  WorkingPlasticMaterials,
  MetalMechanicalProcessing,
  TermalPowerPlants,
  EvaporationEathenFuelOilPits,
  SolidWasteLandfill,
  EmissionsPastaIndustry,
  SugarIndustryEmissons,
  TobaccoProductionEmission,
  EmissionEnterpriseProcessingMolasses,
  BakeriesEmissions,
  BeerIndustryEmissions,
  DieselInstallationsEmissions,
  OilFatIndustryEmissions,
  FishIndustryEmissions,
  MetallurgicProduction,
  FlareEmissions,
  DieselInstallationsEmissions2,
  CalculationAshWasteDisposal,
  FoodConcentrateIndustryEmissions,
  HeatTreatment,
  GrainProcessingIndustryEmissions,
  TransportationStorageGas,
  BenzopyreneEmissions,
  ProductionMetalElectroplating,
  WoodworkingIndustry,
  KazahstanEcoExport,
  TankEmissions,
  RailwayTransport,
  TruckingCompany,
  Order4221,
  Order7221,
  PetrochemicalEnterprises,
  Order8221,
  EnterprisesCementProduction,
  CoalMiningEnterprises,
  MechanicalEngineering,
  CanningIndustryEmissions,
} from '../modules/Methodologies/';

export const methodologies = [
  {
    id: 1,
    name: 'Сборник КазЭКОЭКСП',
    component: KazahstanEcoExport,
  },
  {
    id: 2,
    name: 'АТП (прил. 3 приказ №100-п)',
    component: TruckingCompany,
  },
  {
    id: 3,
    name: 'ЖДТ (прил. 21 приказ №100-п)',
    component: RailwayTransport,
  },
  {
    id: 4,
    name: 'РНД 211.2.02.09-2004',
    component: TankEmissions,
  },
  {
    id: 5,
    name: 'прил. 4 приказ №221-Ө',
    component: Order4221,
  },
  {
    id: 6,
    name: 'прил. 7 приказ №221-Ө',
    component: Order7221,
  },
  {
    id: 7,
    name: 'прил. 8 приказ №221-Ө',
    component: Order8221,
  },
  {
    id: 8,
    name: 'Выбросы от автогазонаполнительной станции (приказ №196)',
    component: AutoGasFillingStation,
  },
  {
    id: 9,
    name: 'Выбросы при лакокрасочных работах(РНД 211.2.02.05-2004)',
    component: PaintWork,
  },
  {
    id: 10,
    name: 'Работа с пластмассовыми материалами (прил. 5 приказ №221-Ө)',
    component: WorkingPlasticMaterials,
  },
  {
    id: 11,
    name: 'Механическая обработка металла (РНД 211.2.02.06-2004)',
    component: MetalMechanicalProcessing,
  },
  {
    id: 12,
    name: 'Выбросы от тепловых электростанций и котельных (прил. 3 приказ №221-Ө)',
    component: TermalPowerPlants,
  },
  {
    id: 13,
    name: 'Испарение от земляных амбаров для мазута(п. 5.3.3. КазЭКОЭКСП, 1996)',
    component: EvaporationEathenFuelOilPits,
  },
  {
    id: 14,
    name: 'Полигон твердых бытовых отходов(прил. 11 приказ №221-Ө)',
    component: SolidWasteLandfill,
  },
  {
    id: 15,
    name: 'Выбросы предприятий макаронной отрасли (п. 5 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: EmissionsPastaIndustry,
  },
  {
    id: 16,
    name: 'Выбросы предприятий сахарной отрасли (п. 7 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: SugarIndustryEmissons,
  },
  {
    id: 17,
    name: 'Выбросы при табачном производстве (п. 13 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: TobaccoProductionEmission,
  },
  {
    id: 18,
    name: 'Выбросы предприятий комплексной переработки мелассы (п. 8 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: EmissionEnterpriseProcessingMolasses,
  },
  {
    id: 19,
    name: 'Выбросы предприятий хлебопекарного производства (п. 4 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: BakeriesEmissions,
  },
  {
    id: 20,
    name: 'Выбросы предприятий пивобезалкогольной продукции (п. 10 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: BeerIndustryEmissions,
  },
  {
    id: 21,
    name: 'Выбросы от стационарных дизельных установок (прил. 9 приказ №221-Ө)',
    component: DieselInstallationsEmissions,
  },
  {
    id: 22,
    name: 'Выбросы предприятий масложировой отрасли (п. 12 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: OilFatIndustryEmissions,
  },
  {
    id: 23,
    name: 'Выбросы предприятий рыбоперерабатывающей отрасли (п. 14 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: FishIndustryEmissions,
  },
  {
    id: 24,
    name: 'Металлургическое производство(прил. 13 приказ №221-Ө)',
    component: MetallurgicProduction,
  },
  {
    id: 25,
    name: 'Выбросы от факельных установок (Приказ №23П)',
    component: FlareEmissions,
  },
  {
    id: 26,
    name: 'Выбросы от стационарных дизельных установок(РНД 211.2.02.04-2004)',
    component: DieselInstallationsEmissions2,
  },
  {
    id: 27,
    name: 'Расчет размещения золошлаковых отходов (прил. 10 приказ №221-Ө)',
    component: CalculationAshWasteDisposal,
  },
  {
    id: 28,
    name: 'Выбросы предприятий пищеконцентратной отрасли (п. 6 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: FoodConcentrateIndustryEmissions,
  },
  {
    id: 29,
    name: 'ТБО. Термическая обработка. М, 1989',
    component: HeatTreatment,
  },
  {
    id: 30,
    name: 'Выбросы предприятий зерноперерабатывающей отрасли (п. 15 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: GrainProcessingIndustryEmissions,
  },
  {
    id: 31,
    name: 'Объекты транспорта и хранения газа (прил. 1 приказ №221-Ө)',
    component: TransportationStorageGas,
  },
  {
    id: 32,
    name: 'Расчет выбросов бенз(а)пирена (Москва, 1999)',
    component: BenzopyreneEmissions,
  },
  {
    id: 33,
    name: 'Производство металлопокрытий гальваническим способом(РНД 211.2.02.07-2004)',
    component: ProductionMetalElectroplating,
  },
  {
    id: 34,
    name: 'Деревообрабатывающая промышленность (РНД 211.2.02.08-2004)',
    component: WoodworkingIndustry,
  },
  {
    id: 35,
    name: 'Предприятия нефтепереработки и нефтехимии (прил. 2 приказ №221-Ө)',
    component: PetrochemicalEnterprises,
  },
  {
    id: 36,
    name: 'Предприятия цементного производства (прил. 6 приказ №221-Ө)',
    component: EnterprisesCementProduction,
  },
  {
    id: 37,
    name: 'Предприятия по добыче и переработке угля',
    component: CoalMiningEnterprises,
  },
  {
    id: 38,
    name: 'Машиностроение',
    component: MechanicalEngineering,
  },
  {
    id: 39,
    name: 'Выбросы предприятий консервной отрасли (п. 9 МУ пищепром. Приказ № 204-п от 05.08.2011)',
    component: CanningIndustryEmissions,
  },
];
