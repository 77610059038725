import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICHPEClimaticZone,
  ICHPEDesignTank,
  ICHPEOilGroup,
  ICHPEOilTypes,
  ICHPEOperationMode,
  ICHPEReportData,
  ICHPEVaporConcentration,
  ICHPEVariable,
} from './interfaces';

const START_URL = 'ReservoirTECClass';

export const getCHPEOperationModes = async (): Promise<ICHPEOperationMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRezhimEkspluatacii`);
  return data;
};

export const getCHPETankDesigns = async (operationModeId: string | number): Promise<ICHPEDesignTank[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKonstrukcRezervuara`, {
    params: { rezhimEkspluatacii: operationModeId },
  });
  return data;
};

export const getCHPEOilGroups = async (): Promise<ICHPEOilGroup[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGruppaNefteproducta`);
  return data;
};

export const getCHPEOilTypes = async (): Promise<ICHPEOilTypes[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNefteproduct`);
  return data;
};

export const getCHPEClimaticZones = async (): Promise<ICHPEClimaticZone[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetClimaticZone`);
  return data;
};

export const getCHPEVaporConcentrations = async (
  climaticZoneId: string | number,
): Promise<ICHPEVaporConcentration[]> => {
  let response;

  switch (climaticZoneId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetKoncentracParovAverage`);
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetKoncentracParovSouth`);
      break;
  }

  return response?.data;
};

export const getCHPEVariables = async (gasPipingTanks: boolean): Promise<ICHPEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { isGOR: gasPipingTanks } });
  return data;
};

export const getCHPEFullReport = async (reportData: ICHPEReportData): Promise<IToolsReport[]> => {
  const {
    operationModeId,
    oilGroupId,
    oilTypeId,
    climaticZoneId,
    vaporConcentrationId,
    tankDesignId,
    gasPiping,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse?IsGOR=${gasPiping}`, [
    {
      id: operationModeId,
      paramName: 'RezhimEkspluatacii',
    },
    {
      id: tankDesignId,
      paramName: 'KonstrukcRezervuara',
    },
    {
      id: oilGroupId,
      paramName: 'GruppaNefteproducta',
    },
    {
      id: oilTypeId,
      paramName: 'Nefteproduct',
    },
    {
      id: climaticZoneId,
      paramName: 'ClimaticZone',
    },
    {
      id: vaporConcentrationId,
      paramName: 'DBKoncentracParov',
    },
    ...variables,
  ]);
  return data;
};
