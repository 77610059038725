import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IADECalculationType,
  IADECleanStep,
  IADEDeleteType,
  IADEDetergent,
  IADETechnologicalProcesses,
  IADEVariable,
} from './interfaces';

const START_URL = 'ForthCatSluzhbyClass';

export const getADECalculationTypes = async (): Promise<IADECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRaschetaSluzhb`);
  return data;
};

export const getADETechnologicalProcesses = async (
  calculationTypeId: string | number,
): Promise<IADETechnologicalProcesses[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTehProcess`, {
    params: { vidRaschetaSluzhb: calculationTypeId },
  });
  return data;
};

export const getADEEquipmentTypes = async (
  calculationTypeId: string | number,
  technologicalProcessId: string | number,
): Promise<IADETechnologicalProcesses[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidOborudovaniya`, {
    params: { vidRaschetaSluzhb: calculationTypeId, tehProcess: technologicalProcessId },
  });
  return data;
};

export const getADEDeleteTypes = async (): Promise<IADEDeleteType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUdalenieVybr`);
  return data;
};

export const getADECleanSteps = async (): Promise<IADECleanStep[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetOchistka`);
  return data;
};

export const getADEDetergents = async (equipmentTypeId: string | number): Promise<IADEDetergent[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidsredtva`, {
    params: { vidOborudovaniya: equipmentTypeId },
  });
  return data;
};

export const getADEVariables = async (
  calculationTypeId: string | number,
  technologicalProcessId: string | number,
  cleanStepId: string | number,
  equipmentTypeId: string | number,
  deleteTypeId: string | number,
): Promise<IADEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetData`, {
    params: {
      vidRaschetaSluzhb: calculationTypeId,
      tehProcess: technologicalProcessId,
      ochistka: cleanStepId,
      udalenieVybr: deleteTypeId,
      vidOborudovaniya: equipmentTypeId,
    },
  });
  return data;
};

export const getADEFullReport = async (reportData: {
  calculationTypeId: string | number;
  technologicalProcessId: string | number;
  cleanStepId: string | number;
  equipmentTypeId: string | number;
  deleteTypeId: string | number;
  detergentId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    technologicalProcessId,
    cleanStepId,
    equipmentTypeId,
    deleteTypeId,
    detergentId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponce`, [
    {
      id: calculationTypeId,
      value: 1,
      paramName: 'VidRaschetaSluzhb',
    },
    {
      id: technologicalProcessId,
      value: 1,
      paramName: 'TehProcess',
    },
    {
      id: cleanStepId,
      value: 1,
      paramName: 'Ochistka',
    },
    {
      id: equipmentTypeId,
      value: 1,
      paramName: 'VidOborudovaniya',
    },
    {
      id: deleteTypeId,
      value: 1,
      paramName: 'UdalenieVybr',
    },
    {
      id: detergentId,
      value: 1,
      paramName: 'Vidsredtva',
    },
    ...variables,
  ]);
  return data;
};
