import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IFDFECalculationType,
  IFDFECurrentProcessStage,
  IFDFEDensity,
  IFDFEDustySurface,
  IFDFEHumidity,
  IFDFEMachineType,
  IFDFEOperationType,
  IFDFEProcessName,
  IFDFEProcessStage,
  IFDFEProductionType,
  IFDFEReportData,
  IFDFETemperature,
  IFDFEVariable,
} from './interfaces';

const START_URL = 'ForthCatLekarstvaClass';

export const getFDFEProductionTypes = async (): Promise<IFDFEProductionType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProizvodstva`, { params: { vidRascheta: 1 } });
  return data;
};

export const getFDFEProcessNames = async (productionTypeId: string | number): Promise<IFDFEProcessName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetImyaProcessa`, {
    params: { vidProizvodstva: productionTypeId },
  });
  return data;
};

export const getFDFEProcessStages = async (
  productionTypeId: string | number,
  processNameId: string | number,
): Promise<IFDFEProcessStage[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetImyaStadiiProcessa`, {
    params: { vidProizvodstva: productionTypeId, imyaProcessa: processNameId },
  });
  return data;
};

export const getFDFEOperationTypes = async (
  processStageId: string | number,
  processNameId: string | number,
): Promise<IFDFEOperationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipOperacii`, {
    params: { imyaProcessa: processStageId, imyaStadiiProcessa: processNameId },
  });
  return data;
};

export const getFDFEHumidities = async (): Promise<IFDFEHumidity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVlaznost`);
  return data;
};

export const getFDFETemperatures = async (): Promise<IFDFETemperature[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVlaznost`);
  return data;
};

export const getFDFEDensities = async (): Promise<IFDFEDensity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPlotnost`);
  return data;
};

export const getFDFECurrentProcessStages = async (): Promise<IFDFECurrentProcessStage[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipPloshad`);
  return data;
};

export const getFDFEMachineTypes = async (): Promise<IFDFEMachineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidMashin`);
  return data;
};

export const getFDFECalculationTypes = async (): Promise<IFDFECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVid`);
  return data;
};

export const getFDFEDustySurfaces = async (): Promise<IFDFEDustySurface[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVvodS`);
  return data;
};

export const getFDFEVariables = async (
  operationTypeId: string | number,
  currentProcessStageId: string | number,
  calculationTypeId: string | number,
  dustySurfaceId: string | number,
): Promise<IFDFEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetData`, {
    params: {
      tipOperacii: operationTypeId,
      tipPloshad: currentProcessStageId,
      vid: calculationTypeId,
      vvodSId: dustySurfaceId,
    },
  });
  return data;
};

export const getFDFEFullReport = async (reportData: IFDFEReportData): Promise<IToolsReport[]> => {
  const {
    productionTypeId,
    processNameId,
    processStageId,
    operationTypeId,
    humidityId,
    temperatureId,
    densityId,
    currentProccesStageId,
    machineTypeId,
    calculationTypeId,
    dustySurface,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponce`, [
    {
      id: processStageId,
      paramName: 'ImyaStadiiProcessa',
    },
    {
      id: dustySurface,
      paramName: 'VvodSId',
    },
    {
      id: operationTypeId,
      paramName: 'TipOperacii',
    },
    {
      id: humidityId,
      paramName: 'Vlaznost',
    },
    {
      id: temperatureId,
      paramName: 'Temperatura',
    },
    {
      id: densityId,
      paramName: 'Plotnost',
    },
    {
      id: machineTypeId,
      paramName: 'VidMashin',
    },
    {
      id: calculationTypeId,
      paramName: 'Vid',
    },
    {
      id: currentProccesStageId,
      paramName: 'TipPloshad',
    },
    {
      id: processNameId,
      paramName: 'ImyaProcessa',
    },
    {
      id: productionTypeId,
      paramName: 'VidProizvodstva',
    },
    ...variables,
  ]);
  return data;
};
