import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import { ISWLReportData, ISWLVariable } from './interfaces';

const START_URL = 'PoligonTBOClass';

export const getSWLVariables = async (): Promise<ISWLVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getSWLFullReport = async (reportData: ISWLReportData): Promise<IToolsReport[]> => {
  const { variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [...variables]);
  return data;
};
