import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getTFEBurnerParametrs,
  getTFEBurnerTypes,
  getTFECoefficients,
  getTFEFuelTypes,
  getTFEFullReport,
  getTFEGasFuels,
  getTFELiquidFuels,
  getTFEVariables,
} from '../../../../api/TubeFurnacesEmissions';

import { IMethodologyModule, ITFEFormValues } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

const { Option } = Select;

export const TubeFurnacesEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const burnerTypes = useQuery('TFEBurnerTypes', getTFEBurnerTypes);
  const fuelTypes = useQuery('TFEFuelTypes', () => getTFEFuelTypes(form.getFieldValue('burner_type')));
  const liquidFuels = useQuery('TFELiquidFuels', getTFELiquidFuels);
  const gasFuels = useQuery('TFEGasFuels', getTFEGasFuels);
  const coefficients = useQuery('TFECoefficients', getTFECoefficients);
  const burnerParametrs = useQuery('TFEBurnerParametrs', () => getTFEBurnerParametrs(form.getFieldValue('fuel_type')));
  const variables = useQuery('TFEVariables', () => getTFEVariables(form.getFieldValue('burner_type')));

  const onFinish = (values: ITFEFormValues) => {
    const { burner_parametr, burner_type, liquid_fuel, gas_fuel, coefficient, fuel_type } = values;

    if (variables.data) {
      getFormResult(getTFEFullReport, {
        burnerTypeId: burner_type,
        fuelTypeId: fuel_type,
        liquidFuelId: liquid_fuel || 1,
        gasFuelId: gas_fuel || 1,
        coefficientId: coefficient,
        burnerParametrId: burner_parametr || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeBurnerType = () => {
    fuelTypes.refetch();
    variables.refetch();
  };

  const onChangeFuelType = () => {
    burnerParametrs.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={burnerTypes.data ? burnerTypes.data : []}
            name="burner_type"
            cyPrefix="TFE"
            label="Тип горелки(форсунки)"
            defaultValue={1}
            onChange={onChangeBurnerType}
          />
          <FormBaseSelect
            data={fuelTypes.data ? fuelTypes.data : []}
            name="fuel_type"
            cyPrefix="TFE"
            label="Вид топлива"
            defaultValue={1}
            onChange={onChangeFuelType}
          />
          {(form.getFieldValue('burner_type') === 1 && form.getFieldValue('fuel_type') === 2) ||
          (form.getFieldValue('burner_type') >= 2 && form.getFieldValue('fuel_type') === 2) ? (
            <FormBaseSelect
              data={liquidFuels.data ? liquidFuels.data : []}
              name="liquid_fuel"
              cyPrefix="TFE"
              label="Топливо жидкое"
              defaultValue={1}
            />
          ) : (
            ''
          )}
          {form.getFieldValue('burner_type') === 1 ||
          (form.getFieldValue('burner_type') >= 2 && form.getFieldValue('fuel_type') === 1) ? (
            <FormBaseSelect
              data={gasFuels.data ? gasFuels.data : []}
              name="gas_fuel"
              cyPrefix="TFE"
              label="Топливо газообразное"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={coefficients.data ? coefficients.data : []}
            name="coefficient"
            cyPrefix="TFE"
            label="Значение коэффицента избытка воздухав уходящих дымовых газах α"
            defaultValue={1}
          />
          {form.getFieldValue('burner_type') === 1 ? (
            <Form.Item name="burner_parametr" label="Параметры горелок" initialValue={1}>
              <Select data-cy={`TFE-burner_parametr-select`}>
                {burnerParametrs.data
                  ? burnerParametrs.data.map((element) => (
                      <Option
                        data-cy={`TFE-burner_parametr-option-${element.id}`}
                        key={element.id + element.name}
                        value={element.id}
                      >
                        {`Тип:${element.name}; Конпоновка: ${element.kompanovka}; Qr: ${element.qg}; A: ${element.a}; D: ${element.d}; C: ${element.c}`}
                      </Option>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="TFE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
