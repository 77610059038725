import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import {
  getPCFullReport,
  getPCOperationTypes,
  getPCSubstances,
  getPCTechnologicalProcesses,
  getPCVariables,
} from '../../../../api/PartsCleaning';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { IMethodologyModule, IPCFormValues } from '../../interfaces';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const PartsCleaning: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const operationTypes = useQuery('PCOperationTypes', getPCOperationTypes);
  const technologicalProcesses = useQuery('PCTechnicalProcesses', () =>
    getPCTechnologicalProcesses(form.getFieldValue('operation_type')),
  );
  const substances = useQuery('PCSubstances', () =>
    getPCSubstances(form.getFieldValue('operation_type'), form.getFieldValue('technological_process')),
  );
  const variables = useQuery('PCVariables', () => getPCVariables(form.getFieldValue('operation_type')));

  const onFinish = (values: IPCFormValues) => {
    const { operation_type, technological_process, substance } = values;

    if (variables.data) {
      getFormResult(getPCFullReport, {
        operationTypeId: operation_type,
        technologicalProcessId: technological_process,
        substanceId: substance,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeOperationType = () => {
    technologicalProcesses.refetch();
    substances.refetch();
    variables.refetch();
  };

  const onChangeTechnologicalProcess = () => {
    substances.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={operationTypes.data ? operationTypes.data : []}
            name="operation_type"
            cyPrefix="PC"
            label="Вид операции"
            defaultValue={1}
            onChange={onChangeOperationType}
          />
          <FormBaseSelect
            data={technologicalProcesses.data ? technologicalProcesses.data : []}
            name="technological_process"
            cyPrefix="PC"
            label="Технологический процесс"
            defaultValue={1}
            onChange={onChangeTechnologicalProcess}
          />
          <FormBaseSelect
            data={substances.data ? substances.data : []}
            name="substance"
            cyPrefix="PC"
            label="Применяемые вещества"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="PC"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
