import React from 'react';
import { Form, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';

import { getMBCalculationTypes, getMBFullReport, getMBMarks, getMBVariables } from '../../../../api/MednitsaBranch';

import { IMBFormValues, IMethodologyModule } from '../../interfaces';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const MednitsaBranch: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('MBCalculationTypes', getMBCalculationTypes);
  const marks = useQuery('MBMarks', () => getMBMarks(form.getFieldValue('calculation_type')));
  const variables = useQuery('MBVariables', () => getMBVariables(form.getFieldValue('calculation_type')));

  const onFinish = (values: IMBFormValues) => {
    const { calculation_type, mark } = values;

    if (variables.data) {
      getFormResult(getMBFullReport, {
        calculationTypeId: calculation_type,
        markId: mark,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const handleChangeVariables = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix={'MB'}
            label="Вид расчета"
            defaultValue={1}
          />
          <FormBaseSelect
            data={marks.data ? marks.data : []}
            name="mark"
            cyPrefix={'MB'}
            label="Марка припоя"
            defaultValue={1}
            onChange={handleChangeVariables}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="MB"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
