import React from 'react';
import { useQuery } from 'react-query';
import { Form, Row, Col } from 'antd';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../ui/molecules/FormBaseSelect';

import {
  getDIE2Conditions,
  getDIE2FullReport,
  getDIE2Groups,
  getDIE2Manufacturers,
  getDIE2Variables,
} from '../../api/DieselInstallationsEmissions2';

import { IDIE2FormValues, IMethodologyModule } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

export const DieselInstallationsEmissions2: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const manufacturers = useQuery('DIE2Manufacturers', getDIE2Manufacturers);
  const groups = useQuery('DIE2Groups', getDIE2Groups);
  const conditions = useQuery('DIE2Conditions', getDIE2Conditions);
  const variables = useQuery('DIE2Variables', getDIE2Variables);

  const onFinish = (values: IDIE2FormValues) => {
    const { manufacturer, group, condition } = values;

    if (variables.data) {
      getFormResult(getDIE2FullReport, {
        manufacturerId: manufacturer,
        groupId: group,
        conditionId: condition,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={manufacturers.data ? manufacturers.data : []}
            name="manufacturer"
            cyPrefix="DIE2"
            label="Производитель СДУ"
            defaultValue={1}
          />
          <FormBaseSelect
            data={groups.data ? groups.data : []}
            name="group"
            cyPrefix="DIE2"
            label="Группа СДУ"
            defaultValue={1}
          />
          <FormBaseSelect
            data={conditions.data ? conditions.data : []}
            name="condition"
            cyPrefix="DIE2"
            label="Состояние СДУ"
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="DIE2"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
