import React from 'react';
import { Form, Col, Row, Select } from 'antd';
import { IMethodologyModule } from '../interfaces';
import {
  EngineRunningSection,
  DrySandOven,
  Vehicles,
  BatterySection,
  MednitsaBranch,
  WagonLocomotiveBusiness,
} from './Submethodologies';

const { Option } = Select;

export const RailwayTransport: React.FC<IMethodologyModule> = ({ children, marginBetween }: IMethodologyModule) => {
  const [selectedSubmethodology, setSelectedSubmethodology] = React.useState(0);
  return (
    <>
      <Form>
        <Row>
          <Col span={11} style={{ marginRight: marginBetween }}>
            <Form.Item label="Расчет выбросов ЗВ" initialValue={0}>
              <Select
                data-cy={`RT-submethodology-select`}
                defaultValue={0}
                onChange={(value: number) => setSelectedSubmethodology(value)}
              >
                <Option data-cy={`RT-submethodology-option-0`} value={0}>
                  Участок обкатки двигателя
                </Option>
                <Option data-cy={`RT-submethodology-option-1`} value={1}>
                  Сушка песка в печи
                </Option>
                <Option data-cy={`RT-submethodology-option-2`} value={2}>
                  Транспортные средства
                </Option>
                <Option data-cy={`RT-submethodology-option-3`} value={3}>
                  Аккумуляторный участок
                </Option>
                <Option data-cy={`RT-submethodology-option-4`} value={4}>
                  Медницкое отделение
                </Option>
                <Option data-cy={`RT-submethodology-option-5`} value={5}>
                  Вагонное и локомотивное дело
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedSubmethodology == 0 ? (
        <EngineRunningSection marginBetween={marginBetween}>{children}</EngineRunningSection>
      ) : (
        ''
      )}
      {selectedSubmethodology == 1 ? <DrySandOven marginBetween={marginBetween}>{children}</DrySandOven> : ''}
      {selectedSubmethodology == 2 ? <Vehicles marginBetween={marginBetween}>{children}</Vehicles> : ''}
      {selectedSubmethodology == 3 ? <BatterySection marginBetween={marginBetween}>{children}</BatterySection> : ''}
      {selectedSubmethodology == 4 ? <MednitsaBranch marginBetween={marginBetween}>{children}</MednitsaBranch> : ''}
      {selectedSubmethodology == 5 ? (
        <WagonLocomotiveBusiness marginBetween={marginBetween}>{children}</WagonLocomotiveBusiness>
      ) : (
        ''
      )}
    </>
  );
};
