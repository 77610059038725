import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IRRPTechnologicalProcess, IRRPVariable } from './interfaces';

const START_URL = 'RemontRezinotechnicheskihIzdeliyLibrary';

export const getRRPTechnologicalProcesses = async (): Promise<IRRPTechnologicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechProcess`);
  return data;
};

export const getRRPVariables = async (technologicalProcessId: string | number): Promise<IRRPVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { techProcessId: technologicalProcessId },
  });
  return data;
};

export const getRRPFullReport = async (reportData: {
  technologicalProcessId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { technologicalProcessId, variables } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: technologicalProcessId,
      paramName: 'TechProcessId',
    },
    ...variables,
  ]);
  return data;
};
