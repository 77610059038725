import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IFCIEDryerType,
  IFCIEDustCollector,
  IFCIEEquipment,
  IFCIEMark,
  IFCIEPollutant,
  IFCIEProcessType,
  IFCIEVariable,
} from './interfaces';

const START_URL = 'PishekoncentratClass';

export const getFCIEProcessTypes = async (): Promise<IFCIEProcessType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidProcessa`);
  return data;
};

export const getFCIEDustCollectors = async (): Promise<IFCIEDustCollector[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPyleulov`);
  return data;
};

export const getFCIEDryerTypes = async (): Promise<IFCIEDryerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidSushilok`);
  return data;
};

export const getFCIEMarks = async (
  processTypeId: string | number,
  dryerTypeId: string | number,
  equipmentId: string | number,
): Promise<IFCIEMark[]> => {
  let response;
  console.log(dryerTypeId, equipmentId, processTypeId);

  switch (processTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetMarka`, { params: { vidSushilok: dryerTypeId } });
      break;

    case 3:
      response = await apiClient.get(`${START_URL}/GetMarkaRazmol`, {
        params: { vidOborudRazmol: equipmentId },
      });
      break;

    case 4:
      response = await apiClient.get(`${START_URL}/GMMaaaeikkort`, {
        params: { vidOborudMoika: equipmentId },
      });
      break;
  }

  return response?.data;
};

export const getFCIEPollutants = async (
  processTypeId: string | number,
  dryerTypeId: string | number,
  equipmentId: string | number,
  markId: string | number,
): Promise<IFCIEPollutant[]> => {
  let response;

  switch (processTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetZVK`, { params: { vidSushilok: dryerTypeId, marka: markId } });
      break;

    case 2:
      response = await apiClient.get(`${START_URL}/GetZVT`, {
        params: { vidOborudovaniya: equipmentId },
      });
      break;

    case 4:
      response = await apiClient.get(`${START_URL}/GMMaaaeikkort`, {
        params: { vidOborudRazmol: equipmentId, markaRazmol: markId },
      });
      break;
  }

  return response?.data;
};

export const getFCIEEquipments = async (processTypeId: string | number): Promise<IFCIEEquipment[]> => {
  let response;

  switch (processTypeId) {
    case 2:
      response = await apiClient.get(`${START_URL}/GetVidOborudovaniya`);
      break;

    case 3:
      response = await apiClient.get(`${START_URL}/GetVidOborudRazmol`);
      break;

    case 4:
      response = await apiClient.get(`${START_URL}/GetVidOborudMoika`);
      break;
  }

  return response?.data;
};

export const getFCIEVariables = async (processTypeId: string | number): Promise<IFCIEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { vidProcessa: processTypeId } });
  return data;
};

export const getFCIEFullReport = async (reportData: {
  processTypeId: string | number;
  dustCollectorId: string | number;
  dryerId: string | number;
  markId: string | number;
  equipment: string | number;
  pollutant: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { processTypeId, dustCollectorId, dryerId, markId, equipment, pollutant, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse?vidProcessa=${processTypeId}`, [
    {
      id: dustCollectorId,
      paramName: 'Pyleulov',
    },
    {
      id: dryerId,
      paramName: 'VidSushilok',
    },
    {
      id: markId,
      paramName: 'Marka',
    },
    {
      id: pollutant,
      paramName: 'DBVeshestvoK',
    },
    {
      id: equipment,
      paramName: 'VidOborudovaniya',
    },
    {
      id: pollutant,
      paramName: 'DBVeshestvoT',
    },
    {
      id: equipment,
      paramName: 'VidOborudRazmol',
    },
    {
      id: markId,
      paramName: 'MarkaRazmol',
    },
    {
      id: pollutant,
      paramName: 'DBVeshestvoR',
    },
    {
      id: equipment,
      paramName: 'VidOborudMoika',
    },
    {
      id: markId,
      paramName: 'DBMarkaM',
    },
    ...variables,
  ]);
  return data;
};
