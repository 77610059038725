import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IWDEBreeds,
  IWDECoefficientK1,
  IWDECoefficientK2,
  IWDEDecommissioning,
  IWDEDeflation,
  IWDEDustEmission,
  IWDEEquipmentMark,
  IWDEEquipmentName,
  IWDELoadingTransportType,
  IWDEReportData,
  IWDEStrength,
  IWDESurfaceWindSpeed,
  IWDETransportType,
  IWDEVariable,
} from './interfaces';

const START_URL = 'PorodnyeOtvalyClass';

export const getWDELoadingTransportTypes = async (): Promise<IWDELoadingTransportType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTransporta`);
  return data;
};

export const getWDETransportTypes = async (loadingTransportTypeId: string | number): Promise<IWDETransportType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTranspOtvaloobr`, {
    params: { vidTransporta: loadingTransportTypeId },
  });
  return data;
};

export const getWDEEquipmentNames = async (): Promise<IWDEEquipmentName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetNameOborud`);
  return data;
};

export const getWDEEquipmentMarks = async (equipmentNameId: string | number): Promise<IWDEEquipmentMark[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetEskavator`, { params: { nameOborud: equipmentNameId } });
  return data;
};

export const getWDEBreeds = async (equipmentNameId: string | number): Promise<IWDEBreeds[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPoroda`, { params: { nameOborudL: equipmentNameId } });
  return data;
};

export const getWDEStrengths = async (
  equipmentNameId: string | number,
  breedId: string | number,
): Promise<IWDEStrength[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKrepost`, {
    params: { nameOborud: equipmentNameId, poroda: breedId },
  });
  return data;
};

export const getWDECoefficientK1 = async (): Promise<IWDECoefficientK1[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetK1`);
  return data;
};

export const getWDECoefficientK2 = async (): Promise<IWDECoefficientK2[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetK2`);
  return data;
};

export const getWDEDustEmissions = async (breedId: string | number): Promise<IWDEDustEmission[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUdelnoePylev`, { params: { poroda: breedId } });
  return data;
};

export const getWDESurfaceWindSpeeds = async (): Promise<IWDESurfaceWindSpeed[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetPrizemSkorostVetra`);
  return data;
};

export const getWDEDeflations = async (surfaceWindSpeedId: string | number): Promise<IWDEDeflation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetSduvaemost`, {
    params: { prizemSkorostVetra: surfaceWindSpeedId },
  });
  return data;
};

export const getWDEDecommissionings = async (surfaceWindSpeedId: string | number): Promise<IWDEDecommissioning[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffSduv`, {
    params: { prizemSkorostVetra: surfaceWindSpeedId },
  });
  return data;
};

export const getWDEVariables = async (
  tiers: string | number,
  equipmentNameId: string | number,
  transportTypeId: string | number,
  equipmentMarkId: string | number,
): Promise<IWDEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: {
      vidTrOtv: transportTypeId,
      kolYarusov: tiers,
      nameOborud: equipmentNameId,
      dBEskavator: equipmentMarkId,
    },
  });
  return data;
};

export const getWDEFullReport = async (reportData: IWDEReportData): Promise<IToolsReport[]> => {
  const {
    loadingTransportTypeId,
    transportTypeId,
    equipmentNameId,
    equipmentMarkId,
    breedId,
    strengthId,
    coefficientK1Id,
    coefficientK2Id,
    dustEmissionId,
    surfaceWindSpeedId,
    deflationId,
    decommissioningId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: loadingTransportTypeId,
      paramName: 'VidTransporta',
    },
    {
      id: strengthId,
      paramName: 'Krepost',
    },
    {
      id: strengthId,
      paramName: 'KrepostUg',
    },
    {
      id: equipmentNameId,
      paramName: 'NameOborud',
    },
    {
      id: equipmentMarkId,
      paramName: 'DBEskavator',
    },
    {
      id: deflationId,
      paramName: 'Sduvaemost',
    },
    {
      id: surfaceWindSpeedId,
      paramName: 'PrizemSkorostVetra',
    },
    {
      id: breedId,
      paramName: 'Poroda',
    },
    {
      id: decommissioningId,
      paramName: 'KoeffSduv',
    },
    {
      id: transportTypeId,
      paramName: 'VidTranspOtvaloobr',
    },
    {
      id: coefficientK1Id,
      paramName: 'K1',
    },
    {
      id: coefficientK2Id,
      paramName: 'K2',
    },
    {
      id: dustEmissionId,
      paramName: 'UdelnoePylev',
    },
    ...variables,
  ]);
  return data;
};
