import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ITFEBurnerParametr,
  ITFEBurnerType,
  ITFECoefficient,
  ITFEFuelType,
  ITFEGasFuel,
  ITFELiquidFuel,
  ITFEReportData,
  ITFEVariable,
} from './interfaces';

const START_URL = 'TrubchatyePechiClass';

export const getTFEBurnerTypes = async (): Promise<ITFEBurnerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTipGorelki`);
  return data;
};

export const getTFEFuelTypes = async (burnerTypeId: string | number): Promise<ITFEFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, { params: { tipGorelki: burnerTypeId } });
  return data;
};

export const getTFELiquidFuels = async (): Promise<ITFELiquidFuel[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetToplivoZhidkoe`);
  return data;
};

export const getTFEGasFuels = async (): Promise<ITFEGasFuel[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetToplivoGaz`);
  return data;
};

export const getTFECoefficients = async (): Promise<ITFECoefficient[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffA`);
  return data;
};

export const getTFEBurnerParametrs = async (fuelTypeId: string | number): Promise<ITFEBurnerParametr[]> => {
  let response;
  switch (fuelTypeId) {
    case 1:
      response = await apiClient.get(`${START_URL}/GetGorelkiGaz`);
      break;
    case 2:
      response = await apiClient.get(`${START_URL}/GetGorelkiSmes`);
      break;
  }

  return response?.data;
};

export const getTFEVariables = async (burnerTypeId: string | number): Promise<ITFEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, { params: { tipGorelki: burnerTypeId } });
  return data;
};

export const getTFEFullReport = async (reportData: ITFEReportData): Promise<IToolsReport[]> => {
  const { burnerParametrId, burnerTypeId, fuelTypeId, liquidFuelId, gasFuelId, coefficientId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: burnerTypeId,
      paramName: 'VidTopliva',
    },
    {
      id: fuelTypeId,
      paramName: 'TipGorelki',
    },
    {
      id: liquidFuelId,
      paramName: 'DBToplivoZh',
    },
    {
      id: gasFuelId,
      paramName: 'DBToplivoGaz',
    },
    {
      id: burnerParametrId,
      paramName: 'DBGorelki',
    },
    {
      id: coefficientId,
      paramName: 'KoeffA',
    },
    ...variables,
  ]);
  return data;
};
