import React from 'react';
import { useQuery } from 'react-query';
import { Form, Col, Row, Select, Input } from 'antd';
import { FormVariableInputList } from '../../ui/molecules/FormVariableInputList';

import { getSIEEquipmentTypes, getSIEFullReport, getSIEVariables } from '../../api/SugarIndustryEmissions';

import { IMethodologyModule, ISIEFormValues } from './interfaces';

import { getFormResult } from '../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../utils';

const { Option } = Select;

export const SugarIndustryEmissons: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = React.useState(0);

  const equipmentTypes = useQuery('SIEEquipmentTypes', getSIEEquipmentTypes);
  const variables = useQuery('SIEVariables', getSIEVariables);

  const onFinish = (values: ISIEFormValues) => {
    const { equipment_type } = values;

    if (variables.data) {
      getFormResult(getSIEFullReport, {
        equipmentTypeId: equipment_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeEquipmentType = () => {
    setSelectedEquipmentTypeId(form.getFieldValue('equipment_type'));
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <Form.Item name="equipment_type" label="Вид оборудования для производства сахара" initialValue={1}>
            <Select data-cy="SIE-equipment_type-select" onChange={onChangeEquipmentType}>
              {equipmentTypes.data
                ? equipmentTypes.data.map((element) => (
                    <Option
                      data-cy={`SIE-equipment_type-option-${element.id}`}
                      key={element.id + element.name}
                      value={element.id}
                    >
                      {element.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
          <Form.Item name="polutant" label="Наименование загрязняющего вещества">
            <Input
              data-cy="SIE-polutant-input"
              placeholder={equipmentTypes.data ? equipmentTypes.data[selectedEquipmentTypeId].zv : ''}
              disabled
            />
          </Form.Item>
          <Form.Item name="emission_amount" label="Удельное количество выбросов">
            <Input
              data-cy="SIE-emission_amount-input"
              placeholder={equipmentTypes.data ? equipmentTypes.data[selectedEquipmentTypeId].value.toString() : ''}
              disabled
            />
          </Form.Item>
          <Form.Item name="unit" label="Единица измерения">
            <Input
              data-cy="SIE-unit-input"
              placeholder={equipmentTypes.data ? equipmentTypes.data[selectedEquipmentTypeId].unit : ''}
              disabled
            />
          </Form.Item>
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="SIE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
