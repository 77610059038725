import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IRTECalculationType,
  IRTEClimaticZone,
  IRTECoefficientKT,
  IRTEGasConnection,
  IRTEGasType,
  IRTELiquidType,
  IRTEOilGroup,
  IRTEOilType,
  IRTEOperatingMode,
  IRTEReportData,
  IRTETankDesing,
  IRTEVariable,
} from './interfaces';

const START_URL = 'ReservoirNPZLibrary';

export const getRTECalculationTypes = async (): Promise<IRTECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRascheta`);
  return data;
};

export const getRTEOilGroups = async (): Promise<IRTEOilGroup[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGruppa`);
  return data;
};

export const getRTETankDesings = async (): Promise<IRTETankDesing[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetConstrukciya`);
  return data;
};

export const getRTEOilTypes = async (calculationType: string | number): Promise<IRTEOilType[]> => {
  let response;

  switch (calculationType) {
    case 1 || 2 || 3 || 4:
      response = await apiClient.get(`${START_URL}/GetVidNefteprodukta`);
      break;

    case 5:
      response = await apiClient.get(`${START_URL}/GetNaimenovanieNefteprodukta`);
  }
  return response?.data;
};

export const getRTELiquidTypes = async (): Promise<IRTELiquidType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidZhidkosti`);
  return data;
};

export const getRTEOperatingModes = async (): Promise<IRTEOperatingMode[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetRezhimEksp`);
  return data;
};

export const getRTECoefficientKT = async (): Promise<IRTECoefficientKT[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKoeffKt`);
  return data;
};

export const getRTEGasTypes = async (): Promise<IRTEGasType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidGaza`);
  return data;
};

export const getRTEClimaticZones = async (): Promise<IRTEClimaticZone[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetKlimaticheskayaZona`);
  return data;
};

export const getRTEGasConnections = async (): Promise<IRTEGasConnection[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetGazovayaObvyazka`);
  return data;
};

export const getRTEVariables = async (
  calculationTypeId: string | number,
  oilTypeId: string | number,
): Promise<IRTEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { vidRaschetaId: calculationTypeId, vidNefteproduktaId: oilTypeId },
  });
  return data;
};

export const getRTEFullReport = async (reportData: IRTEReportData): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    oilGroupId,
    oilTypeId,
    liquidTypeId,
    operatingModeId,
    coefficientKTId,
    gasTypeId,
    climaticZoneId,
    tankDesingId,
    gasConnectionId,
    variables,
  } = reportData;

  const liquidTypeArray = [];
  for (let i = 0; i <= liquidTypeId.length - 1; i++) {
    liquidTypeArray.push({ id: liquidTypeId[i], paramName: 'VidZhidkostiId' });
    console.log('1');
  }

  const gasTypeArray = [];
  for (let i = 0; i <= gasTypeId.length - 1; i++) {
    gasTypeArray[i] = { id: gasTypeId[i], paramName: 'VidGazaId' };
  }

  console.log(liquidTypeArray, gasTypeArray);

  const { data } = await apiClient.post(`${START_URL}/GetResponse`, {
    list: [
      ...liquidTypeArray,
      {
        id: calculationTypeId,
        paramName: 'VidRaschetaId',
      },
      {
        id: oilTypeId,
        paramName: 'VidNefteproduktaId',
      },
      {
        id: coefficientKTId,
        paramName: 'KoeffKtId',
      },
      {
        id: oilGroupId,
        paramName: 'GruppaId',
      },
      {
        id: tankDesingId,
        paramName: 'ConstrukciyaId',
      },
      {
        id: operatingModeId,
        paramName: 'RezhimEkspId',
      },
      {
        id: gasConnectionId,
        paramName: 'GazovayaObvyazkaId',
      },
      {
        id: climaticZoneId,
        paramName: 'KlimaticheskayaZonaId',
      },
      ...variables,
    ],
    multipleparam: [...gasTypeArray, ...liquidTypeArray],
  });
  return data;
};
