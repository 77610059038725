import React from 'react';
import { Form, Col, Row } from 'antd';
import { IMethodologyModule, IWLBFormValues } from '../../interfaces';
import { useQuery } from 'react-query';
import {
  getWLBFullReport,
  getWLBOperationTypes,
  getWLBPollutants,
  getWLBTankTypes,
  getWLBVariables,
} from '../../../../api/WagonLocomotiveBusiness';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';
import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const WagonLocomotiveBusiness: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const tankTypes = useQuery('WLBTankTypes', getWLBTankTypes);
  const pollutants = useQuery('WLBPollutants', () => getWLBPollutants(form.getFieldValue('operation_type')));
  const operationTypes = useQuery('WLBOperationTypes', getWLBOperationTypes);
  const variables = useQuery('WLBVariables', getWLBVariables);

  const onFinish = (values: IWLBFormValues) => {
    const { tank_type, pollutant, operation_type } = values;

    if (variables.data) {
      getFormResult(getWLBFullReport, {
        tankTypeId: tank_type,
        pollutantId: pollutant,
        operationTypeId: operation_type,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeOperationType = () => {
    pollutants.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={tankTypes.data ? tankTypes.data : []}
            name="tank_type"
            cyPrefix="WLB"
            label="Тип цистерны"
            defaultValue={1}
          />
          <FormBaseSelect
            data={pollutants.data ? pollutants.data : []}
            name="pollutant"
            cyPrefix="WLB"
            label="Вид ЗВ"
            defaultValue={1}
          />
          <FormBaseSelect
            data={operationTypes.data ? operationTypes.data : []}
            name="operation_type"
            cyPrefix="WLB"
            label="Вид операции"
            onChange={onChangeOperationType}
            defaultValue={1}
          />
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="WLB"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
