import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IWPMCalculationType,
  IWPMEquipmentType,
  IWPMMaterialType,
  IWPMMechanicalRestorationType,
  IWPMOperationType,
  IWPMProductMassType,
  IWPMVariables,
} from './interfaces';

const START_URL = 'Plast';

export const getWPMCalculationTypes = async (): Promise<IWPMCalculationType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidRascheta`);
  return data;
};

export const getWPMEquipmentTypes = async (calculationTypeId: string | number): Promise<IWPMEquipmentType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetOborudovanie`, { params: { vidRascheta: calculationTypeId } });
  return data;
};

export const getWPMOperationTypes = async (
  calculationTypeId: string | number,
  equipmentId: string | number,
): Promise<IWPMOperationType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidOperacii`, {
    params: { vidRascheta: calculationTypeId, oborudovanie: equipmentId },
  });
  return data;
};

export const getWPMMechanicalRestorationTypes = async (
  calculationTypeId: string | number,
): Promise<IWPMMechanicalRestorationType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidMehObrabotki`, {
    params: { vidRascheta: calculationTypeId },
  });
  return data;
};

export const getWPMProductMassTypes = async (calculationTypeId: string | number): Promise<IWPMProductMassType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetIzdeliya`, { params: { vidRascheta: calculationTypeId } });
  return data;
};

export const getWPMMaterialTypes = async (
  calculationTypeId: string | number,
  operationTypeId: string | number,
): Promise<IWPMMaterialType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidMateriala`, {
    params: { vidRascheta: calculationTypeId, vidOperacii: operationTypeId },
  });
  return data;
};

export const getWPMPrepressingModes = async (): Promise<IWPMCalculationType[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetVidPress`);
  return data;
};

export const getWPMVariables = async (calculationTypeId: string | number): Promise<IWPMVariables[]> => {
  const { data } = await apiClient.get(`/${START_URL}/GetData`, { params: { vidRascheta: calculationTypeId } });
  return data;
};

export const getWPMFullReport = async (reportData: {
  calculationTypeId: string | number;
  equipmentId: string | number;
  operationId: string | number;
  mechanicalRestorationTypeId: string | number;
  productMassTypeId: string | number;
  materialTypeId: string | number;
  prepressingMode: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const {
    calculationTypeId,
    equipmentId,
    operationId,
    mechanicalRestorationTypeId,
    productMassTypeId,
    materialTypeId,
    prepressingMode,
    variables,
  } = reportData;

  const { data } = await apiClient.post(`${START_URL}/GetResponce`, [
    {
      id: calculationTypeId,
      paramName: 'VidRascheta',
    },
    {
      id: equipmentId,
      paramName: 'Oborudovanie',
    },
    {
      id: operationId,
      paramName: 'VidOperacii',
    },
    {
      id: mechanicalRestorationTypeId,
      paramName: 'VidMehObrabotki',
    },
    {
      id: productMassTypeId,
      paramName: 'Izdeliya',
    },
    {
      id: materialTypeId,
      paramName: 'VidMateriala',
    },
    {
      id: prepressingMode,
      paramName: 'VidPress',
    },
    ...variables,
  ]);
  return data;
};
