import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import {
  IBWBatteryType,
  IBWElectrolyteType,
  IBWTechnologicalOperation,
  IBWTechnologicalProcess,
  IBWVariable,
} from './interfaces';

const START_URL = 'AkkumulyatornyeRabotyLibrary';

export const getBWTechnologicalProcesses = async (): Promise<IBWTechnologicalProcess[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechProcess`);
  return data;
};

export const getBWTechnologicalOperations = async (): Promise<IBWTechnologicalOperation[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTechOperaciya`);
  return data;
};

export const getBWElectrolyteTypes = async (): Promise<IBWElectrolyteType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidElektrolit`);
  return data;
};

export const getBWBatteryTypes = async (): Promise<IBWBatteryType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidAkkumulyatora`);
  return data;
};

export const getBWVariables = async (technologicalProcessId: string | number): Promise<IBWVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { techProcessid: technologicalProcessId },
  });
  return data;
};

export const getBWFullReport = async (reportData: {
  technologicalProcessId: string | number;
  technologicalOperationId: string | number;
  electrolyteTypeId: string | number;
  batteryTypeId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { technologicalProcessId, technologicalOperationId, electrolyteTypeId, batteryTypeId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: technologicalProcessId,
      paramName: 'TechProcessId',
    },
    {
      id: technologicalOperationId,
      paramName: 'TechOperaciyaId',
    },
    {
      id: electrolyteTypeId,
      paramName: 'ElektrolitId',
    },
    {
      id: batteryTypeId,
      paramName: 'AkkumulyatorId',
    },
    ...variables,
  ]);
  return data;
};
