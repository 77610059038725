import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  ICLECalculationType,
  ICLEEquipmentType,
  ICLELabName,
  ICLEReportData,
  ICLESubstance,
  ICLEVariable,
} from './interfaces';

const START_URL = 'ForthCatHimLaboratoryClass';

export const getCLECalculationTypes = async (): Promise<ICLECalculationType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidRaschetaHimLab`);
  return data;
};

export const getCLEEquipmentTypes = async (labNameId: string | number): Promise<ICLEEquipmentType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetTehOborudovanie`, { params: { imyaLab: labNameId } });
  return data;
};

export const getCLELabNames = async (calculationTypeId: string | number): Promise<ICLELabName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetImyaLab`, { params: { vidRaschetaHimLab: calculationTypeId } });
  return data;
};

export const getCLEOutliarRemovals = async (): Promise<ICLELabName[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetUdalenieVybr`);
  return data;
};

export const getCLESubstances = async (
  calculationTypeId: string | number,
  labNameId: string | number,
  equipmentTypeId: string | number,
): Promise<ICLESubstance[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVeshestvaHimLab`, {
    params: { vidRaschetaHimLab: calculationTypeId, imyaLab: labNameId, tehOborudovanie: equipmentTypeId },
  });
  return data;
};

export const getCLEVariables = async (outliarRemovalId: string | number): Promise<ICLEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetData`, { params: { udalenieVybr: outliarRemovalId } });
  return data;
};

export const getCLEFullReport = async (reportData: ICLEReportData): Promise<IToolsReport[]> => {
  const { calculationTypeId, equipmentTypeId, labNameId, outlairRemovalId, substances, variables } = reportData;

  const substanceArray = [];
  for (let i = 0; i <= substances.length - 1; i++) {
    substanceArray.push({ names: substances[i], isSelected: true });
    console.log('1');
  }

  const { data } = await apiClient.post(`${START_URL}/GetResponce`, {
    list: [
      {
        id: calculationTypeId,
        paramName: 'VidRaschetaHimLab',
      },
      {
        id: labNameId,
        paramName: 'ImyaLab',
      },
      {
        id: equipmentTypeId,
        paramName: 'TehOborudovanie',
      },
      {
        id: outlairRemovalId,
        paramName: 'UdalenieVybr',
      },
      ...variables,
    ],
    veshestvaHimLablist: [...substanceArray],
  });
  return data;
};
