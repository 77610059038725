import { apiClient } from '../Client';
import { IReportVariableObject } from '../Interfaces';
import { IToolsReport } from '../Tools/interface';
import { IBEPollutant, IBEProductionStage, IBEVariable } from './interfaces';

const START_URL = 'PishepromHlebClass';

export const getBEProductionStages = async (): Promise<IBEProductionStage[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetEtap`);
  return data;
};

export const getBEPollutants = async (productionStageId: string | number): Promise<IBEPollutant[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetZV`, {
    params: { etap: productionStageId },
  });
  return data;
};

export const getBEVariables = async (): Promise<IBEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`);
  return data;
};

export const getBEFullReport = async (reportData: {
  productionStageId: string | number;
  pollutantId: string | number;
  variables: IReportVariableObject[];
}): Promise<IToolsReport[]> => {
  const { productionStageId, pollutantId, variables } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: productionStageId,
      paramName: 'Etap',
    },
    {
      id: pollutantId,
      paramName: 'DBZV',
    },
    ...variables,
  ]);
  return data;
};
