import { apiClient } from '../Client';
import { IToolsReport } from '../Tools/interface';
import {
  IAEAreaType,
  IAEBusType,
  IAEEngineType,
  IAEEngineValue,
  IAEFuelType,
  IAEInjectionOrCarburetor,
  IAELiftingCompacity,
  IAENeutralizerType,
  IAEReportData,
  IAESource,
  IAEVariable,
} from './interfaces';

const START_URL = 'TechObsluzhivanieAvtoLibrary';

export const getAEAreaTypes = async (): Promise<IAEAreaType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPomesheniya`);
  return data;
};

export const getAESources = async (): Promise<IAESource[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidPeredvizhnyeIstochniki`);
  return data;
};

export const getAEEngineTypes = async (sourceId: string | number): Promise<IAEEngineType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidDvigatelya`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getAEFuelTypes = async (engineTypeId: string | number): Promise<IAEFuelType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidTopliva`, { params: { vidDvigatelyaId: engineTypeId } });
  return data;
};

export const getAEEngineValues = async (): Promise<IAEEngineValue[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVDvigatelya`);
  return data;
};

export const getAEInjectionOrCarburetor = async (): Promise<IAEInjectionOrCarburetor[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidVpryskKarbyurator`);
  return data;
};

export const getAENeutralizerTypes = async (sourceId: string | number): Promise<IAENeutralizerType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNeitralizatora`, {
    params: { peredvizhnyeIstochnikiId: sourceId },
  });
  return data;
};

export const getAELiftingCompacities = async (
  sourceId: string | number,
  engineTypeId: string | number,
): Promise<IAELiftingCompacity[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidNeitralizatora`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineTypeId },
  });
  return data;
};

export const getAEBusTypes = async (
  sourceId: string | number,
  engineTypeId: string | number,
): Promise<IAEBusType[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetVidKlassAvtobusa`, {
    params: { peredvizhnyeIstochnikiId: sourceId, dvigatelId: engineTypeId },
  });
  return data;
};

export const getAEVariables = async (areaTypeId: string | number): Promise<IAEVariable[]> => {
  const { data } = await apiClient.get(`${START_URL}/GetInputData`, {
    params: { pomeshenieId: areaTypeId },
  });
  return data;
};

export const getAEFullReport = async (reportData: IAEReportData): Promise<IToolsReport[]> => {
  const {
    areaTypeId,
    sourceId,
    EngineTypeId,
    FuelTypeId,
    EngineValueId,
    injectionOrCarburetorId,
    neutralizerTypeId,
    liftingCompacityId,
    busTypeId,
    variables,
  } = reportData;
  const { data } = await apiClient.post(`${START_URL}/GetResponse`, [
    {
      id: areaTypeId,
      paramName: 'PomeshenieId',
    },
    {
      id: sourceId,
      paramName: 'PeredvizhnyeIstochnikiId',
    },
    {
      id: EngineTypeId,
      paramName: 'DvigatelId',
    },
    {
      id: FuelTypeId,
      paramName: 'ToplivoId',
    },
    {
      id: EngineValueId,
      paramName: 'VDvigatelyaId',
    },
    {
      id: injectionOrCarburetorId,
      paramName: 'VpryskKarbyuratorId',
    },
    {
      id: neutralizerTypeId,
      paramName: 'NeitralizatorId',
    },
    {
      id: liftingCompacityId,
      paramName: 'GruzopodemnostId',
    },
    {
      id: busTypeId,
      paramName: 'KlassAvtobusaId',
    },
    ...variables,
  ]);
  return data;
};
