import React from 'react';
import { Form, Row, Col } from 'antd';
import { IADEFormValues, IMethodologyModule } from '../../interfaces';
import { useQuery } from 'react-query';
import { FormVariableInputList } from '../../../../ui/molecules/FormVariableInputList';
import { FormBaseSelect } from '../../../../ui/molecules/FormBaseSelect';

import {
  getADECalculationTypes,
  getADECleanSteps,
  getADEDeleteTypes,
  getADEDetergents,
  getADEEquipmentTypes,
  getADEFullReport,
  getADETechnologicalProcesses,
  getADEVariables,
} from '../../../../api/AuxiliaryDomesticEmissions';

import { getFormResult } from '../../../../utils/getFormResult';
import { generateReportingVariablesArray } from '../../../../utils';

export const AuxiliaryDomesticEmissions: React.FC<IMethodologyModule> = ({
  children,
  marginBetween,
}: IMethodologyModule) => {
  const [form] = Form.useForm();

  const calculationTypes = useQuery('ADECalculationTypes', getADECalculationTypes);
  const technologicalProcesses = useQuery('ADETechnologicalProcesses', () =>
    getADETechnologicalProcesses(form.getFieldValue('calculation_type')),
  );
  const equipmentTypes = useQuery('ADEEquipmentTypes', () =>
    getADEEquipmentTypes(form.getFieldValue('calculation_type'), form.getFieldValue('technological_process')),
  );
  const deleteTypes = useQuery('ADEDeleteTypes', getADEDeleteTypes);
  const cleanSteps = useQuery('ADECleanSteps', getADECleanSteps);
  const detergents = useQuery('ADEDetergents', () => getADEDetergents(form.getFieldValue('equipment_type')));
  const variables = useQuery('ADEVariables', () =>
    getADEVariables(
      form.getFieldValue('calculation_type'),
      form.getFieldValue('technological_process'),
      form.getFieldValue('clean_step'),
      form.getFieldValue('equipment_type'),
      form.getFieldValue('delete_type'),
    ),
  );

  const onFinish = (values: IADEFormValues) => {
    const { calculation_type, technological_process, equipment_type, clean_step, delete_type, detergent } = values;

    if (variables.data) {
      getFormResult(getADEFullReport, {
        calculationTypeId: calculation_type,
        technologicalProcessId: technological_process,
        equipmentTypeId: equipment_type,
        cleanStepId: clean_step || 1,
        deleteTypeId: delete_type || 1,
        detergentId: detergent || 1,
        variables: generateReportingVariablesArray(variables.data, form),
      });
    }
  };

  const onChangeCalculationTypes = () => {
    technologicalProcesses.refetch();
    equipmentTypes.refetch();
    variables.refetch();
  };

  const onChangeTechnologicalProcess = () => {
    equipmentTypes.refetch();
    variables.refetch();
  };

  const onChangeEquipmentType = () => {
    detergents.refetch();
    variables.refetch();
  };

  const onChangeCleanStep = () => {
    variables.refetch();
  };

  const onChangeDeleteType = () => {
    variables.refetch();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row>
        <Col span={11} style={{ marginRight: marginBetween }}>
          <FormBaseSelect
            data={calculationTypes.data ? calculationTypes.data : []}
            name="calculation_type"
            cyPrefix="ADE"
            label="Вид расчета для вспомогательных и бытовых служб"
            defaultValue={1}
            onChange={onChangeCalculationTypes}
          />
          <FormBaseSelect
            data={technologicalProcesses.data ? technologicalProcesses.data : []}
            name="technological_process"
            cyPrefix="ADE"
            label="Наименование технологического процесса"
            defaultValue={1}
            onChange={onChangeTechnologicalProcess}
          />
          <FormBaseSelect
            data={equipmentTypes.data ? equipmentTypes.data : []}
            name="equipment_type"
            cyPrefix="ADE"
            label="Вид обоорудования"
            defaultValue={1}
            onChange={onChangeEquipmentType}
          />
          {detergents.data && detergents.data.length > 0 ? (
            <FormBaseSelect
              data={detergents.data ? detergents.data : []}
              name="detergent"
              cyPrefix="ADE"
              label="Наименование моющего средства"
              defaultValue={1}
            />
          ) : (
            ''
          )}

          <FormBaseSelect
            data={deleteTypes.data ? deleteTypes.data : []}
            name="delete_type"
            cyPrefix="ADE"
            label="Как удаляются выбросы в атмосферу"
            defaultValue={1}
            onChange={onChangeDeleteType}
          />
          {form.getFieldValue('delete_type') === 1 ? (
            <FormBaseSelect
              data={cleanSteps.data ? cleanSteps.data : []}
              name="clean_step"
              cyPrefix="ADE"
              label="Ступень очистки"
              defaultValue={1}
              onChange={onChangeCleanStep}
            />
          ) : (
            ''
          )}
        </Col>
        <Col>
          <FormVariableInputList
            data={variables.data ? variables.data : []}
            cyPrefix="ADE"
            placeholder="0"
            minValue={0}
            defaultValue={0}
          />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
